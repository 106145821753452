.arrow
  transition: transform 0.25s

  &-top
    transform: rotate(270deg)

  &-right
    transform: rotate(0deg)

  &-bottom
    transform: rotate(90deg)

  &-left
    transform: rotate(180deg)

.long-arrow-left
  display: block
  width: 16px
  height: 16px
  border-top: 2px solid #879CA5
  border-left: 2px solid #879CA5
  transform: rotate(135deg)

  &::after
    content: ""
    display: block
    width: 2px
    height: 22px
    background-color: #879CA5
    transform: rotate(-45deg) translate(7px, 0px)
    left: 0
    top: 0
