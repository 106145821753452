.scroll-box
  overflow: auto
  border-top: 1px solid transparent
  border-bottom: 1px solid transparent

  &--scrolled
    border-top-color: $border-default-color

  &--overflown
    border-bottom-color: $border-default-color
