.collapsible
  $parent: &
  display: grid
  grid-template-rows: 0fr
  overflow: hidden
  transition: grid-template-rows 0.3s ease

  & > &__content
    min-height: 0
    opacity: 0
    transition: opacity 0.3s ease

  &--entering,
  &--entered
    grid-template-rows: 1fr

    & > #{$parent}__content
      opacity: 1
