.integrations-picker
  display: flex
  flex-direction: column
  max-width: 1040px
  margin: 0 auto
  width: 100%

  &__wrapper
    max-width: 1040px
    margin: 0 auto
    width: 100%

  &__header
    &--centered
      text-align: center

  &-content
    display: flex
    align-items: center
    height: 180px

    background-color: #fff

    border: 1px solid rgba(5, 117, 158, 0.2)
    border-radius: 12px
    box-shadow: 0 1px 8px rgba(5, 117, 158, 0.12)

    +screen-max(540px)
      flex-direction: column
      height: auto
      font-size: 14px

  .separator
    width: 32px
    display: flex
    align-items: center
    flex-direction: column

    +screen-max(540px)
      flex-direction: row
      width: 100%
      height: 32px

    &-icon
      width: 32px
      height: 32px
      border: 1.25px solid rgba(5, 117, 158, 0.2)
      display: flex
      align-items: center
      justify-content: center
      border-radius: 30px
      color: var(--primary-color)

      +screen-max(540px)
        transform: rotate(90deg)

    &-line
      height: 73px
      border-left: 1px solid rgba(5, 117, 158, 0.2)

      +screen-max(540px)
        height: 1px
        width: calc(50% - 16px)
        border-left: 0
        border-top: 1px solid rgba(5, 117, 158, 0.2)

  &-button.action-button--fixed-width-large
    align-self: center
    margin-top: 40px

    @media screen and (max-width: 540px)
      width: 100%
      margin-top: 25px

  &__or-delimiter
    margin-top: 38px
    width: 100%
    border: none
    border-top: 1px solid $border-default-color
    overflow: visible
    text-align: center

  &__or-delimiter:after
    background: $bg-grey-color
    color: $text-secondary-color
    content: 'OR'
    padding: 0 1rem
    font-size: 14px
    font-weight: 400
    position: relative
    top: -14px


.integrations-combobox
  display: flex
  justify-content: center
  width: 50%
  height: 100%
  padding-top: 40px
  line-height: 20px

  +screen-max(540px)
    height: 132px
    width: 100%
    padding-top: 20px
    font-size: 14px

    &:first-child
      height: 132px

    &:last-child
      height: 136px

    input
      font-size: 14px

  &-content
    width: 75%
    display: flex
    flex-direction: column

    +screen-max(540px)
      width: 100%
      padding-left: 12px
      padding-right: 12px

  &-title
    font-weight: 500
    line-height: 1.5

  &-description
    margin-bottom: 8px
    font-weight: 400
    line-height: 1.5
    color: $text-secondary-color
