.sortable
  $font-weight: 400
  $background-color: #fff
  $border-color: #efefef
  $text-color: #333
  $handle-color: rgba(0, 0, 0, 0.25)
  $box-shadow: 0 0 0 rgba(0, 0, 0, 0)
  $focused-outline-color: #4c9ffe

  @keyframes pop
    0%
      transform: scale(1)
      box-shadow: var(--box-shadow)
    100%
      transform: scale(var(--scale))
      box-shadow: var(--box-shadow-picked-up)

  @keyframes fadeIn
    0%
      opacity: 0
    100%
      opacity: 1

  &__list
    display: grid
    grid-auto-rows: max-content
    box-sizing: border-box
    min-width: 350px
    grid-gap: 0
    border-radius: 5px
    min-height: 200px
    transition: background-color 350ms ease
    grid-template-columns: repeat(var(--columns, 1), 1fr)

    &::after
      content: ''
      height: 10px
      grid-column-start: span var(--columns, 1)

  &__item__wrapper
    display: flex
    box-sizing: border-box
    transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0) scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1))
    transform-origin: 0 0
    touch-action: manipulation

    &.fadeIn
      animation: fadeIn 500ms ease

    &.dragOverlay
      //--scale: 1.05;
      --box-shadow: $box-shadow
      --box-shadow-picked-up: 0 4px 12px rgba(5, 117, 158, 0.12)
      z-index: 999

  &__item
    position: relative
    display: flex
    flex-grow: 1
    align-items: center
    background-color: $background-color
    box-shadow: $box-shadow
    outline: none
    border-radius: calc(4px / var(--scale-x, 1))
    box-sizing: border-box
    list-style: none
    transform-origin: 50% 50%
    -webkit-tap-highlight-color: transparent
    color: $text-color
    font-weight: $font-weight
    font-size: 1rem
    white-space: nowrap
    transform: scale(var(--scale, 1))
    transition: box-shadow 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22)

    &:focus-visible
      box-shadow: 0 0 4px 1px $theme-primary-color

    &.dragging:not(.dragOverlay)
      opacity: var(--dragging-opacity, 0.5)
      z-index: 0

      &:focus
        box-shadow: $box-shadow

    &.dragOverlay
      cursor: inherit
      animation: pop 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22)
      transform: scale(var(--scale))
      box-shadow: var(--box-shadow-picked-up)
      opacity: 1

    &__action
      display: flex
      width: 12px
      padding: 15px
      align-items: center
      justify-content: center
      flex: 0 0 auto
      touch-action: none
      cursor: var(--cursor, pointer)
      border-radius: 5px
      border: none
      outline: none
      appearance: none
      background-color: transparent
      -webkit-tap-highlight-color: transparent

      @media (hover: hover)
        &:hover
          background-color: var(--action-background, rgba(0, 0, 0, 0.05))

          svg
            fill: #6f7b88

      svg
        flex: 0 0 auto
        margin: auto
        height: 100%
        overflow: visible
        fill: #919eab

      &:active
        background-color: var(--background, rgba(0, 0, 0, 0.05))

        svg
          fill: var(--fill, #788491)

      &:focus-visible
        outline: none
        box-shadow: 0 0 0 2px $theme-primary-color
