.search-box
  display: flex
  flex-direction: column

  &__label
    color: $deprecated-text-primary-color
    font-weight: 500
    line-height: 20px

  &__label ~ &__hint
    margin-top: 4px

  &__hint
    color: $deprecated-text-secondary-color
    line-height: 20px

  &__label ~ &__input-container,
  &__hint ~ &__input-container
    margin-top: 8px

  &__input-container
    display: flex
    align-items: center
    border: 1px solid $input-border-color
    border-radius: 5px
    overflow: hidden
    height: 100%

    &.disabled
      background-color: $theme-lighter-color

    &.error
      background-color: var(--bg-input-error-color)
      border-color: var(--border-error-color)

    &:hover:not(.disabled):not(:focus-within)
      border-color: $input-border-hover-color

    &:focus-within
      border-color: $input-border-active-color

  &__icon
    color: $theme-grey-color
    margin-left: 10px
    width: 24px
    height: 24px

  &__input
    flex-grow: 1
    border: 0
    padding: 7px 8px
    line-height: 20px
    background-color: transparent

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration
      display: none

  &__clear *
    fill: $theme-primary-color

  &__clear
    margin-right: 12px
    margin-left: 0
    width: 20px
    height: 20px

  &__error
    margin-top: 8px
    color: var(--text-error-color)
    font-size: 13px
    line-height: 16px

  &--size-small &__input
    @extend %paragraph-small
    color: $text-primary-color

  &--size-small &__icon
    width: 20px
    height: 20px
    margin-left: 8px

  &--size-small &__input
    padding: 5px 4px

  &--size-small &__clear
    margin-right: 8px
