.dashboard-scorecard
  display: flex
  flex-direction: column
  justify-content: center
  height: 100%
  width: 100%
  padding: 20px 22px

  &__title
    @extend %paragraph-medium
    color: var(--text-secondary-color)

  &__figure
    @extend %heading-large-h2

    &--negative
      color: var(--text-error-color)

    &--positive
      color: var(--fill-success-color)
  &__delta
    @extend %paragraph-medium
    display: flex
    align-items: center

    &--negative
      color: var(--text-error-color)

    &--positive
      color: var(--fill-success-color)
