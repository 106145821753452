@use '@ag-grid-community/styles' as ag;

@include ag.grid-styles(
  (
    theme: alpine,
    --ag-header-background-color: $theme-light-color,
    --ag-odd-row-background-color: $theme-white,
    --ag-row-hover-color: $theme-lighter-color,
    --ag-font-family: $barlow-font-family
  )
);

.ag-theme-alpine {
  .ag-header-cell-menu-button {
    cursor: pointer;
  }

  [aria-label='Column Menu'].ag-menu {
    border-radius: 5px;
    background-color: $theme-white;
    min-width: 145px;

    .ag-menu-header {
      display: none;
    }

    .ag-menu-list {
      padding: 0;
    }

    .ag-menu-option {
      height: 36px;
      cursor: pointer;
    }

    .ag-menu-option-icon {
      text-align: center;

      > svg {
        vertical-align: middle;

        * {
          fill: $theme-dark-grey;
        }
      }
    }

    .ag-menu-option-text {
      font-size: 14px;
      line-height: 20px;
      padding-left: 4px;
    }

    .ag-menu-option-shortcut,
    .ag-menu-option-popup-pointer {
      display: none
    }
  }

  .ag-header-cell.highlighted-cell,
  .ag-header-cell.highlighted-cell:not(.ag-column-hover):hover,
  .ag-header-cell.highlighted-cell:not(.ag-column-resizing) + .ag-header-cell.highlighted-cell:not(.ag-column-hover):not(.ag-header-cell-moving):hover {
    background-color: $warning-background-color;
  }

  .ag-header-cell.cell-colored {
    border-top: 2px solid
  }

  $cell-colors: (
    eminence: $eminence-color,
    pale-red: $pale-red-color,
    algae: $algae-color,
    sunset: $sunset-color,
    cerulean: $cerulean-color
  );

  @each $color-name, $color in $cell-colors {
    .ag-header-cell.cell-colored--#{$color-name} {
      border-top-color: $color
    }
  }
}

.preview {
  .preview-content {
    .ag-header-cell.highlighted-cell,
    .ag-header-cell.highlighted-cell:not(.ag-column-hover):hover {
      background-color: $warning-background-color;
    }
  }
}
