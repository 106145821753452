@design-tokens url('./../design-tokens/core/colors.json') format('style-dictionary3');

:root {
  /* Neutral */
  --body-bg-color: design-token('colors.neutral.white');
  --bg-default-color: design-token('colors.neutral.white');
  --bg-input-default-color: design-token('colors.neutral.white');
  --text-inverse-color: design-token('colors.neutral.white');
  --bg-low-color: design-token('colors.neutral.100');
  --bg-lower-color: design-token('colors.neutral.200');
  --bg-disabled-color: design-token('colors.neutral.200');
  --border-alt-color: design-token('colors.neutral.300');
  --border-default-color: design-token('colors.neutral.400');
  --border-hover-color: design-token('colors.neutral.500');
  --muted-color: design-token('colors.neutral.500');
  --fill-disabled-color: design-token('colors.neutral.500');
  --text-disabled-color: design-token('colors.neutral.600');
  --icon-disabled-color: design-token('colors.neutral.600');
  --text-secondary-color: design-token('colors.neutral.700');
  --text-primary-color: design-token('colors.neutral.900');
  --dark-color: design-token('colors.neutral.black');
  /* Blue */
  --bg-brand-color: design-token('colors.blue.100');
  --primary-light-color: design-token('colors.blue.400');
  --icon-info-color: design-token('colors.blue.600');
  --primary-color: design-token('colors.blue.700');
  --text-brand-color: design-token('colors.blue.700');
  --link-default-color: design-token('colors.blue.700');
  --text-button-brand-default-color: design-token('colors.blue.700');
  --border-primary-color: design-token('colors.blue.700');
  --icon-brand-color: design-token('colors.blue.700');
  --fill-brand-color: design-token('colors.blue.700');
  --checkbox-hover-color: design-token('colors.blue.800');
  --border-brand-hover-color: design-token('colors.blue.800');
  --primary-active-color: design-token('colors.blue.900');
  --icon-brand-higher-color: design-token('colors.blue.900');
  /* Orange */
  --bg-warning-color: design-token('colors.orange.100');
  --text-button-warning-default-color: design-token('colors.orange.700');
  --text-button-warning-hover-color: design-token('colors.orange.800');
  --bg-warning-btn-active-color: design-token('colors.orange.900');
  --border-warning-color: design-token('colors.orange.500');
  --icon-warning-color: design-token('colors.orange.600');
  --text-button-warning-default-color: design-token('colors.orange.700');
  --bg-warning-btn-active-color: design-token('colors.orange.900');
  --text-warning-color: design-token('colors.orange.900');
  /* Red */
  --bg-button-secondary-danger-hover-color: design-token('colors.red.100');
  --bg-input-error-color: design-token('colors.red.100');
  --border-error-color: design-token('colors.red.500');
  --text-error-color: design-token('colors.red.600');
  --icon-danger-color: design-token('colors.red.600');
  --text-button-danger-default-color: design-token('colors.red.700');
  --text-button-danger-pressed-color: design-token('colors.red.900');
  --text-danger-color: design-token('colors.red.900');
  /* Green */
  --icon-success-color: design-token('colors.green.600');
  --fill-success-color: design-token('colors.green.700');
  --text-success-color: design-token('colors.green.900');
  /* Decorative */
  --decorative-cerulean-color: design-token('colors.blue.400');
  --decorative-pale-red-color: design-token('colors.red.500');
  --decorative-sunset-color: design-token('colors.orange.600');
  --decorative-algae-color: design-token('colors.green.500');
  --decorative-eminence-color: design-token('colors.purple.700');
}
