.oauth-buttons
  display: flex
  flex-direction: column
  gap: 12px

  .button
    padding: 0 16px

  .button-login
    min-height: 40px
    display: flex
    justify-content: center
    font-size: 12px
    font-weight: 600
    line-height: 1.3
    text-transform: uppercase
    color: #ffffff
    box-shadow: none
    gap: 8px
    max-width: 100%

    .logo
      margin: 0

    &--google
      background-color: #4285F4

      &:hover:not(:disabled):not(.disabled)
        background-color: #4285F4

    &--microsoft
      background-color: #2F2F2F

      &:hover:not(:disabled):not(.disabled)
        background-color: #2F2F2F

    &--xero
      background-color: #ffffff

      &:hover:not(:disabled):not(.disabled)
        background-color: #f2fcff

  &__box
    display: flex
    justify-content: center
    gap: 8px

    .button-login
      padding: 0
      border: 1px solid rgba(195, 205, 210, 0.40)
      width: 40px
      height: 40px

      .logo
        width: 100%
        height: 100%
        margin: 0

      
