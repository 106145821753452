.stats-block
  &-value
    margin-bottom: 0

  .danger > *
    vertical-align: baseline

  .column
    padding-left: 8px
    padding-right: 5px
    border-left: 3px solid #05759E

    strong
      margin-bottom: 10px

    .info-icon
      left: 4px
      