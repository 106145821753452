.property
  display: flex
  flex-direction: row
  position: relative
  gap: 16px
  padding: 8px 0

  &-content
    flex-grow: 1
    display: flex
    flex-direction: column
    justify-content: space-between

  &-title
    @extend %heading-small-h4
    margin-bottom: 8px

  &-description
    display: flex
    align-items: center
    text-align: right
    margin-bottom: 0
    word-break: break-all

  +screen-min(768px)
    &-content
      flex-direction: row
      align-items: center

    &-title
      margin-bottom: 0
