.connection-button
  width: 100%

  &-card
    position: absolute
    left: 6px
    display: flex
    background-color: #fff
    border-radius: 3px

    img
      width: 29px
      height: 29px

.google-connection-button
  font-family: 'Barlow', sans-serif
  width: 100%
  background-color: #4285F4
  color: #FFF
  border-radius: 5px
  padding: 8px 20px
  height: 40px

  &:hover:not(:disabled):not(.disabled)
    color: #FFF
    background-color: #3367D6

  &-card
    display: flex
    margin-right: 8px

    img
      width: 24px

.quickbooks-connection-button
  border-radius: 4px
  background: url('~connect_buttons/qb_button_default.svg') no-repeat center
  background-color: #2CA01C
  width: 274px
  height: 48px
  align-self: center

  &:hover:not(:disabled):not(.disabled)
    background: url('~connect_buttons/qb_button_hover.svg') no-repeat center
    background-color: #1E900E

