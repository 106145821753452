.tabs,
.tab-list
  display: flex
  gap: 32px
  border-bottom: 1px solid var(--border-default-color)
  margin-bottom: 25px
  li,
  .tab
    @extend %paragraph-medium
    padding: 11px 0
    background-color: unset
    cursor: pointer

    +screen-max(768px)
      padding: 20px 10px

    &.active, &[data-selected]
      @extend %subheading-medium
      color: var(--text-primary-color)
      border-bottom: 3px solid var(--border-primary-color)

