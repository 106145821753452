:root
  --barlow-font-family: 'Barlow', 'Helvetica', 'Arial', sans-serif
  --inter-font-family: 'Inter', 'Helvetica', 'Arial', sans-serif
  --font-family-heading: 'DM Sans', 'Helvetica', 'Arial', sans-serif

$barlow-font-family: var(--barlow-font-family)
$inter-font-family: var(--inter-font-family)
$font-family-heading: var(--font-family-heading)

body,
input,
button,
textarea
  font-family: $barlow-font-family
  font-size: $text-size-base
  font-weight: 400
  line-height: 1.5
  color: $deprecated-text-primary-color

body
  &.with-dashboard
    color: var(--text-primary-color)

    input,
    textarea
      color: var(--text-primary-color)

h1,
h2,
h3,
h4,
h5
  font-family: $font-family-heading
  line-height: 1.2

h1
  margin-bottom: 20px
  font-size: $text-size-xl

  sup
    font-size: 46%

h2
  font-size: $text-size-l

h3
  font-size: $text-size-m

h4
  margin-bottom: 20px
  font-size: $text-size-s
  font-weight: 500

h5
  font-size: $text-size-base
  font-weight: 500

strong
  font-weight: 500

a
  color: $theme-primary-color
  text-decoration: none

  &.disabled
    pointer-events: none
    opacity: 0.6

  &:hover
    color: lighten($theme-primary-color, 5%)

  &.danger
    color: $error-status-color

    &:hover
      color: lighten($error-status-color, 5%)

  &.plaintext
    color: $deprecated-text-primary-color
    text-decoration: none

  &.chevron-right
    &::after
      display: inline-block
      width: 10px
      height: 10px
      margin-left: 4px
      content: ''
      background-image: url('~chevron.svg')
      background-repeat: no-repeat
      background-size: contain

p
  margin-bottom: 15px

small
  font-size: $text-size-xs

  button
    font-size: inherit

button
  color: currentColor

ol
  padding-left: $spaceMedium

.highlighted
  color: #007aa4

.muted
  color: $deprecated-text-disabled-color

.danger,
.danger > a
  color: $error-status-color

.danger > a.highlighted
  color: #007aa4

.success
  color: rgba(37, 167, 58, 1)

.warning
  color: $warning-default-color

.error
  color: var(--text-error-color)

// UI Library

%heading-xlarge-h1
  font-family: $inter-font-family
  font-style: normal
  font-weight: 700
  font-size: 36px
  line-height: 40px
  color: $text-primary-color

%heading-large-h2
  font-family: $inter-font-family
  font-style: normal
  font-weight: 700
  font-size: 24px
  line-height: 36px
  color: $text-primary-color

%heading-medium-h3
  font-family: $inter-font-family
  font-style: normal
  font-weight: 700
  font-size: 20px
  line-height: 32px
  color: $text-primary-color

%heading-small-h4
  font-family: $inter-font-family
  font-style: normal
  font-weight: 700
  font-size: 18px
  line-height: 24px
  color: $text-primary-color

%heading-xsmall-h5
  font-family: $inter-font-family
  font-style: normal
  font-weight: 600
  font-size: 16px
  line-height: 24px
  color: $text-primary-color

%subheading-medium
  font-family: $barlow-font-family
  font-style: normal
  font-weight: 500
  font-size: 16px
  line-height: 24px
  color: $text-primary-color

%subheading-small
  font-family: $barlow-font-family
  font-style: normal
  font-weight: 500
  font-size: 14px
  line-height: 20px
  color: $text-primary-color

%paragraph-medium
  font-family: $barlow-font-family
  font-style: normal
  font-weight: 400
  font-size: 16px
  line-height: 24px
  color: $text-secondary-color

%paragraph-small
  font-family: $barlow-font-family
  font-style: normal
  font-weight: 400
  font-size: 14px
  line-height: 20px
  color: $text-secondary-color

%hyperlink-medium
  font-family: $barlow-font-family
  font-style: normal
  font-weight: 500
  font-size: 16px
  line-height: 24px
  color: $text-link-color

%hyperlink-small
  font-family: $barlow-font-family
  font-style: normal
  font-weight: 500
  font-size: 14px
  line-height: 20px
  color: $text-link-color

%captions-medium
  font-family: $barlow-font-family
  font-style: normal
  font-weight: 400
  font-size: 13px
  line-height: 16px
  color: $text-secondary-color

%captions-small
  font-family: $barlow-font-family
  font-style: normal
  font-weight: 400
  font-size: 11px
  line-height: 14px
  color: $text-secondary-color

%labels-button
  font-family: $barlow-font-family
  font-style: normal
  font-weight: 600
  font-size: 12px
  line-height: 16px
  text-transform: uppercase
  color: $text-link-color

%labels-tabs
  font-family: $barlow-font-family
  font-style: normal
  font-weight: 500
  font-size: 12px
  line-height: 16px
  text-transform: uppercase
  color: $text-primary-color

%labels-badge
  font-family: $barlow-font-family
  font-style: normal
  font-weight: 600
  font-size: 11px
  line-height: 12px
  text-transform: uppercase
  color: $text-primary-color

%decorative-prices
  font-family: $barlow-font-family
  font-style: normal
  font-weight: 600
  font-size: 40px
  line-height: 48px
  color: $text-primary-color

%decorative-subheadings
  font-family: $barlow-font-family
  font-size: 18px
  font-weight: 600
  line-height: 24px
