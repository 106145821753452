#help-scout-button
  position: fixed
  bottom: 12px
  left: 12px
  z-index: 1000
  background-color: $info-default-color
  color: white
  border: none
  border-radius: 16px
  padding: 8px
  font-size: 16px
  cursor: pointer
  box-shadow: 0px 6px 8px 0px #0083AD33
  transition: transform 0.2s

  svg
    display: block

  &:hover
    transform: scale(1.1)
    cursor: pointer

