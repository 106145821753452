.disclosure
  &-button
    &.outlined-button
      font-size: 16px
      font-weight: 400
      text-transform: capitalize
      border: 1px solid transparent

      &:hover,
      &:focus,
      &[data-state='open']
        color: $deprecated-text-disabled-color
        border: 1px solid $deprecated-text-disabled-color

  &-wrapper
    position: relative

    [data-reach-disclosure-panel]
      position: absolute
      right: 0
      z-index: 1
      min-width: 174px
      overflow: hidden
      background: #fff
      border: 1px solid rgba(135, 156, 165, 0.3)
      border-bottom: 0
      border-radius: 3px
      box-shadow: 0 12px 65px rgba(0, 82, 111, 0.1)

      .button
        width: 100%
        border-radius: 0

  &-panel-title
    display: flex
    align-items: center
    justify-content: space-between
    padding: 20px 25px 16px 32px

    h4
      margin: 0

    svg
      width: 20px
      height: 20px
      cursor: pointer
      transform: scale(2.1)

+screen-max(768px)
  .disclosure-wrapper.is-expanded
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%

    [data-reach-disclosure-panel]
      width: 100%
      height: 100%
      z-index: $z-index-disclosure-panel
      border: 0

      form
        padding: 0 13px 0 20px

      .button
        display: block
        width: calc(100% - 24px)
        margin: 16px auto 0
