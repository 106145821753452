.data-aggregation
  &__container
    width: 616px
    display: flex
    flex-direction: column
    margin-inline: auto
    gap: 16px

  &__source-view
    margin-bottom: 24px

  &__source-view-label
    @extend %subheading-medium
    margin-bottom: 8px
    display: block

  &__section
    border-radius: 4px
    border: 1px solid var(--border-default-color)
    overflow: hidden

  &__section-header
    padding-left: 8px
    border-bottom: 1px solid var(--border-default-color)
    display: flex
    flex-direction: row
    align-items: center

  &__section-icon
    margin-right: 2px

  &__section-title
    @extend %subheading-medium
    flex-grow: 1

  &__section-body
    padding: 16px
    background-color: var(--bg-lower-color)

  &__section-empty
    display: block
    text-align: center
    color: var(--text-disabled-color)
    font-size: var(--text-size-xs)

  &__section-list
    display: flex
    flex-direction: column
    gap: 12px

  &__section-item
    display: flex
    align-items: center
    gap: 8px

  &__section-item-content
    display: flex
    flex-direction: row
    align-items: center
    flex-grow: 1
    gap: 8px

  &__section-item-column
    @extend .field-input
    display: flex
    align-items: center
    flex-grow: 1

    &--error
      border-color: var(--border-error-color)
      background-color: var(--bg-input-error-color)

  &__section-item-adornment
    width: 164px
    flex-shrink: 0

  &__column-management
    width: 320px
    height: 456px
    border: 1px solid var(--border-primary-color)
    display: flex
    flex-direction: column

    .column-management__content
      flex-grow: 1

  &__column-management-header
    padding: 12px
    border-bottom: 1px solid var(--border-default-color)

  &__column-management-title
    margin-bottom: 4px

  &__column-management-footer
    padding: 8px 12px
    display: flex
    flex-direction: row
    border-top: 1px solid var(--border-default-color)
    gap: 8px

  &__actions
    display: flex
    padding: 16px
    gap: 8px
    width: 616px
