.table
  .sticky-header
    tr
      border-bottom: 0

    th
      position: sticky
      top: 0
      background: #fff
      z-index: 1
      box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.12)

  .expandable-row
    td:first-child
      padding-left: 30px
      position: relative

      &::before
        display: block
        position: absolute
        width: 12px
        height: 12px
        left: 10px
        top: 50%
        transform: translateY(-50%)
        content: ''
        background-image: url('~chevron.svg')
        background-repeat: no-repeat
        background-size: contain
        transition: transform .15s ease

    &.is-expanded
      td::before
        transform: translateY(-50%) rotate(90deg)

    &:hover
      background-color: #f6fafb
      cursor: pointer

  .nested-row
    &:hover
      background-color: #f6fafb

    td:first-child
      padding-left: 50px

    p
      margin: 0

    .muted
      font-size: 14px
      line-height: 16px

  .failed
    background-color: #FAE6E4

    &:hover
      background-color: #FAE6E4

  .empty-row
    color: $deprecated-text-disabled-color
    background: lighten(#f6fafb, 20%)
    text-align: center

  @media screen and (max-width: 768px)
    .nested-row
      .muted
        font-size: 12px
        line-height: 14px
