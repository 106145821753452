.paywall
  display: flex
  align-items: center
  background: var(--body-bg-color)
  border: 1px solid var(--border-default-color)
  padding: 6px 16px
  border-radius: 5px

  &__wrapper
    position: relative

  &--shifted-up
    position: relative
    margin: 0 40px
    top: 0
    transform: translateY(-35px)
    margin-bottom: -15px

    +screen-max(768px)
      transform: translateY(-47px)
      margin-bottom: -37px

    +screen-max(679px)
      margin-left: 20px
      margin-right: 20px

  +screen-max(1100px)
    flex-direction: column
    padding: 8px 16px

  &__ico
    margin-right: 8px
    width: 24px
    height: 24px
    color: var(--dark-color)

    +screen-max(1100px)
      display: none

  &__message
    margin: 0
    flex: 1
    margin-right: 8px
    color: var(--text-primary-color)

    +screen-max(1100px)
      margin-right: 0
      margin-bottom: 4px
