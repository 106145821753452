.combobox
  position: relative
  width: 100%

  &.disabled
    pointer-events: none

  &-error-message
    color: var(--text-error-color)

// Material overrides

.MuiAutocomplete-paper
  // New sheet label inside dropdown
  .new-option-label
    color: $success-status-color

    &:before
      content: ' '
      padding: 3px
      margin: 0 5px 2px 5px
      border-radius: 50%
      background: $deprecated-text-primary-color
      display: inline-block

.MuiAutocomplete-popper[role="presentation"]
  z-index: $z-index-dropdown-options

.MuiAutocomplete-popper
  .MuiListSubheader-sticky
    top: 0

  .MuiAutocomplete-listbox
    &> li
      &:first-child
        .MuiAutocomplete-groupLabel
          margin-top: 0

  .MuiAutocomplete-groupLabel
    margin-top: 8px
    color: var(--dark-color)
    font-weight: 600
    font-size: 16px
    line-height: 40px
    padding-left: 12px
    padding-right: 12px

  .MuiPaper-root.MuiAutocomplete-paper
    .MuiAutocomplete-listbox
      .MuiAutocomplete-groupUl
        &--indented
          .MuiAutocomplete-option
            padding-left: 28px

.combobox
  .start-adornment-icon
    width: 30px
    height: 30px

  span.start-adornment-icon
    padding: 3px

  // New sheet label inside input
  .new-option-label
    color: $success-status-color

    .new-option-label:before
      display: none

  .MuiBase-root,
  .MuiInputBase-root,
  .MuiInputBase-input,
  .MuiAutocomplete-popper .MuiAutocomplete-option .MuiAutocomplete-groupLabel
    font-family: 'Barlow', 'Helvetica', 'Arial', sans-serif
    letter-spacing: 0

  .MuiAutocomplete-groupLabel
    font-weight: 600
    color: $theme-dark-grey

  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
    padding: 5px 7px
    background-color: white

    input.MuiAutocomplete-input,
    button.MuiAutocomplete-input > div
      padding: 5px 4px 5px 6px

    button.MuiAutocomplete-input
      padding: 0 // clicking on button and not its content breaks on Safari

    .MuiChip-root
      @extend %paragraph-small
      height: 24px
      border-radius: 4px

      .MuiChip-label
        padding-left: 8px

    .MuiChip-deleteIcon
      width: 16px
      height: 16px

      &:hover
        color: $text-primary-color

    button.MuiOutlinedInput-input
      height: auto

    .MuiChip-deleteIcon
      width: 20px
      height: 20px

  input::placeholder
    color: $deprecated-text-disabled-color
    opacity: 1

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline
    border: 1px solid $input-border-active-color

  .MuiOutlinedInput-root:not(.Mui-focused):hover .MuiOutlinedInput-notchedOutline
    border-color: $theme-grey-color

  .MuiAutocomplete-endAdornment
    display: flex
    align-items: center
    top: initial
    right: 0

  .MuiAutocomplete-popupIndicator
    padding: 10px 12px
    margin-right: -4px

  .MuiAutocomplete-clearIndicator
    padding: 7px 6px
    color: $theme-primary-color

  .MuiIconButton-root:hover
    background-color: transparent

  .MuiTouchRipple-root
    display: none
  .MuiChip-root, .MuiChip-deleteIcon
    color: $text-primary-color
  .MuiChip-outlined
    border: 1px solid $border-default-color
    background-color: $bg-grey-color

  &.disabled
    .MuiOutlinedInput-root
      background-color: $theme-lighter-color

    &.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
      .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
        padding-right: 0

    .MuiAutocomplete-popupIndicator svg
      fill: $text-primary-color

    .MuiChip-root
      color: $text-primary-color
      cursor: default
      background-color: $theme-lighter-color
    .MuiChip-root, .MuiChip-deleteIcon
      color: $text-primary-color
    .MuiChip-outlined
      border: 1px solid $deprecated-text-disabled-color

  &.has-error
    .MuiOutlinedInput-root,
    .MuiOutlinedInput-root:hover
      background-color: var(--bg-input-error-color)

      .MuiOutlinedInput-notchedOutline
        border-color: var(--border-error-color)

  &.has-field-helper:not(.is-open)
    .MuiOutlinedInput-root:not(.Mui-focused)
      .MuiOutlinedInput-notchedOutline
        border-bottom-right-radius: 0
        border-bottom-left-radius: 0

  &.search-disabled
    .MuiAutocomplete-inputRoot
      cursor: pointer

    button.MuiAutocomplete-input
      cursor: pointer
      text-align: left

  &.center-button
    button.MuiAutocomplete-input
      text-align: center

    &.MuiAutocomplete-hasPopupIcon
      .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
        button.MuiAutocomplete-input > div
          +screen-max(480px)
            padding-left: 39px

  &.dense
    &.MuiAutocomplete-hasPopupIcon
      .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
        padding: 0 30px 0 0

        input.MuiAutocomplete-input,
        button.MuiAutocomplete-input > div
          padding: 5px 8px

  &-listbox--normal-font-weight .combobox-option-name
    font-weight: 400

.combobox-option-name
  margin-bottom: 4px
  display: flex
  align-items: center
  font-weight: 500
  color: $deprecated-text-primary-color

  svg
    margin-right: 5px
    max-width: 18px
    max-height: 18px

  .MuiAutocomplete-input &
    font-weight: inherit

.combobox-option-description
  font-size: 14px
  line-height: 20px
  color: $deprecated-text-disabled-color

.MuiPaper-root.MuiAutocomplete-paper
  border: 1px solid $input-border-active-color
  box-shadow: 0 4px 12px rgba(5, 117, 158, 0.12)
  letter-spacing: 0

  .MuiAutocomplete-listbox
    font-family: 'Barlow', 'Helvetica', 'Arial', sans-serif
    padding: 0
    max-height: $dropdown-max-height
    width: inherit

    .MuiAutocomplete-option
      line-height: 1.1
      width: inherit

      .combobox-option-content
        line-height: 1.1

      .option-icon
        width: 24px
        height: 24px

      .option-name
        line-height: 1.2

      .spread
        flex-grow: 1
        max-width: 100%

        // WARNING!
        // shameful css to fix long email in connection picker
        // please review dropdown usage and cases to address it better
        flex-wrap: nowrap

        & > div:first-child
          flex: 1
          min-width: 50%

          +screen-min(768px)
            min-width: 25%
      // end of shame

      &:hover,
      &[aria-selected="true"]
        background-color: $bg-grey-color

    .MuiAutocomplete-option[aria-disabled="true"]
      opacity: 1
      cursor: not-allowed
      color: $deprecated-text-disabled-color

    .MuiAutocomplete-option[aria-selected="true"]
      background-color: $bg-grey-color

    // ConnectionPicker uses that
    .option-add-new
      color: $theme-primary-color
      border-top: 1px solid rgba(5, 117, 158, 0.15)
      width: 100%

      button
        width: 100%
        font-size: $text-size-xxs
        line-height: 1.8
        text-transform: uppercase

    &:not(.with-option-add-new) .MuiAutocomplete-option,
    &.with-option-add-new .MuiAutocomplete-option:not(:last-child),
    &.with-option-add-new .MuiAutocomplete-option:last-child .option-add-new button
      padding: 11px 12px

    &.with-option-add-new .MuiAutocomplete-option:last-child
      padding: 0
      min-height: auto

    &.options-with-description
      .MuiAutocomplete-option
        min-height: 62px

.MuiAutocomplete-more-items
  @extend %paragraph-small
  padding: 0 4px
  color: var(--primary-color)
