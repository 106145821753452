@import "react-datepicker/dist/react-datepicker.css"

.react-datepicker
  &.datepicker
    font-family: 'Barlow', 'Helvetica', 'Arial', sans-serif
    font-size: $text-size-base
    border: 1px solid $body-divider-color
    border-radius: 5px
    box-shadow: 0 4px 12px rgba(5, 117, 158, 0.12)

  &__input-container
    input
      box-sizing: border-box
      width: 100%
      padding: 7px 38px 7px 12px
      border: 1px solid $input-border-color
      border-radius: 5px
      word-spacing: 10px

      &:active,
      &:focus
        border-color: $input-border-active-color

      &:hover
        border-color: $input-border-hover-color

      &::placeholder
        color: $deprecated-text-disabled-color

      &[disabled]
        pointer-events: none
        background-color: $theme-lighter-color

    &::before
      content: ""
      position: absolute
      background-image: url('~icons/data-types/ic-date.svg')
      background-repeat: no-repeat
      top: 50%
      right: 5px
      width: 24px
      height: 24px
      transform: translateY(-50%)
      background-size: 24px 24px
      filter: invert(31%) sepia(99%) saturate(522%) hue-rotate(155deg) brightness(97%) contrast(103%)

  .datepicker-dropdown
    width: auto

    &.datepicker-dropdown-month
      width: 116px
      margin-right: 4px

    &.datepicker-dropdown-year
      width: 70px

  .react-datepicker__triangle
    display: none

  .react-datepicker__header
    padding: 0
    background-color: #fff
    border-bottom: 1px solid $body-divider-color

  .datepicker-arrow-button
    width: 36px
    height: 36px

    svg
      width: 8px
      fill: $theme-primary-color

    &:hover
      svg
        fill: $theme-primary-darker-color

  .react-datepicker__day-names
    padding: 7px 12px 0
    margin-bottom: 0

  .react-datepicker__day-name
    width: 36px
    height: 20px
    margin: 0 0 9px
    font-weight: 500
    color: $deprecated-text-primary-color
    text-transform: capitalize

  .react-datepicker__month
    padding: 12px 23px
    margin: 0

  .react-datepicker__day--today
    font-weight: normal

  .react-datepicker__day
    width: 36px
    height: 36px
    margin: 0
    line-height: 36px
    color: $deprecated-text-primary-color
    border: 1px solid transparent

    &:hover
      background-color: $theme-lighter-color
      border: 1px solid $theme-lighter-color

    &.react-datepicker__day--in-range,
    &.react-datepicker__day--in-selecting-range
      background-color: $theme-lighter-color
      border: 1px solid $theme-lighter-color

    &.react-datepicker__day--selected,
    &.react-datepicker__day--keyboard-selected,
    &.react-datepicker__month-text--keyboard-selected,
    &.react-datepicker__quarter-text--keyboard-selected,
    &.react-datepicker__year-text--keyboard-selected,
    &.react-datepicker__day--range-start,
    &.react-datepicker__day--range-end
      color: $text-theme-color
      background-color: $theme-lighter-color
      border: 1px solid $theme-primary-color
      border-radius: 5px

      &:hover
        background-color: $theme-lighter-color

    &.react-datepicker__day--keyboard-selected
      color: unset
      background: none
      border: 1px solid transparent

    &.react-datepicker__day--outside-month
      color: $deprecated-text-disabled-color
      pointer-events: none

    &.react-datepicker__day--disabled
      pointer-events: none

  .react-datepicker__input-time-container
    margin: 0
    border-top: 1px solid $body-divider-color
    border-bottom: 1px solid $body-divider-color

    .react-datepicker-time__input-container
      width: 100%

    .react-datepicker-time__input-container .react-datepicker-time__input
      width: 100%
      margin: 0

    .react-datepicker-time__input-container .react-datepicker-time__input input
      width: 40px

    .react-datepicker-time__caption
      display: none

    .datepicker-time-picker-wrapper
      display: flex
      align-items: center
      padding: 12px 23px

      .datepicker-time-picker-label
        margin: 0 16px 0 0
        font-weight: 500

      .combobox
        margin-right: 4px

        &:last-child
          margin-right: 0

  .react-datepicker__children-container
    width: auto
    margin: 0
    padding: 0

    .datepicker-action-buttons
      text-align: right

      .button
        margin: 10px 23px 10px 0

      .button,
      .outlined-button
        width: 100px

.datepicker-container
  .react-datepicker-wrapper
    display: block

  &.has-field-helper
    .react-datepicker__input-container input
      margin-bottom: 0
      border-bottom-right-radius: 0
      border-bottom-left-radius: 0

  &.has-error
    .react-datepicker__input-container input
      background-color: var(--bg-input-error-color)
      border-color: var(--border-error-color)
