.key-value-field-date
  background-color: var(--body-bg-color)
  border: 1px solid $border-default-color
  color: $text-primary-color

  .react-datepicker__input-container input
    @extend %paragraph-small
    border: 0px
    padding: 5px 0px 5px 12px
    background-position: calc(100% - 6px) center

  &.size-medium
    .react-datepicker__input-container input
      @extend %paragraph-medium
      padding: 6px 0px 5px 7px

.react-datepicker-popper.key-value-field-date-popper
  z-index: $z-index-date-picker

