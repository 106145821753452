.button
  padding: 10px 20px
  font-size: 12px
  line-height: 20px
  color: #fff
  background-color: $theme-primary-color
  border: 0
  +button
  &:not(.disable-ripple)
    +ripple-effect

  .loader > div
    color: #fff

  &:hover:not(:disabled):not(.disabled)
    color: #fff
    background-color: darken($theme-primary-color, 5%)

  &.split-button-trigger
    padding: 10px

    > svg
      margin-right: 0

  &.success
    background-color: $success-status-color

    &:hover:not(:disabled):not(.disabled),
    &:focus:not(:disabled):not(.disabled)
      background-color: darken($success-status-color, 5%)

  &.danger
    color: #fff
    background-color: $error-status-color

    &:hover:not(:disabled):not(.disabled),
    &:focus:not(:disabled):not(.disabled)
      color: #fff

  &.warning
    color: #fff
    background-color: $warning-status-color

  &-support
    display: inline
    padding: 0
    color: $theme-primary-color
    text-decoration: underline
    background: none

.outlined-button
  padding: 9.5px 20px
  font-size: 12px
  line-height: 20px
  color: $deprecated-text-disabled-color
  letter-spacing: 0.01em
  background-color: transparent
  border: 1px solid currentColor
  +button
  +ripple-effect

  &:hover:not(:disabled):not(.disabled),
  &:focus:not(:disabled):not(.disabled)
    color: darken($deprecated-text-disabled-color, 10%)

  &.success
    &:hover:not(:disabled):not(.disabled),
    &:focus:not(:disabled):not(.disabled)
      color: $success-status-color

  &.danger
    &:hover:not(:disabled):not(.disabled)
      color: $error-status-color

  &.primary
    color: $theme-primary-color

.flat-button
  min-width: 80px
  line-height: 26px
  padding: 7px 9px
  text-align: center
  text-transform: uppercase
  font-size: 13px
  font-weight: 600
  border-radius: 5px
  cursor: pointer

  &:hover:not(:disabled):not(.disabled)
    background-color: $theme-lighter-color

.button, .outlined-button
  .loader
    padding: 0
    margin: 0 5px -4px 0

.text-button
  padding: 10px 20px
  font-size: 14px
  line-height: 20px
  color: $deprecated-text-primary-color
  text-decoration: underline

  &:hover:not(:disabled):not(.disabled)
    color: $deprecated-text-secondary-color
