.menu-button
  &-label
    display: flex
    align-items: center
    justify-content: center
    min-width: 30px
    min-height: 30px
    color: $theme-primary-color
    border: 1px solid transparent
    border-radius: 5px
    flex-shrink: 0

    &[aria-expanded='true']
      color: #05759e
      border: 1px solid $body-divider-color

    &:hover,
    &:focus
      color: #05759e

  &-list
    min-width: 136px
    background-color: #fff
    border: 1px solid rgba(5, 117, 158, 0.15)
    border-radius: 5px
    z-index: 50

  &-item
    display: flex
    align-items: center
    padding: 12px
    font-size: 14px
    font-weight: 500
    line-height: 16px
    text-align: left
    cursor: pointer

    .loader
      padding-right: 0
      padding-left: 0
      .MuiCircularProgress-colorPrimary
        color: #879CA5

    &:last-child
      border-bottom: 0

    &:hover
      background-color: #f6fafb

    &[aria-disabled='true']
      cursor: default
      pointer-events: none
      opacity: 0.3

      &:hover
        background-color: transparent

    &.danger svg path
      stroke: $error-status-color
      fill: transparent

  &-icon
    display: flex
    margin-right: 5px
