.interval-toggle-container
  display: flex
  flex-direction: column

.interval-toggle-switch
  display: flex
  width: fit-content
  align-self: center
  padding: 2px
  background: $theme-primary-color
  border-radius: 100px
  color: #fff

  li
    display: flex
    padding: 8px 21.5px
    font-size: 14px
    line-height: 20px
    font-weight: 500
    border-radius: 100px
    cursor: pointer

    &.active
      color: $theme-primary-color
      background: #fff
      cursor: default
