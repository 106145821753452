.row-filtering
  &__container
    display: flex
    flex-direction: column
    overflow: hidden

    .filter-box
      background-color: $bg-grey-light-color
      border: 1px solid $border-default-color
      border-radius: 6px
      padding: 12px 12px 8px 12px

      .ui-lib-condition-input,
      .delimiter
        margin-bottom: 4px

      .action-button
        margin-left: -1px // #goal align with OR

    .no-filters
      &__text
        @extend %paragraph-medium
        margin-bottom: 0

    .delimiter
      text-transform: uppercase
      color: $text-secondary-color
      font-size: $text-size-xs
      position: relative

      &__or
        padding: 3px 40px

        span
          background-color: var(--body-bg-color)

      &__and
        padding: 4px 26px

        span
          background-color: $bg-grey-light-color

      &::before
        content: ''
        position: absolute
        top: 50%
        left: 0
        width: 100%
        height: 1px
        background-color: $border-default-color

      span
        position: relative
        z-index: 1
        padding: 0 $space-s

    .filter-box,
    .delimiter__or
      margin-bottom: 8px
      margin-inline: 12px

  &__content-dropdown
    width: var(--row-popover-width)
    border: 1px solid $border-focused-color

  &__title
    @extend %heading-xsmall-h5
    padding: 12px

  &__action-buttons
    display: flex
    justify-content: flex-end
    gap: 8px
    padding: 12px

  @media screen and (max-width: 560px)
    &__content-dropdown
      width: auto
