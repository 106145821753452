.welcome
  &-container
    height: auto

    .report-templates__categories
      padding-top: 0

  &__header
    display: flex
    background: $bg-grey-color
    margin: 0 auto 24px
    padding: 8px 48px 8px 72px
    justify-content: space-between
    align-items: center
    position: sticky
    top: 0
    z-index: $z-index-header
    width: 100%

  &__close-button
    cursor: pointer
    color: var(--muted-color)
    flex-shrink: 0

    svg
      transform: scale(2.1)

  &__h1
    margin: 0
    text-align: center

  &__form
    display: flex
    flex-direction: column
    gap: $spaceLarge
    width: clamp(400px, 50vw, 600px)
    margin: auto

    &-field
      display: flex
      flex-direction: column
      gap: $spaceSmall

      &-placeholder
        color: $text-disabled-color

    &-actions
      display: flex
      flex-direction: column
      gap: $spaceSmall

  +screen-max(768px)
    &__header
      padding: 8px 16px
      margin: 0
