.outgoing-webhooks
  position: relative

  &__input
    label
      display: none

  &--inactive
    &.card
      margin-bottom: 0

      +screen-max(768px)
        padding-bottom: 47px

    .outgoing-webhooks__title,
    .outgoing-webhooks__text
      color: $theme-grey-color

