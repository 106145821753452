.credential-form-wrapper
  height: 100vh
  display: flex
  justify-content: center
  align-items: center

.credential-form-card
  width: 28rem
  min-height: 28rem
  border: 1px solid $border-default-color
  box-shadow: 0px 8px 24px 0px rgba(3, 81, 109, 0.12)
  background: var(--body-bg-color)
  border-radius: 0.75rem
  padding: 2rem 2.5rem

.credential-form
  display: flex
  flex-direction: column
  min-height: 24rem

.credential-form__header
  color: var(--dark-color)
  font-size: 1.5rem

.credential-form__info
  display: flex
  align-items: center
  gap: 1.5rem

.credential-form__info-right
  display: flex
  flex-direction: column
  flex: 1

.credential-form__info-right-name
  font-weight: 700

.credential-form__sharing
  flex: 1
  align-self: stretch
  display: flex
  margin: 1.5rem 0
  padding-top: 1rem
  gap: 0.5rem

.credential-form__sharing-right
  flex: 1
  display: flex
  flex-direction: column
  gap: 0.5rem

.credential-form__sharing-right-heading
  font-weight: 600
  font-size: 1rem
  line-height: 1.5rem

.credential-form__sharing-right-description
  font-size: 0.8125rem

.credential-form__sharing-right-link
  font-size: 0.8125rem

.credential-form__button
  min-height: 2.5rem
  font-size: 0.75rem
  font-weight: 600

.apple-credential-form
  padding-bottom: 8px

  ul
    list-style: none

    > li
      @extend %paragraph-medium
      color: $text-primary-color

.apple-credential-form__header
  display: flex
  gap: 16px

.apple-credential-form__public-key
  padding: 12px 0 0 24px

  &-header
    @extend %subheading-medium

.apple-credential-form__public-key-details
  padding: 8px 0 0 0
  display: flex
  gap: 5px
  align-items: flex-start

.apple-credential-form__generate-keys
  padding-left: 18px
  margin: 0.5rem 0 0.5rem 0
