.entries
  .entry
    position: relative
    display: flex
    align-items: center
    justify-content: space-between
    padding: 16px 24px
    margin-bottom: 4px
    background: #fff
    border: 1px solid rgba(5, 117, 158, 0.15)
    border-radius: 5px

  .entry-box
    display: flex
    flex-direction: column

  .entry-prefix
    display: flex
    padding-right: 15px

  .entry-root
    flex-grow: 1
    word-break: break-word
    min-width: 25%
    padding-right: 15px

    + .entry-suffix
      padding-left: 30px

    .danger
      word-break: break-word
      line-height: 1rem

      a
        color: $theme-primary-color

    .badge
      align-self: flex-start

  .entry-suffix
    display: flex
    flex-shrink: 0
    align-items: center

    &--actions
      align-self: flex-start
      margin-top: 28px

    .button,
    .action-button,
    .outlined-button
      min-width: 117px

    .combobox
      width: unset
      min-width: 127px

      .combobox-option-content
        white-space: nowrap
        display: inline-block

    .tooltip-children
      cursor: default

      > *
        width: 100%

    > *
      margin-right: 8px

      &:last-child
        margin-right: 0

  &.with-checkboxes
    padding-left: 45px

    +screen-max(768px)
      padding-left: 0

    .entry-checkbox
      position: absolute
      top: 50%
      left: -33px
      transform: translateY(-50%)

      +screen-max(768px)
        display: none

  &.importers
    .entry
      border: 1px solid rgba(5, 117, 158, 0.4)

    .entry-prefix
      padding-bottom: 13px

  +screen-max(1024px)
    .entry
      flex-direction: column
      align-items: stretch

      &-prefix
        margin-bottom: 13px

      &-root
        + .entry-suffix
          padding: 0

      &-suffix
        justify-content: flex-start
        margin-top: 13px

      .menu-button-label
        position: absolute
        top: 20px
        right: 4px
        width: auto

  +screen-max(480px)
    .entry-suffix
      flex-direction: column
      align-items: stretch

      &--actions
        align-self: stretch

      .button, .outlined-button
        margin-right: 0
        width: 100%

      > *
        margin-right: 0
        margin-bottom: 8px
        width: 100%

        &:last-child
          margin-bottom: 0
