.radio-group
  padding: 12px
  font-size: 14px
  text-align: left

  &-title
    margin-bottom: 12px
    font-size: $text-size-xs
    font-weight: 500

  label
    display: flex
    flex-direction: row
    align-items: center
    color: var(--text-disabled-color)
    cursor: pointer

    &.checked
      color: var(--text-primary-color)

  label + label
    margin-top: 8px

  input
    display: none

  svg
    margin-right: 8px

  & + &
    border-top: 1px solid rgba(135, 156, 165, 0.3)
