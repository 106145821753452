.data-grid
  &__toolbar
    display: flex
    gap: $space-s
    background-color: $theme-white
    align-items: center
    padding-inline: 24px
    top: 0

    &__spacing
      flex-grow: 10

    &-divider
      height: 24px
      padding: 4px 0
      margin: 8px
      border: 1px solid $border-default-color

  &-footer
    @extend %subheading-medium
    position: relative
    display: flex
    justify-content: space-between
    width: 100%
    align-items: center
    bottom: 0
    padding: 8px 20px
    background: white
    border-top: 1px solid $border-default-color
    border-bottom: 1px solid $border-default-color
    color: $text-secondary-color
    &__actions
      display: flex
      gap: 8px

    &__rows
      color: $text-primary-color
      margin-left: 4px

    .info-icon > svg
      color: var(--primary-color)
      width: 19px
      height: 19px

  &-column
    &__label-icon
      svg
        color: $text-primary-color
        margin-right: 4px
        width: 20px
        height: 20px
        min-width: 20px
        min-height: 20px

  &-loader
    display: flex
    flex-direction: column
    align-items: center
    gap: 16px
    margin-top: 200px

    &__title
      @extend %heading-xsmall-h5

    &__description
      @extend %paragraph-medium

    .MuiCircularProgress-svg
      color: var(--icon-info-color)
