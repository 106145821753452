.column-edit-modal
  border: 1px solid $theme-primary-color

  &:has(.column-formula-field)
    &[data-reach-dialog-content]
      max-width: clamp(564px, 80vw, 1200px)

  &[data-reach-dialog-content]
    max-width: 564px

  &__original-name-hint
    display: block
    margin-bottom: 24px
