.invitation-section
  .column
    margin-bottom: 0

  @media screen and (max-width: 970px)
    flex-direction: column

    form
      text-align: left

  @media screen and (max-width: 555px)
    form
      .button
        display: block
        margin: 20px 0 0
        max-width: 100px
        width: 100%
