$wizard-sidebar-tabs-collapsed-width: 73px
$wizard-sidebar-tabs-expanded-width: 320px

.wizard
  &-wrapper
    display: flex
    flex-direction: row
    height: calc(100vh - var(--wizard-header-height) - var(--billing-alert-height))
    background-color: var(--body-bg-color)

  &-content
    flex-grow: 1
    width: 100%
    padding: 0
    margin-bottom: 0
    margin-left: 0
    overflow-x: hidden
    box-sizing: border-box
    height: 100%
    display: flex
    flex-direction: column

    .wizard-wrapper[data-sidebar="collapsed"] &
      width: calc(100% - #{$wizard-sidebar-tabs-collapsed-width})
      margin-left: $wizard-sidebar-tabs-collapsed-width

    .wizard-wrapper[data-sidebar="expanded"] &
      width: calc(100% - #{$wizard-sidebar-tabs-expanded-width})
      margin-left: $wizard-sidebar-tabs-expanded-width

    .wizard-content__spacing
      height: 100%
      overflow: auto
      padding: 32px 32px 80px 32px

      +screen-min(1280px)
        padding-inline: 56px
      +screen-max(1065px)
        padding-inline: 18px
      +screen-max(1030px)
        padding-inline: 16px

    .wizard-footer, .wizard-basic-footer
      border-top: 1px solid var(--border-default-color)
      background-color: var(--body-bg-color)
      flex-shrink: 0
      padding: 12px 16px

      +screen-min(1280px)
        padding-inline: 56px

      .wizard-footer__content
        display: flex
        flex-direction: row-reverse
        justify-content: space-between

        +screen-max(744px)
          flex-direction: column-reverse
          align-items: center

        .action-button
          height: 48px

          +screen-max(480px)
            width: 100%

      .wizard-footer__button-group
        display: flex
        gap: 8px

        +screen-max(480px)
          width: 100%
          flex-direction: column

    .wizard,
    .wizard-summary,
    .wizard-footer__content
      width: 100%
      max-width: var(--wizard-max-width)
      margin-inline: auto
