.tooltip,
.tooltip.MuiTooltip-tooltip
  @extend %captions-medium
  max-width: 357px
  color: $text-on-primary-color
  background-color: var(--dark-color)
  border-radius: 5px
  box-shadow: 0px 1px 8px rgba(3, 60, 82, 0.12)
  padding: 10px

  &.MuiTooltip-tooltipPlacementRight
    margin-left: 30px

  .MuiTooltip-arrow
    color: var(--dark-color)

  .button-support
    font-size: 13px

  &-children
    position: relative
    cursor: pointer

    &.current-usage
      margin-left: 0
      white-space: nowrap
      cursor: default

      svg
        margin-left: 5px

    svg
      vertical-align: sub

    &.warning-icon
      margin-left: 10px
      vertical-align: baseline

    &.external-icon
      color: $deprecated-text-primary-color

      &:hover
        color: #00759e

      svg
        margin-left: 5px
        width: 14px

    &.info-icon
      position: relative

      svg
        position: absolute
        top: 50%
        margin: 0
        color: $deprecated-text-disabled-color
        transform: translateY(-50%)

