.wizard
  &-integrations
    display: grid
    grid-template-columns: repeat(3, minmax(100px, 1fr))
    gap: 16px

    &__empty-section
      .illustration
        color: $info-default-color // We don't have a variable for this color 🤷🏻
        margin: 24px 0 8px

      .back-button
        @extend %subheading-medium
        display: inline-flex
        align-items: center
        color: var(--primary-color)


    &__search
      background-color: var(--body-bg-color)

  &-integration
    $integration: &
    display: flex
    align-items: center
    gap: 8px
    padding: 6px 12px
    background-color: var(--bg-low-color)
    border-radius: 8px
    border: 1px solid var(--border-default-color)
    min-height: 56px

    .loader
      margin: 0 8px

    &__content
      display: flex
      flex-direction: column
      gap: 4px

    &__title
      @extend %subheading-medium
      color: var(--text-secondary-color)

    &__description
      @extend %captions-small

      &.muted
        color: var(--text-disabled-color)

    &--disabled
      background-color: var(--bg-disabled-color)
      color: var(--text-disabled-color)
      cursor: not-allowed

    &--not-allowed
      color: var(--icon-disabled-color)
      margin-left: auto

    &:hover:not(#{$integration}--disabled)
      background-color: var(--body-bg-color)
      border-color: var(--border-brand-hover-color)
      cursor: pointer
      box-shadow: 0px 1px 8px 0px #033C521F

      #{$integration}-title
        color: var(--text-brand-color)
