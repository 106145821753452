$sticky-shadow-size: 5px

\:root
  /* default height with one line title */
  --sticky-header-top: 71px
html
  scroll-behavior: smooth

html,
body
  height: 100%

body
  width: 100%
  background: #f6fafb

  &.scroll-disabled
    position: fixed
    overflow: hidden

div[tabindex]
  outline: 0

.main
  position: relative
  flex: 1
  min-height: calc(100vh - 56px - var(--billing-alert-height))
  padding-top: 40px
  padding-bottom: 40px
  box-sizing: border-box

  .with-toolbar &
    padding-left: var(--toolbar-width)

  .with-toolbar--pinned &
    padding-left: var(--toolbar-wide-width)

  .with-wizard &, .with-dashboard &
    padding-top: 0
    padding-bottom: 0

    & > .loader-is-fullscreen
      margin: 32px

  .with-dashboard &
    display: flex
    flex-direction: column

.wrapper
  position: relative
  display: flex
  flex-direction: column

.container
  display: flex
  flex-direction: column
  min-width: 320px
  max-width: 1280px
  height: 100%
  padding: 0 32px
  margin: 0 auto

  +screen-max(700px)
    padding: 0 16px

  &.narrow
    max-width: 800px

  &.mt40
    margin-top: 40px

.marketing-main
  min-height: calc(100vh - 88px)
  padding: 0px
  background: #ffffff

.with-wizard .wrapper
  height: 100%

.gsheet-addon-embedded.with-wizard .wrapper
  border-radius: 8px
  border: 1px solid $border-default-color
  overflow: hidden

.sticky-wrapper
  position: sticky
  top: -$sticky-shadow-size
  backface-visibility: hidden
  z-index: 10
  background-color: #FFFFFF

  &::before, &::after
    /* make pseudo elements sticky as well */
    position: sticky
    content: ""
    display: block

  &::before
    /* sticky header height - 5px - 1px for shadow */
    top: calc(var(--sticky-header-top) - #{$sticky-shadow-size})
    /* shadow is at bottom of element */
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5)
    /* hide shadow from left and right sides of header */
    margin: 0 3px
    height: $sticky-shadow-size

  &::after
    /* linear gradient from background color to transparent acts as
      a transition effect so the shadow appears gradually */
    background: linear-gradient(white 10%, rgba(255, 255, 255, 0.8) 30%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.3) 70%, #FFFFFF)
    top: 0
    height: 5px
    /* cover should fall over shadow */
    z-index: 9

.spread
  display: flex
  align-items: center
  justify-content: space-between

  &.sticky
    position: sticky
    top: 0
    /* content should fall over shadow and cover */
    z-index: 10
    /* compensate for shadow with negative margin */
    margin-top: -$sticky-shadow-size
    background-color: #FFFFFF
    gap: 15px

hr
  margin: 15px 0
  border-color: $body-divider-color
  border-top: 0

body
  &.lock-scroll
    overflow: hidden

  &.gsheet-addon-embedded
    background: #FFFFFF

    .main
      padding-top: 0

    .container
      padding: 5px

    .app-header
      width: 100%

  &.with-dashboard
    background: var(--bg-lower-color)
