.switch
  position: relative
  display: flex
  align-items: center
  width: 56px
  height: 26px
  padding: 0 10px
  font-size: 14px
  line-height: 26px
  color: #fff
  letter-spacing: -0.02em
  border-radius: 30px
  flex-shrink: 0

  &[aria-checked='false']
    color: var(--dark-color)
    text-align: right
    justify-content: flex-end
    background-color: $border-default-color

    &:hover
      background-color: var(--muted-color)

    &:focus
      background-color: $border-default-color
      outline: 1px solid var(--dark-color)

      &:hover
        background-color: var(--muted-color)

    &[disabled]
      color: var(--muted-color)
      background-color: $border-default-color

      &::after
        background-color: var(--muted-color)

  &[aria-checked='true']
    text-align: left
    justify-content: flex-start
    background-color: $success-status-color

    &:hover
      background-color: $success-hover-color

    &:focus
      background-color: $success-status-color
      outline: 1px solid $success-pressed-color

      &:hover
        background-color: $success-hover-color

    &[disabled]
      background-color: $bg-success-color

  &[disabled]
    cursor: default

  &::after
    position: absolute
    top: 50%
    left: 2px
    width: 22px
    height: 22px
    content: ''
    background-color: #fff
    border-radius: 50%
    will-change: transform
    transition: transform 150ms ease-out
    transform: translateY(-50%)

  &[aria-checked='true']
    &::after
      transform: translate(30px, -50%)

  // supports icon only, no text
  &--size-small
    width: 36px
    height: 20px
    padding: 2px
    line-height: 0

    .switch__label
      margin-left: 2px

    &::after
      width: 16px
      height: 16px

    &[aria-checked='true']
      &::after
        transform: translate(16px, -50%)
