.wizard-scheduling
  border: 1px solid var(--border-default-color)
  border-radius: 8px

  &__spread
    display: flex
    align-items: center
    padding: 16px
    background: var(--body-bg-color)
    border-radius: 8px 8px 0 0

    &.disabled
      border-radius: 8px
      background: $bg-grey-color

    .title
      @extend %heading-xsmall-h5

    .tip
      @extend %paragraph-medium
      margin-left: auto

    .switch
      width: 62px
      height: 32px

      &::after
        width: 28px
        height: 28px

  .danger
    border-radius: 8px
    background: $bg-grey-color
    padding: 16px
    margin-bottom: unset

  .form
    border-top: 1px solid var(--border-default-color)
    border-radius: 0 0 8px 8px
    background: var(--bg-low-color)
    padding: 16px

    .group
      display: flex
      column-gap: 16px

      .field
        &:nth-child(1)
          flex-basis: 40%
        &:nth-child(2)
          flex-basis: 60%

        .checkbox-group-input
          width: 44px
          height: 39px

        &.day-of-month
          .combobox
            width: 40%

    .checkbox-group
      +screen-max(480px)
        flex-wrap: wrap
        gap: 4px
