.importers-table
  tbody
    tr
      td:first-child
        width: 64px

      td:nth-child(2)
        width: 60%

      td:nth-child(3)
        vertical-align: bottom
        width: 30%
