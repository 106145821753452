@import 'highcharts/css/highcharts';
@import './funnel.css';
@import './table.css';

.dashboard-theme-default {
  --gridline-color: #eceef3;
  --gridline-width: 1;

  --highcharts-background-color: #fff;
  --highcharts-color-0: #05759e;
  --highcharts-color-1: #ac3d31;
  --highcharts-color-2: #e3ac1e;
  --highcharts-color-3: #894c7a;
  --highcharts-color-4: #3cab61;
  --highcharts-color-5: #515f7a;
  --highcharts-color-6: #bcbcbc;
  --highcharts-color-7: #12baf7;
  --highcharts-color-8: #6541a1;
  --highcharts-color-9: #3cab8a;
  --highcharts-color-10: #8d1951;
  --highcharts-color-11: #c6680f;

  .highcharts-root {
    font-family: var(--barlow-font-family);
    --highcharts-neutral-color-80: var(--text-secondary-color);
    --highcharts-neutral-color-100: var(--text-primary-color);
  }

  .highcharts-title, .dashboard-component-header {
    font-family: var(--inter-font-family);
    font-weight: 700;
    font-size: var(--text-size-s);
    fill: var(--text-primary-color);
  }

  .highcharts-grid-line {
    stroke: var(--gridline-color);
  }

  .with-full-2d-grid {
    .highcharts-xaxis-grid {
      .highcharts-grid-line {
        stroke-width: var(--gridline-width);
      }
    }

    .highcharts-yaxis-grid {
      .highcharts-grid-line {
        stroke-width: var(--gridline-width);
      }
    }
  }

  .without-yaxis-grid {
    .highcharts-yaxis-grid {
      .highcharts-grid-line {
        stroke-width: 0;
      }
    }
  }

  .with-threshold-marker .highcharts-dial {
    /* This allows us apply series.gauge.colorIndex to the gauge dial fill color */
    fill: inherit;
  }

  .highcharts-legend-item > text {
    font-weight: normal;
    font-size: var(--text-size-xs);
  }

  .highcharts-no-data > text {
    font-size: var(--text-size-m);
    font-weight: normal;
  }

  .highcharts-data-label text {
    font-size: var(--text-size-xs);
  }

  .highcharts-label:not(.highcharts-data-label) > text {
    font-size: var(--text-size-xs);
    font-family: var(--barlow-font-family);
  }

  .highcharts-loading {
    opacity: 0.8;
  }

  .highcharts-loading-inner {
    font-family: var(--barlow-font-family);
    font-size: var(--text-size-m);
    font-weight: normal;
  }

  .highcharts-contextmenu {
    /* make it match our .menu-button-list */
    .highcharts-menu {
      border-radius: 5px;
      border: 1px solid rgba(5, 117, 158, 0.15);
      box-shadow: none;
      padding: 0;

      .highcharts-menu-item {
        font-family: var(--barlow-font-family);
        font-weight: 500;
        font-size: var(--text-size-xs);
        padding: 12px;
      }

      .highcharts-menu-item:hover {
        background-color: #f6fafb;
      }
    }
  }
}
