.loader
  text-align: center

  .MuiCircularProgress-colorPrimary
    color: $theme-primary-color

  &-container
    +screen-min(1280px)
      max-width: 1280px

    +screen-min(600px)
      padding-left: 24px
      padding-right: 24px

  &-is-fullscreen
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center
