%flex-center
  flex-grow: 1
  display: flex
  justify-content: center
  align-items: center
  height: 100%
  width: 100%

.dashboard
  &__error
    @extend %flex-center
    flex-direction: column

    > .text-wrapper
      text-align: center
      max-width: 400px

      > h3
        @extend %subheading-medium
        color: $text-secondary-color

      > .instructions-text
        margin-top: $spaceSmall

    > .text-wrapper--wide
      max-width: 768px

    .warning-icon
      color: #E57010

    .error-icon
      color: var(--icon-danger-color)

    .button-wrapper
      @extend %flex-center
      flex-grow: 0
      gap: $spaceMedium
      margin: $spaceSmall 0

    .error-details
      position: relative

      &--wide
        max-width: 768px

      &__content
        overflow-y: scroll
        border: 1px solid var(--border-default-color)
        border-radius: 5px
        padding: $spaceSmall $spaceLarge 0 $spaceSmall
        max-height: 268px

      &__copy-button-container
        position: absolute
        top: 0
        right: 12px



.dashboard-component
  &__error
    @extend %dashboard-component-common
    @extend %flex-center
    padding: 10px

    > .text-wrapper
      text-align: center
      max-width: 400px

      > h3
        @extend %subheading-medium
        color: $text-secondary-color

    .question-icon
      color: #05759E
