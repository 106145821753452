\:root
  --app-height: 100vh
  --toolbar-width: 0
  --toolbar-wide-width: 0

  +screen-min(476px)
    --toolbar-width: 72px
    --toolbar-wide-width: 240px

.toolbar
  overflow: hidden

  &-separator
    background-color: var(--border-default-color)
    margin: 8px

  &--hidden
    display: none

  &-nav
    position: fixed
    z-index: $z-index-left-menu
    height: calc(var(--app-height) - #{$app-header-height})
    top: calc(#{$app-header-height} + var(--billing-alert-height))
    overflow: hidden
    overflow-y: auto
    background-color: var(--bg-default-color)
    box-shadow: 0px 1px 8px 0px #033C521F

  &-wrapper
    width: var(--toolbar-width)
    transition: width 0.3s ease

    &.wide, &--pinned
      width: var(--toolbar-wide-width)

      .toolbar-item-label,
      .toolbar-header__toggle-button,
      .toolbar-header__full-logo,
      .toolbar-item__submenu
        display: block

      .toolbar-header__small-logo
        display: none

  &-header
    display: flex
    align-items: center
    justify-content: space-between
    padding: 16px 20px

    &__full-logo
      display: none
      height: 32px

    &__small-logo
      flex-shrink: 0

    &__toggle-button
      display: none
      width: 24px
      height: 24px
      color: var(--icon-brand-higher-color)

  &__back-button
    margin: 8px 12px

  +screen-max(476px)
    &-nav
      right: 0

      &:has(.wide)
        box-shadow: 0px 0px 35px 40px #000A2499

    &-header, &-header + hr
      display: none

    &-wrapper
      &.wide
        width: calc(100vw - 40px)

    &-item-icon
      padding: 17px 20px 17px 17px

.toolbar-item
  padding: 4px 8px 4px 5px
  font-size: 14px
  font-weight: 500
  border-left: 3px solid transparent

  &__submenu
    display: none
    padding: 0px 8px 0 48px
    height: 0
    transition: height 0.1s ease

    &--opened
      height: auto // fallback for browsers that don't support calc-size
      height: calc-size(min-content, size)

    .toolbar-item-link
      @extend %subheading-small
      padding: 11px 16px

    &-item
      &:hover
        background-color: var(--bg-lower-color)
        color: var(--text-brand-color)
        border-radius: 4px

      &--current
        .toolbar-item-link
          color: var(--text-brand-color)
          background-color: var(--bg-brand-color)
          border-radius: 4px

        &:hover
          .toolbar-item-link
            background-color: var(--bg-brand-color)
            border-radius: 4px

  &__account
    position: relative
    display: flex
    align-items: center
    gap: 12px

    &--active,
    &:hover
      .toolbar-item__account-button
        background-color: var(--bg-lower-color)
        border-radius: 4px

      .toolbar-item__account-name
        color: var(--text-brand-color)

    &-name
      @extend %subheading-small
      text-align: left
      width: 60%
      text-overflow: ellipsis
      overflow: hidden
      white-space: nowrap

    &-button
      padding: 8px 10px
      display: flex
      align-items: center
      gap: 14px
      width: 100%

    &-arrow-icon
      margin-left: auto

    &.red
      .toolbar-item__account-icon
        background-color: var(--decorative-pale-red-color)

    &.blue
      .toolbar-item__account-icon
        background-color: var(--decorative-cerulean-color)

    &.green
      .toolbar-item__account-icon
        background-color: var(--decorative-algae-color)

    &.yellow
      .toolbar-item__account-icon
        background-color: var(--decorative-sunset-color)

    &.purple
      .toolbar-item__account-icon
        background-color: var(--decorative-eminence-color)

    &-icon
      flex-shrink: 0
      color: var(--bg-default-color)
      position: relative
      border-radius: 50%
      width: 36px
      height: 36px
      background-image: url(~icons/ic-organization.svg)
      background-repeat: no-repeat
      background-position: center
      background-size: 24px

      &::after
        content: ''
        position: absolute
        bottom: 0px
        left: 26px
        width: 13px
        height: 13px
        background-image: url(~icon-selected-company.svg)
        background-repeat: no-repeat
        background-size: 100% 100%

  &-link
    display: flex
    align-items: center
    color: #0a0836
    text-decoration: none

  &-icon
    display: flex
    padding: 12px 16px
    border-radius: 4px

  &-label
    display: none
    padding-left: 3px
    line-height: 14px
    white-space: nowrap

  &:hover,
  &--current
    .toolbar-item-icon svg
      //https://codepen.io/sosuke/pen/Pjoqqp
      filter: brightness(0) saturate(100%) invert(20%) sepia(65%) saturate(3683%) hue-rotate(180deg) brightness(103%) contrast(96%)

  &:hover
    border-left: 3px solid var(--border-primary-color)

    .toolbar-item-link
      background-color: var(--bg-lower-color)
      color: var(--text-brand-color)
      border-radius: 4px

  &--current
    background-color: var(--bg-default-color)
    border-left: 3px solid transparent

    .toolbar-item-link
      color: var(--link-default-color)
      background-color: var(--bg-brand-color)
      border-radius: 4px

    &:hover
      .toolbar-item-link
        background-color: var(--bg-brand-color)
        border-radius: 4px

  &-arrow
    margin-left: auto
    transition: transform 0.1s ease-out
    padding: 0 10px

  &__settings
    cursor: pointer

    &:hover,
    &--current,
    &--opened
      .toolbar-item-link
        background-color: var(--bg-lower-color)
        color: var(--primary-color)
        border-radius: 4px

    &--opened
      .toolbar-item-arrow
        transform: rotate(180deg)

.toolbar
  +screen-min(476px)
    &-nav:hover
      .toolbar-wrapper
        width: var(--toolbar-wide-width)

      .toolbar-item-label,
      .toolbar-header__toggle-button,
      .toolbar-header__full-logo,
      .toolbar-item__submenu
        display: block

      .toolbar-header__small-logo
        display: none
