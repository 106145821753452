.ui-lib-condition-input
  --condition-input-height: 32px

  display: grid
  grid-template-rows: var(--condition-input-height)

  &--medium
    --condition-input-height: 40px

  &--three-columns
    grid-template-columns: 9fr 6fr 9fr var(--condition-input-height)

  &--two-columns
    grid-template-columns: 9fr 9fr var(--condition-input-height)

  &--one-column
    grid-template-columns: 9fr var(--condition-input-height)

  &__fraction
    min-height: var(--condition-input-height)

    &.placeholder
      border: 1px solid $border-default-color
      color: $text-disabled-color
      padding: 6px 12px

    &:first-child
      .MuiOutlinedInput-root
        border-radius: 5px 0 0 5px

    &:nth-child(2)
      margin-left: -1px

    &:nth-child(3)
      margin-left: -2px

    &:not(.placeholder):hover,
    &:not(.placeholder):focus-within
      z-index: 1

  .input-clear-button
    margin-left: -1px

  &__complete-state
    display: flex
    align-items: center
    flex-grow: 1
    gap: 8px
    padding: 0px 8px 0px 12px
    background-color: var(--body-bg-color)
    border: 1px solid $border-default-color
    border-radius: 5px 0 0 5px

    &__label
      @extend %paragraph-small
      color: $text-primary-color
      flex-grow: 1

      &.size-medium
        @extend %paragraph-medium

      &__operator
        color: $text-disabled-color

    &__pencil
      opacity: 0

      path
        fill: var(--dark-color)

    &:hover
      border-color: $border-hover-color
      z-index: 1

    &:hover &__pencil
      opacity: 1
