.wizard-sidebar-tabs
  $parent: &
  height: calc(100vh - var(--wizard-header-height))
  overflow: auto
  display: flex
  flex-direction: column
  flex-shrink: 0
  padding: 16px
  background-color: $bg-grey-color
  border-right: 1px solid $border-default-color
  position: absolute
  width: $wizard-sidebar-tabs-collapsed-width
  transition: all 200ms ease-in-out
  z-index: $z-index-wizard-sidebar

  &__header
    position: sticky
    top: 0
    z-index: 1
    padding-bottom: 8px
    display: flex
    flex-direction: row
    align-items: center
    gap: 8px
    background: inherit
    border-bottom: 1px solid transparent

    // cover the gap between caused by the padding
    &::before
      content: ""
      display: block
      position: absolute
      width: 100%
      top: -16px
      height: 16px
      background: inherit

  &--scrolled &__header
    border-bottom-color: $border-default-color

  &__pin-button
    display: flex
    padding: 8px
    border-radius: 4px
    color: var(--icon-brand-higher-color)

    .expanded-icon
      display: none

    #{$parent}--pinned &
      color: var(--primary-color)

  &__title
    @extend %subheading-medium
    display: none

  &__list
    display: flex
    flex-direction: column
    gap: 4px

  &__item
    $item: &
    padding: 3px 8px 3px 3px
    gap: 4px
    display: flex
    border-radius: 8px
    align-items: center
    flex-direction: row
    flex-shrink: 0
    border: 1px solid transparent
    color: $text-secondary-color
    cursor: pointer

    svg
      flex-shrink: 0

    &-name
      @extend %subheading-small
      color: inherit
      display: none
      flex-grow: 1
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis

    &-warning-icon
      padding: 6px
      color: var(--icon-warning-color)
      display: none

    &-error-icon
      padding: 6px
      color: var(--icon-danger-color)
      display: none

    &:hover
      color: $text-primary-color
      border-color: $border-hover-color

    &--active,
    &:active
      color: $text-primary-color
      border-color: $border-blue-default-color
      background-color: #fff

    &--active:hover
      border-color: $border-blue-hover-color

    &--has-status-icon
      padding-right: 3px

      #{$item}-icon
        display: none

      #{$item}-warning-icon, #{$item}-error-icon
        display: block

  &__actions
    position: sticky
    bottom: 0
    flex-shrink: 0
    padding-top: 8px
    border-top: 1px solid transparent
    background-color: inherit
    height: 100px

    // cover the gap between caused by the padding
    &::after
      content: ""
      display: block
      position: absolute
      width: 100%
      bottom: -16px
      height: 16px
      background: inherit

  &--overflown &__actions
    border-top-color: $border-default-color



  // #goal Makes sure that modal opens "in front of" the sidebar
  //       without breaking dropdowns inside the modal.
  //
  // #reason Sidebar on hover gets a higher z-index than combobox dropdown
  //         to cover it while expanding.
  // #reason Modal has lower z-index than combobox dropdown
  //         not to cover dropdowns used inside modals.
  //
  // #dk Combobox dropdown is rendered using react portal and it always has the same z-index.
  //
  // #approach block pointer events to remove :hover state from the sidebar and push it back.
  &--covered
    pointer-events: none
    transition: none

  &__add-item
    justify-content: left
    flex-shrink: 1

    svg
      flex-shrink: 0

    span
      display: none

  &:hover,
  &--pinned
    width: $wizard-sidebar-tabs-expanded-width

    #{$parent}__title
      display: block

    #{$parent}__item
      width: 100%

      &-name
        display: block

    #{$parent}__item--has-status-icon
      #{$parent}__item-icon
        display: block

    #{$parent}__add-item
      span
        display: block

    .expanded-icon
      display: block

    .collapsed-icon
      display: none

  &:not(&--pinned):hover
    box-shadow: 0 4px 16px #033C5229
    z-index: $z-index-wizard-sidebar-hover
