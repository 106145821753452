.dashboard-table
  height: 100%
  display: flex
  flex-direction: column
  border: 1px solid var(--border-default-color)
  border-radius: 12px
  overflow: hidden

  .ag-root-wrapper
    overflow: hidden
    border: none

    --ag-header-background-color: var(--bg-lower-color)
    --ag-odd-row-background-color: var(--bg-low-color)
    --ag-row-border-color: var(--border-alt-color)
    --ag-border-color: var(--border-default-color)
    --ag-foreground-color: var(--text-primary-color)
    --ag-header-foreground-color: var(--text-primary-color)
    --ag-font-size: 14px

  .ag-header-cell-text
    font-size: var(--ag-font-size)
    font-weight: 500

  .ag-theme-alpine .ag-row
    font-size: var(--ag-font-size)

  &-actions
    position: absolute
    bottom: 6px
    left: 10px

  &-header
    background: var(--body-bg-color)
    padding: 16px 24px
    border-bottom: 1px solid var(--border-default-color)
