.password-toggle-wrapper
  position: relative

.password-toggle-btn
  width: 25px
  height: 25px
  right: 10px
  position: absolute
  top: 10px
  cursor: pointer
  background-color: #030327
  mask: url(images/icons/ic-hide.svg) no-repeat 50% 50%
  user-select: none

.password-toggle-show-state
  mask: url(images/icons/ic-show.svg) no-repeat 50% 50%

.password-toggle-error
  background-color: #CC6152
