.onboarding-wrapper
  max-width: 320px
  height: 100%

  &.completed
    .onboarding-description
      max-width: 180px

.onboarding-progress
  display: flex
  align-items: center
  margin-bottom: 16px

  &-percentage
    font-size: 16px
    letter-spacing: -0.02em
    color: $success-status-color
    margin-right: 9px

  &-num
    font-size: 24px

.onboarding-title
  margin-bottom: 16px

ol.onboarding-list
  list-style: none
  counter-reset: custom-counter
  padding-left: 0

  .onboarding-item
    position: relative
    padding-left: 34px
    margin-bottom: 10px
    line-height: 24px
    counter-increment: custom-counter

    &:last-child
      margin-bottom: 0

    &::before
      position: absolute
      left: 0
      box-sizing: border-box
      display: flex
      align-items: center
      justify-content: center
      width: 24px
      height: 24px
      font-size: 15px
      font-weight: 500
      color: #879ca5
      text-align: center
      content: counter(custom-counter)
      background-color: #f6fafb
      border: 1px solid #cad4d9
      border-radius: 50%

    &.is-checked
      &::before
        content: ''
        background-color: unset
        background-image: url('~icons/ic-checkmark-fill.svg')
        background-repeat: no-repeat
        background-size: 100% 100%
        border: 0
        transform: scale(1.33)
        filter: invert(69%) sepia(8%) saturate(2630%) hue-rotate(77deg) brightness(85%) contrast(79%)


  .onboarding-item-signup
    margin-bottom: 30px

.onboarding-call
  display: flex
  justify-content: space-between
  align-items: center
  margin-top: 17px

  &-title
    font-size: 20px
    letter-spacing: -0.02em
    line-height: 1.5
    margin-bottom: 13px

  &-text
    font-size: 16px
    margin-bottom: 7px

  &-link
    font-size: 16px
    font-weight: 500

  &-img-wrapper
    position: relative
    display: flex
    width: 64px
    min-width: 64px
    height: 64px
    margin-left: 14px

    &::before
      content: ''
      background-color: unset
      background-image: url('~icons/ic-checkmark-fill.svg')
      background-repeat: no-repeat
      background-size: 100% 100%
      border: 0
      position: absolute
      right: 0
      bottom: 0
      box-sizing: border-box
      display: flex
      align-items: center
      justify-content: center
      width: 17px
      height: 17px
      border-radius: 50%
      z-index: 2
      transform: scale(1.33)
      filter: invert(69%) sepia(8%) saturate(2630%) hue-rotate(77deg) brightness(85%) contrast(79%)


  &-img
    display: block
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    width: 100%
    height: 100%
    object-fit: contain
    border-radius: 50%

.onboarding-actions
  display: flex
  align-items: center
  margin-top: 10px

.onboarding-contact-ico
  margin-right: 14px
  width: 48px
  min-width: 48px
  height: 48px
  position: relative

  img
    width: 100%
  
  &::before
    content: ''
    background-color: unset
    background-image: url('~icons/ic-checkmark-fill.svg')
    background-repeat: no-repeat
    background-size: 100% 100%
    border: 0
    position: absolute
    right: 0
    bottom: 0
    box-sizing: border-box
    display: flex
    align-items: center
    justify-content: center
    width: 13px
    height: 13px
    border-radius: 50%
    transform: scale(1.33)
    filter: invert(69%) sepia(8%) saturate(2630%) hue-rotate(77deg) brightness(85%) contrast(79%)


.onboarding-progress-bar
  &.linear-progress
    &.root
      width: 100%
      background: rgba(135, 156, 165, 0.5)
      border-radius: 100px
      height: 12px
      margin: 0

    .bar
      background: $success-status-color
      border-radius: 100px

.widget-panel
  &.completed
    .onboarding-call
      flex-direction: column-reverse
      margin-top: 0
      margin-bottom: 6px
      text-align: center
    
    .onboarding-call-img-wrapper
      margin-right: 0
      margin-left: 0
      margin-bottom: 12px
      width: 54px
      min-width: 54px
      height: 54px

      &::before
        width: 14px
        height: 14px

    .onboarding-call-title
      margin-bottom: 4px
