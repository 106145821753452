.toolbar-stats
  width: 240px
  padding: 16px 22px
  background-color: #fff
  border-top: 1px solid #e7eBee

  &-plan
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 8px
    font-size: 16px
    font-weight: 500

  &-progress-label
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 5px
    font-size: 14px
    font-weight: 500

  &-progress-amount,
  &-reset
    font-size: 12px
    font-weight: 500
    color: rgba(10, 8, 54, 0.5)

  &-details
    display: flex
    justify-content: space-between

  &-reset
    margin-bottom: 19px

  &-plan-link
    @extend %hyperlink-small
    font-size: 11px
    line-height: 14px

  &-reset
    width: 100%
    max-width: 120px

  &-progress
    margin-bottom: 7px

  .button
    width: 100%

  +screen-max(767px)
    padding: 16px 15px

  +screen-max(475px)
    width: 100vw
    overflow: hidden
