.oauth-card
  @extend .card
  margin: 2rem auto
  max-width: 510px
  padding: $space-l

  pre
    white-space: pre-wrap

  .alert
    padding: $space-s $space-m

  .actions
    display: flex
    justify-content: right

    .action-button,
    .button
      cursor: pointer
      height: 40px
      min-width: 128px


