.data-join
  &__container
    width: 100%
    max-width: calc(var(--wizard-max-width) - 2*40px)
    display: flex
    flex-direction: column
    margin-inline: auto
    padding-inline: 40px

  &__title
    @extend %heading-xsmall-h5
    padding: 12px

  &__errors
    padding: 12px

  &__add-join
    margin-left: 22px

  &__actions
    display: flex
    padding: 16px
    gap: 8px
    width: 616px

.join-conditions
  gap: 32px
  display: flex
  flex-direction: column
  margin-bottom: 32px

.join-condition
  padding: 0 24px

  &__views
    position: relative
    display: grid
    grid-template-columns: 1fr 32px 1fr
    gap: 8px
    padding: 16px
    background-color: var(--body-bg-color)
    border: 1px solid $border-default-color
    border-radius: 8px
    box-shadow: 0 1px 8px 0 #033C521F
    align-items: center
    z-index: 2

  &__remove-button
    position: absolute
    right: -44px

  &__result-of-prev-joins
    display: flex
    align-items: center
    gap: 4px
    align-self: stretch
    border: 1px dashed $border-default-color
    border-radius: 5px

    > div
      padding-left: 8px

    > span
      @extend %paragraph-medium

  &__errors
    @extend %captions-medium
    padding: 8px 8px 0 8px

.join-predicates
  background-color: $bg-grey-color
  border: 1px solid $border-default-color
  border-top: 0
  border-radius: 0 0 6px 6px
  margin-inline: 16px
  padding: 12px 0

  &__add-button
    margin-left: 32px

.join-predicate
  display: grid
  grid-template-columns: 1fr 48px 1fr 32px
  margin-left: 32px
  margin-bottom: 8px
  align-items: center
