.dashboard-theme-default {
  .funnel-chart {
    --highcharts-color-0: #98d6f3;
    --highcharts-color-1: #5db8ea;
    --highcharts-color-2: #479ecd;
    --highcharts-color-3: #397ea9;
    --highcharts-color-4: #33739b;
    --highcharts-color-5: #255c82;
    --highcharts-color-6: #153a52;
    --highcharts-color-7: #061722;

    /* <z-index-hack>
    * Funnel charts use custom Data Labels which require CSS overrides in Highcharts nodes
    * in order to render Exporting options over these labels
    */
    .highcharts-container {
      position: static;
    }

    .highcharts-contextmenu {
      top: 26px !important;
      right: 6px !important;
    }
    /* </z-index-hack> */
  }
}
