.column-edit-dropdown
  &__title
    @extend %heading-xsmall-h5
    padding: 12px

  &__content-dropdown
    width: var(--row-popover-width)
    border: 1px solid $border-focused-color
    overflow: hidden

    &.type-default
      max-height: calc(100vh - 300px)

    @media screen and (max-width: 568px)
      width: auto

  &__fields
    padding: 12px

    .cm-editor
      min-height: 100px

      @media screen and (min-height: 950px)
        height: 280px

  &__action-buttons
    display: flex
    justify-content: flex-end
    gap: 8px
    padding: 12px
    height: 65px

  &__cancel-button
    margin-right: 12px
