.wizard-header-step
  $parent: &

  &__button
    @extend %subheading-medium
    cursor: pointer
    padding: 8px 12px
    display: flex
    flex-direction: row
    gap: 8px
    color: inherit

  &__number
    padding-bottom: 1px
    width: 24px
    height: 24px
    border: 1px var(--muted-color) solid
    border-radius: 50%
    display: flex
    justify-content: center
    align-items: center

  &__active-indicator
    position: absolute
    width: calc(8px + 100% + 8px)
    height: 8px
    top: 100%
    left: -8px
    background-color: var(--active-background-color)

    &::before,
    &::after
      content: ''
      position: absolute
      width: 8px
      height: 8px
      background-color: $bg-black-dark-color
      top: 0

    &::before
      left: 0
      border-bottom-right-radius: 100%

    &::after
      right: 0
      border-bottom-left-radius: 100%

  &--action
    +screen-max(480px)
      display: none

  &--active
    position: relative
    color: $text-primary-color
    --active-background-color: #{$bg-white-color}

    #{$parent}__button
      background-color: var(--active-background-color)
      border-radius: 8px 8px 0 0

    #{$parent}__number
      color: var(--primary-color)
      border-color: currentColor

  &--summary, &--unavailable
    --active-background-color: #{$bg-grey-color}

  &--done
    color: $text-invert-color

    #{$parent}__number
      border-color: $success-default-color

  &--disabled
    color: var(--muted-color)
    cursor: not-allowed

    #{$parent}__button
      pointer-events: none

  &:not(&--active)
    +screen-max(960px)
      #{$parent}__name
        display: none

  &--active
    +screen-max(540px)
      #{$parent}__name
        display: none
