.complex-transformation-chooser
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  gap: 4px
  margin-top: 16px

  &__title
    @extend %heading-medium-h3
    margin-top: 40px

  &__message
    @extend %paragraph-medium
    text-align: center
    margin-bottom: 20px

    a
      text-decoration: underline

.complex-transformations
  display: flex
  gap: 24px
  padding: 0 24px
  justify-content: center
  flex-wrap: wrap
  max-width: var(--wizard-max-width)

  &__item
    $parent: &
    display: flex
    width: 296px
    flex-direction: column
    gap: 8px
    border: 1px solid var(--border-default-color)
    border-radius: 12px
    background-color: var(--body-bg-color)

    &:hover
      cursor: pointer
      border-color: var(--border-brand-hover-color)
      box-shadow: 0px 4px 16px 0px #033C5229

      #{$parent}-read-more
        visibility: visible
        opacity: 1

      #{$parent}-cover-image
        padding: 0px

    &-wrapper
      overflow: hidden
      position: relative
      border-radius: 12px

      svg
        height: auto

    &-cover-image
      width: 100%
      padding: 12px 12px 0px 12px
      transition: padding 0.3s ease-in-out
      object-fit: cover
      height: 156px

    &-content
      background-color: var(--body-bg-color)
      padding: 0px 12px 12px 12px
      display: flex
      flex-direction: column
      gap: 8px

      .action-button
        border: 1px solid var(--border-brand-hover-color)

    &-title-wrapper
      width: 100%
      padding: 6px 0px 0px 0px
      display: flex
      justify-content: space-between
      align-items: center

    &-title
      @extend %heading-xsmall-h5
      height: 24px
      margin: 0

    &-read-more
      color: var(--link-default-color)
      visibility: hidden
      opacity: 0
      transition: opacity 0.3s ease-in-out
      @extend %subheading-small
