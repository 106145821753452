.importer-webhook
  position: relative

  &--inactive
    &.card
      margin-bottom: 0

      +screen-max(768px)
        padding-bottom: 47px

    .importer-webhook__title,
    .importer-webhook__text
      color: $theme-grey-color
