.wizard-sub-step-preview
  display: flex
  align-items: center
  width: 100%
  gap: 8px
  background-color: var(--body-bg-color)
  padding-right: 16px


  &-list
    border-radius: 8px
    overflow: hidden

    &--with-shadow
      box-shadow: 0px 1px 8px 0px #033C521F

    &:not(:empty)
      border: 1px solid $border-default-color

    &__item
      position: relative

      &:not(:last-child)::after
        content: ''
        position: absolute
        bottom: 0
        left: 0
        margin-left: 12px
        width: calc(100% - 12px)
        border-top: 1px solid $border-default-color

  &__content
    display: flex
    flex-grow: 1
    gap: 8px
    align-items: center
    padding: 16px 0 16px 12px
    overflow: hidden
    cursor: pointer

  &__icon
    width: 40px
    height: 40px
    flex-shrink: 0

  &__texts
    flex-grow: 1
    display: flex
    flex-direction: column
    text-align: left
    overflow: hidden

  &__name
    @extend %heading-xsmall-h5

    .editable-input
      @extend %heading-xsmall-h5
      border: 1px solid transparent
      background: inherit
      padding: 0
      caret-color: var(--primary-color)

  &__description
    @extend %paragraph-small

  &__edit-button
    flex-shrink: 0

  &__status
    flex-shrink: 0

  &__warning-status
    color: var(--icon-warning-color)

  &__error-status
    color: var(--icon-danger-color)

  &:hover,
  &:focus
    background-color: $bg-grey-color

  &__delimiter
    padding: 0 12px

  &__data-to-share
    display: flex
    gap: 8px
    align-items: center
    padding: 0 32px

    &-content
      display: flex
      gap: 4px
      align-items: center
      border-bottom-right-radius: 8px
      border-bottom-left-radius: 8px
      border: 1px solid var(--border-default-color)
      border-top: none
      padding: 6px 12px
      width: 100%

    &-name
      @extend %subheading-small
