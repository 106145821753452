.table-pagination
  display: flex
  gap: 16px
  align-items: center
  justify-content: end

  +screen-max(576px)
    flex-direction: column

  &__text
    @extend %paragraph-medium
    color: var(--text-primary-color)
    white-space: nowrap

    +screen-max(768px)
      white-space: normal
      word-break: break-word

  &__block
    display: flex
    gap: 8px
    align-items: center

  &__actions
    display: flex
    gap: 4px
    align-items: center

  &__rows-per-page
    width: 96px
