.column-management
  &__content-dropdown
    border: 1px solid $theme-primary-color
    width: 564px
    overflow: hidden

    &.type-default
      // #goal show below the button that opens the dropdown
      max-height: calc(100vh - 300px)

  &__title
    @extend %heading-xsmall-h5
    margin: 8px 12px 0

  &__counter
    @extend %paragraph-small
    color: $text-secondary-color
    margin: 2px 12px 8px
    display: block

    b
      font-weight: 600

  &__actions-bar
    padding: 4px 12px 12px
    display: flex
    align-items: center
    gap: 8px

    & > *
      flex-shrink: 0

  &__actions-bar-collapsible
    border-bottom: 1px solid $border-default-color
    flex-shrink: 0 // to fully expand

  &__action-divider
    margin: 0 1px
    flex-grow: 0
    height: 24px
    width: 0
    border: 0
    border-left: 1px solid $border-default-color

  &__action-search
    flex-grow: 1

  &__action-filter
    width: 144px

    .MuiAutocomplete-inputRoot[class*=MuiOutlinedInput-root]
      padding: 2px 7px
      font-size: 14px

  &__action-prefix
    @extend %subheading-small

  &__action-auto-update
    display: inherit
    align-items: inherit

  &__action-tooltip
    display: flex // to wrap around its content
    margin-left: 4px

    svg
      color: var(--primary-light-color)

  &__content
    overflow: auto

  &__column-groups
    padding: 8px 12px
    flex-grow: 1

  &__group
    margin-bottom: 4px

    &--header
      padding: 6px 4px
      background: white
      border-radius: 4px
      width: 100%
      display: flex
      align-items: center
      position: sticky
      top: -8px
      cursor: pointer

      &.checked
        background: $theme-light-color

    &--items
      margin-left: 24px
      overflow: hidden

      :not(&)
        & > .column-management__item
          border-radius: 4px

          &.checked
            background: $theme-light-color

    &--arrow
      transition: transform 0.1s ease-in-out

    &.collapsed
      .column-management__group--items
        height: 0

      .column-management__group--arrow
        transform: rotate(180deg)

  &__item
    padding: 6px 12px

    &__drag-indicator.sortable__item__action
      display: flex
      color: $theme-grey-color
      padding-top: 9px
      padding-bottom: 9px

    &__checkbox
      flex-grow: 1

  &__empty
    display: flex
    flex-direction: column
    align-items: center
    gap: 4px
    justify-content: center
    height: 300px

    svg
      color: var(--icon-info-color)
      transform: scale(1.33)

    &--description
      @extend %paragraph-small

  &__action-buttons
    border-top: 1px solid $body-divider-alternative-color
    border-bottom: 1px solid $body-divider-alternative-color
    display: flex

    > button
      flex-grow: 1
      flex-basis: 0%

  &__order-notice
    display: flex
    gap: 4px
    align-items: center
    padding: 8px 12px 8px 10px
    font-size: 14px
    font-weight: 400
    line-height: 20px
    white-space: pre-wrap
    border-top: 1px solid $body-divider-alternative-color

    svg
      color: var(--icon-info-color)
      transform: scale(1.33)

  &__view-header
    @extend %subheading-small

    padding: 16px 12px 0 12px
    text-transform: uppercase
    font-size: 14px
    line-height: 16px

  &__view-dot
    height: 8px
    width: 8px
    border-radius: 50%
    display: inline-block
    margin: 0 0 1px 5px

    &--eminence
      background-color: $eminence-color
    &--pale-red
      background-color: $pale-red-color
    &--algae
      background-color: $algae-color
    &--sunset
      background-color: $sunset-color
    &--cerulean
      background-color: $cerulean-color



