// UI Library color palette
// https://www.figma.com/file/9yKY3OAVO5E8BLOUGYBUCW/00-%5BGeneral%5D-Dashboard-Design-Tokens?node-id=496%3A2711&t=vHUiAYtpHiQJiSY5-1

@import './token-colors'

// Background & overlay color
$bg-white-color: #FFF // still needed for some formulas
$bg-grey-lightest-color: #FCFCFC
$bg-grey-color: #F6FAFB
$bg-grey-light-color: #F2FCFF
$bg-black-dark-color: #020C1B
$overlay-grey-dark-color: rgb(#030327, 0.32)
$overlay-grey-darkest-color: rgb(#030327, 0.64)
$bg-information-color: #CCF3FF
$bg-success-color: #E7F3E9
$bg-warning-color: #FFF4CE
$bg-critical-color: #F5CDC7
$bg-transparent-color: transparent

// Text colors
$text-primary-color: #030327
$text-secondary-color: #3F3F3F
$text-disabled-color: #879CA5
$text-link-color: #05759E
$text-on-primary-color: #FFF
$text-invert-color: #FFF

// Information colors
$info-pressed-color: #004D66
$info-hover-color: #004D66
$info-default-color: #009BCD
$info-text-color: #FFF
$info-text-on-bg-color: #030327

// Success colors
$success-pressed-color: #34653B
$success-hover-color: #45874F
$success-default-color: #58A964
$success-text-color: #FFF
$success-text-on-bg-color: #030327

// Warning colors
$warning-pressed-color: #9D5E00
$warning-hover-color: #CC8316
$warning-default-color: #E49C30
$warning-text-color: #FFF
$warning-text-on-bg-color: #030327

// Critical colors
$critical-pressed-color: #752115
$critical-hover-color: #CB3924
$critical-default-color: #DE5946
$critical-text-color: #FFF
$critical-text-on-bg-color: #030327

// Border colors
$border-default-color: #C3CDD2
$border-hover-color: #879CA5
$border-focused-color: #05759E
$border-blue-default-color: #05759E
$border-blue-hover-color: #006385
$border-info-color: #009BCD
$border-success-color: #45874F
$border-warning-color: #605E5D
$border-critical-color: #CB3924
$border-white-color: #FFF

// Decorative colors
$eminence-color: #69318B
$cerulean-color: #17B3E5
$pale-red-color: #DE5946
$algae-color: #58A964
$dodger-blue-color: #4285F4
$white-color: #FFF
$brand-color: #05759E
$sunset-color: #E6900F

// DEPRECATED
$deprecated-text-primary-color: #030327
$deprecated-text-secondary-color: #3F3F3F
$deprecated-text-disabled-color: #879CA5
$text-theme-color: #05759E

$theme-primary-color: #05759E
$theme-primary-darker-color: #006385
$theme-primary-dark-color: #04526F
$theme-primary-vivid-color: #879CA5
$theme-primary-muted-color: rgba(5, 117, 158, .6)
$theme-grey-color: #879CA5
$theme-dark-grey: #030327
$theme-lighter-color: #F2FCFF
$theme-light-color: #F6FAFB
$theme-white: #FFF

$success-status-color: #58A964
$success-background-color: #E7F3E9
$error-status-color: #cc6152
$error-background-color: #FBCFBD
$error-background-color-light: #FAE9E6
$warning-status-color: #797775
$warning-background-color: #FFF4CE
$info-status-color: #009bcd
$info-background-color: #dcf4fb

$critical-default-color: #DE5946

$body-divider-color: rgba(135, 156, 165, 0.12)
$body-divider-alternative-color: rgba(5, 117, 158, 0.2)
$input-border-color: rgba(135, 156, 165, 0.5)
$input-border-hover-color: #879CA5
$input-border-hover-blue: #006385
$input-border-active-color: #05759E

$overlay-dark-color: rgba(3, 3, 39, .2)
