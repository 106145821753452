.integrations
  display: flex
  gap: 6px
  align-items: center

  &__wrapper
    display: flex
    flex-wrap: wrap
    gap: 4px

  &__group
    display: flex

  &__arrow
    width: 4px
    height: 4px
    color: var(--muted-color)
    transform: scale(4.5)

  .integration
    position: relative
    display: inline-flex
    align-items: center
    justify-content: center
    aspect-ratio: 1
    background: var(--body-bg-color)
    border: 1px solid var(--border-default-color)
    border-radius: 3px

    &--disabled
      background: $bg-grey-color
      filter: grayscale(100%)

    &__from
      &:not(:first-child)
        margin-left: -32px

    img, svg
      display: block
      width: 32px
      aspect-ratio: 1

  &-small
    .integration
      border-radius: 5px

      img, svg
        width: 24px

      &__from
        &:nth-child(2)
          margin-left: -21px
          transform: scale(0.9)

        &:nth-child(3)
          margin-left: -22px
          transform: scale(0.8)

  &-large
    .integration
      width: 90px
      border-radius: 5px

      img, svg
        width: 84px

      &__from
        &:not(:first-child)
          margin-left: -84px

  +screen-max(768px)
    &-large
      .integration
        width: 66px

        img, svg
          width: 60px

        &__from
          &:not(:first-child)
            margin-left: -60px
