.cookie-consent
  position: fixed
  left: 0
  bottom: 0
  display: flex
  align-items: center
  justify-content: center
  padding: 15px
  z-index: 99
  width: 100%
  transform: translateY(100%)
  transition: transform 500ms ease-in-out
  background-color: #fff
  box-shadow: 0 0 20px rgba(0, 101, 150, 0.19)

  .button
    flex-shrink: 0
    margin-left: $spaceSmall

  &.entering
    transform: translateY(100%)

  &.entered, &.visible
    transform: translateY(0%)

  &.exited
    transform: translateY(100%)

  &.exiting
    transform: translateY(100%)

  +screen-max(767px)
    flex-direction: column

    .button
      margin-left: 0
      margin-top: $spaceSmall

// Disable cookie consent for views opened from add-on
.gsheet-addon-embedded
  .cookie-consent
    display: none
