$spaceSmall: 15px!default
$spaceMedium: 25px!default
$spaceLarge: 50px!default

.pts, .pvs, .pas
  padding-top: $spaceSmall
.ptm, .pvm, .pam
  padding-top: $spaceMedium
.ptl, .pvl, .pal
  padding-top: $spaceLarge

.prs, .phs, .pas
  padding-right: $spaceSmall
.prm, .phm, .pam
  padding-right: $spaceMedium
.prl, .phl, .pal
  padding-right: $spaceLarge

.pbs, .pvs, .pas
  padding-bottom: $spaceSmall
.pbm, .pvm, .pam
  padding-bottom: $spaceMedium
.pbl, .pvl, .pal
  padding-bottom: $spaceLarge

.pls, .phs, .pas
  padding-left: $spaceSmall
.plm, .phm, .pam
  padding-left: $spaceMedium
.pll, .phl, .pal
  padding-left: $spaceLarge

.mts, .mvs, .mas
  margin-top: $spaceSmall
.mtm, .mvm, .mam
  margin-top: $spaceMedium
.mtl, .mvl, .mal
  margin-top: $spaceLarge

.mrs, .mhs, .mas
  margin-right: $spaceSmall
.mrm, .mhm, .mam
  margin-right: $spaceMedium
.mrl, .mhl, .mal
  margin-right: $spaceLarge

.mbs, .mvs, .mas
  margin-bottom: $spaceSmall
.mbm, .mvm, .mam
  margin-bottom: $spaceMedium
.mbl, .mvl, .mal
  margin-bottom: $spaceLarge

.mls, .mhs, .mas
  margin-left: $spaceSmall
.mlm, .mhm, .mam
  margin-left: $spaceMedium
.mll, .mhl, .mal
  margin-left: $spaceLarge

$spaces: 4, 6, 8, 12, 14, 16, 20, 24, 32, 40, 48, 64

@each $space in $spaces
  .ma#{$space}, .mh#{$space}, .ml#{$space}
    margin-left: #{$space}px

  .ma#{$space}, .mh#{$space}, .mr#{$space}
    margin-right: #{$space}px

  .ma#{$space}, .mv#{$space}, .mt#{$space}
    margin-top: #{$space}px

  .ma#{$space}, .mv#{$space}, .mb#{$space}
    margin-bottom: #{$space}px
