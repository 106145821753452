[data-reach-dialog-overlay]
  display: flex
  justify-content: center
  align-items: center

.gsheet-addon-embedded
  .modal-window
    &[data-reach-dialog-content]
      &.modal-window-extra-large
        height: 100%
        width: 100%

.modal-window
  &[data-reach-dialog-content]
    position: relative
    width: calc(100% - 32px)
    max-width: 510px
    padding: 24px
    margin: 0 auto
    border-radius: 5px

    &.modal-window-large
      max-width: 1024px
      height: 80%

    &.modal-window-extra-large
      height: calc(100% - 150px)
      width: calc(100% - 100px)
      max-width: initial

    &.modal-window-borderless
      padding: 0

  &-close-icon
    position: absolute
    top: 10px
    right: 14px
    width: 14px
    height: 14px
    color: $theme-primary-color
    cursor: pointer
    transform: scale(1.4)
    z-index: 1

    &:hover
      color: $theme-primary-darker-color

  &-description
    word-break: break-word

  &-buttons
    display: flex
    align-items: center
    justify-content: flex-end
    margin-top: 32px

    button
      min-width: 128px

  &-overlay
    background: rgba(3, 3, 39, 0.2)
    z-index: 10

  &-borderless &-description
    height: 100%
    border-radius: inherit

  +screen-max(768px)
    &[data-reach-dialog-content]
      &.modal-window-extra-large
        height: 100%
        width: 100%

    h4
      text-align: center

    &-buttons
      margin-top: 24px
      flex-direction: column

      button
        width: 100%
        margin: 0 auto 12px

        &:last-child
          margin: 0

  +screen-max(480px)
    &[data-reach-dialog-content]
      padding: 40px 8px

      &.modal-window-borderless
        padding: 0

    &-close-icon
      top: 14px

    &-description
      text-align: center

    &-buttons
      margin-top: 40px

.grant-permissions-container
  display: flex
  justify-content: center

  img.grant-permissions
    width: 75%
    border: 1px solid rgba(5, 117, 158, 0.15)
