.credentials-table
  thead
    th:first-child
      padding: 0 16px 0 22px
      text-align: left

  .credential-provider-column
    width: 160px

  .credential-provider
    &__content
      display: flex
      align-items: center
      gap: 4px

    &__icon
      flex-shrink: 0

    &__title
      @extend %subheading-small

  .credential-login-column
    max-width: 400px

    @media screen and (max-width: 992px)
      max-width: 200px

    .credential-login
      width: 100%

  .share-column
    min-width: 150px

  .reconnect-button
    color: var(--link-default-color)

  @media screen and (max-width: 992px)
    .credential-provider
      &-column
        width: auto

      &__title
        display: none

    .created-at-column, .used-in-column
      display: none
