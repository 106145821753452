.billing
  display: flex
  flex-direction: column
  font-size: 16px

  .alert
    margin-bottom: 40px

  .tab-list
    .tab
      &::before
        display: none

  .current-plan
    @extend .card
    width: 100%
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    padding: 20px 40px
    margin-bottom: 16px
    font-size: 14px
    border-left: 6px solid #000
    gap: 20px

    .current-usage
      margin-top: 4px
      font-size: 16px
      font-weight: 400
      text-wrap: nowrap
      color: $deprecated-text-disabled-color

      strong
        font-size: 20px
        font-weight: 600
        color: $deprecated-text-primary-color

      .data-integrations
        margin: 0
        display: flex
        align-items: center
        flex-wrap: wrap
        row-gap: 2px

      .tooltip-children
        margin: 0

        &:last-of-type
          margin-right: 5px

      &-button
        font-size: 14px
        line-height: 30px // to match the height of bold text
        color: $text-theme-color 
        text-decoration: underline
        text-align: left
        text-wrap: nowrap

    &-item
      &__title
        text-wrap: nowrap

      &--sources
        min-width: 190px

    .plan-name
      display: block
      font-size: 24px
      line-height: 36px
      font-weight: 700

    @each $plan-id, $plan-color in $billing-plan-colors
      &.#{$plan-id}
        border-left-color: $plan-color
        @if $plan-id != 'free_2024' and $plan-id != 'free_2024_08' and $plan-id != 'free_trial_2024_08'
          .plan-name
            color: $plan-color

    +screen-max(1180px)
      display: grid
      grid-template-rows: 1fr
      grid-template-columns: repeat(2, 1fr)
      padding: 16px
      gap: 12px

      &__plan
        grid-column-start: 1
        grid-column-end: 3

      &-item
        &--price
          display: block

        &--sources
          min-width: auto

  .interval-toggle-switch
    margin-top: 24px
    margin-bottom: 40px

  .available-plans
    flex: 1
    font-size: 14px
    margin-bottom: 40px
    display: grid
    grid-template-rows: 1fr
    grid-template-columns: repeat(1, 1fr)
    column-gap: 12px
    row-gap: 16px

    +screen-min(640px)
      column-gap: 12px
      row-gap: 24px
      grid-template-columns: repeat(2, 1fr)

    +screen-min(960px)
      grid-template-columns: repeat(3, 1fr)
      column-gap: 24px
      row-gap: 24px

    .plan
      padding: 0
      border-radius: 8px
      height: fit-content

      &.most-popular
        background: #ffffff

        &::before
          padding: 4px 16px
          border-radius: 16px 16px 0px 0px
          font-size: 16px
          display: flex
          align-items: center
          font-weight: 400
          height: 32px
          z-index: 2
          box-sizing: border-box

      .plan-action
        margin-top: 24px

      .plan-content
        padding: 32px 16px 24px
        position: relative
        overflow: hidden
        z-index: 0

        &::before
          content: ""
          z-index: -1
          position: absolute
          top: 0
          right: 0
          width: 166px
          height: 144px
          border-radius: 166px
          background: linear-gradient(315deg, #DFC7EE 0%, #FBF5FF 100%)
          filter: blur(75px)
          transform: translate3d(0, 0, 0)

      .plan-expand
        margin-top: 16px
        padding-top: 16px
        padding-bottom: 4px
        width: 100%
        display: flex
        align-items: center
        justify-content: space-between
        font-size: 14px
        line-height: 1.7
        font-weight: 600
        text-transform: uppercase
        border-top: 1px solid $text-primary-color

        svg
          color: $info-default-color
          transition: transform 0.3s ease-in

        &--active
          svg
            transform: rotate(180deg)

      .plan-expand-content
        transition: max-height 0.3s ease-in
        overflow: hidden

      @each $plan-id, $plan-color in $billing-plan-colors
        &.#{$plan-id}
          .plan-content
            &::before
              @if $plan-color == $grey-plan-color
                background: linear-gradient(303deg, #BCCBD1 0%, #FAFDFF 100%)

              @if $plan-color == $orange-plan-color
                background: linear-gradient(315deg, #EADAB2 0%, #FFF9EA 100%)

              @if $plan-color == $red-plan-color
                background: linear-gradient(315deg, #FBCACA 0%, #FFECEC 100%)

              @if $plan-color == $green-plan-color
                background: linear-gradient(315deg, #BEEFC5 0%, #F8FFF9 100%)

              @if $plan-color == $blue-vivid-plan-color
                background: linear-gradient(315deg, #B2DEEA 0%, #F5FDFF 100%)

      &.custom
        height: fit-content
        margin-bottom: 40px
        padding: 0

        .plan-content
          padding: 30px 24px 24px
          display: flex
          flex-direction: column
          gap: 24px

        p
          @extend %paragraph-medium
          margin: 0

        .plan-action
          margin-top: 0

          button
            margin-top: unset

        +screen-max(640px)
          .plan-content
            flex-direction: column

          .pricing
            margin-bottom: 12px

          .plan-action
            width: 100%

  .data-integrations
    display: grid
    grid-template-columns: repeat(4, 1fr)
    margin: 0 0 0 auto
    max-width: 180px

    li
      display: flex
      align-items: center
      justify-content: center
      width: 32px
      height: 32px
      border-radius: 4px
      margin: 0 0 6px auto

      &:hover
        background-color: $theme-lighter-color

    @for $i from 1 through 3
      &.count-#{$i}
        & > :first-child
          grid-column-start: #{4 - $i + 1}

  .card
    .loader
      margin: $spaceLarge 0

  .request-sources
    padding: 24px
    gap: 24px
    margin-bottom: 40px

    +screen-max(640px)
      flex-direction: column

    .button
      width: 100%

      +screen-min(641px)
        max-width: 276px

    &-text
      margin-bottom: 0

      strong
        font-weight: 600

  .addon-cards
    margin-top: 40px
    display: grid
    grid-template-columns: repeat(1, 1fr)
    align-items: flex-start
    gap: 16px

    +screen-min(640px)
      grid-template-columns: repeat(2, 1fr)

    +screen-min(960px)
      grid-template-columns: repeat(3, 1fr)

    .addon-card
      position: relative
      flex: 1
      background: var(--body-bg-color)
      border-radius: 8px      
      border-top: 6px solid var(--body-bg-color)
      overflow: hidden

      &::before
        content: ""
        z-index: 0
        position: absolute
        bottom: 15px
        right: 15px
        width: 220px
        height: 140px
        border-radius: 166px
        background: linear-gradient(315deg, #DFC7EE 0%, #FBF5FF 100%)
        filter: blur(60px)
        transform: translate3d(0, 0, 0)

      &--cyan
        border-color: $cerulean-color

        &::before
          background: linear-gradient(315deg, #B2DEEA 0%, #F5FDFF 100%)

        .addon-card__name
          color: $cerulean-color

        .addon-card-list__item
          &::before
            background: $cerulean-color

      &--yellow
        border-color: $sunset-color

        &::before
          background: linear-gradient(315deg, #EADAB2 0%, #FFF9EA 100%)

        .addon-card__name
          color: $sunset-color

        .addon-card-list__item
          &::before
            background: $sunset-color

      &--green
        border-color: $algae-color

        &::before
          background: linear-gradient(315deg, #BEEFC5 0%, #F8FFF9 100%)

        .addon-card__name
          color: $algae-color

        .addon-card-list__item
          &::before
            background: $algae-color

      &__box
        position: relative
        padding: 30px 20px 24px
        z-index: 0

      &__name
        margin-bottom: 8px
        font-size: 18px
        font-weight: 600

      &-price
        margin-bottom: 16px
        &__value
          margin-bottom: 8px
          display: block
          @extend %decorative-prices

        span
          font-size: 16px
          color: $text-secondary-color

      &__desc
        margin-bottom: 24px
        font-size: 16px
        color: $text-secondary-color

        +screen-min(640px)
          min-height: 216px

        +screen-min(800px)
          min-height: 168px

        +screen-min(960px)
          min-height: 225px

        +screen-min(1080px)
          min-height: 165px

        +screen-min(1225px)
          min-height: 150px

      &__action
        .button
          width: 100%

      &-list
        position: relative
        padding: 24px 20px
        border-top: 1px solid var(--border-default-color)
        z-index: 0

        &__title
          margin-bottom: 12px
          color: var(--text-primary-color)
          font-weight: 600

        &__list
          display: flex
          flex-direction: column
          gap: 12px

        &__item
          color: var(--text-primary-color)
          display: flex
          align-items: flex-start
          gap: 4px

          &::before
            content: ""
            display: block
            width: 24px
            min-width: 24px
            height: 24px
            mask: url('~icons/ic-checkmark.svg') no-repeat 50% 50%
            mask-size: contain
            transition: all .2s ease-in
