.table-legacy
  width: 100%
  border-collapse: collapse

  th
    text-align: left
    font-weight: 500
    font-size: 16px

    &.actionable
      position: relative
      cursor: pointer

      &::after
        content: ' '
        padding: 0 4px
        font-size: 12px
        color: rgba(0, 0, 0, 0.54)

      &.asc::after
        content: '▲'

      &.desc::after
        content: '▼'

  th
    padding: 0 10px 10px

    &:first-child
      padding-left: 0

  td
    padding: 12px 10px

  th, td
    &.min
      width: 1%
      white-space: nowrap

  tr
    border-bottom: 1px solid $body-divider-color

    svg
      width: 20px
      height: 20px

  tbody tr
    background: transparent
    transition: background .3s ease

  &.open-ended
    tbody tr:last-child
      border-bottom: none

.table-legacy-wrapper
  @media screen and (max-width: 560px)
    overflow: auto

    &::-webkit-scrollbar
      -webkit-appearance: none

      &:vertical
        width: 8px

      &:horizontal
        height: 8px

      &-thumb
        border-radius: 6px
        border: 2px solid white
        background-color: rgba(0, 0, 0, .5)
