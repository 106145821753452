.field
  margin-bottom: 16px

  &--no-margin
    margin-bottom: 0

  &--large
    margin-bottom: 24px

  label
    @extend %subheading-medium
    display: block
    margin-bottom: 10px

  .field-necessity
    font-size: 13px
    font-weight: 400
    text-transform: lowercase

  &.spread
    label,
    p
      margin-bottom: 0

  &.has-field-helper
    .field-input,
    .field-textarea,
    .field-code
      border-bottom-right-radius: 0
      border-bottom-left-radius: 0

  &.has-error
    .field-input,
    .field-textarea,
    .field-code
      background-color: var(--bg-input-error-color)
      border-color: var(--border-error-color)

    .field-code
      border-style: solid
      border-width: 1px

.field-with-actions
  display: flex
