=ripple-effect
  &::before, &::after
    content: ''
    position: absolute
  &::before
    top: 0
    bottom: 0
    left: 0
    right: 0
    background-color: rgb(255, 255, 255)
    opacity: 0
    transition: opacity 0.2s
  &::after
    left: 50%
    top: 50%
    border-radius: 50%
    padding: 50%
    width: 32px /* Safari */
    height: 32px /* Safari */
    background-color: rgb(255, 255, 255)
    opacity: 0
    transform: translate(-50%, -50%) scale(1)
    transition: opacity 1s, transform 0.5s
  &:hover::before
    opacity: 0.08
  &:focus::before
    opacity: 0.24
  &:hover:focus::before
    opacity: 0.3
  &:active::after
    opacity: 0.32
    transform: translate(-50%, -50%) scale(0)
    transition: transform 0s

  &.disabled
    &::before, &::after
      content: none
