.wizard-basic-summary
  width: 100%
  max-width: 776px
  margin-inline: auto
  padding: 0
  display: flex
  flex-direction: column

  &__wrapper
    @extend .wizard-content__spacing
    background-color: var(--bg-lower-color)

  &__title
    @extend %heading-xlarge-h1
    margin-bottom: 8px

  &__description
    @extend %paragraph-medium
    margin-bottom: 32px

  &__sources
    flex-grow: 1
    display: flex
    flex-direction: column
    gap: 8px

  &__connect
    display: flex
    flex-direction: column
    gap: 8px
    margin-bottom: 32px

    &-list
      display: flex
      flex-direction: column
      gap: 8px

    &-item
      display: flex
      align-items: center
      gap: 8px
      border: 1px solid $border-default-color
      border-radius: 8px
      background: $bg-grey-color
      padding: 7px 12px

      svg
        flex-shrink: 0

      &-destination
        padding: 19px 12px

    &-name
      @extend %heading-xsmall-h5

    &-description
      @extend %paragraph-small
      margin-bottom: 0
      color: var(--text-secondary-color)

    &-button
      background-color: var(--bg-lower-color)

  &__connect-button,
  &__edit-button
    margin-left: auto

  &__content
    display: flex
    align-items: center
    gap: 16px
    margin-bottom: 32px

  &__sources
    display: flex
    flex-direction: column
    gap: 8px
    width: 60%

  &__destination
    width: 35%

    &--configured
      .wizard-summary__connect-item-destination
        background-color: var(--body-bg-color)

    &--disabled
      .wizard-summary__connect-item-destination
        border-color: var(--border-default-color)

      .wizard-summary__connect-name
        color: var(--text-disabled-color)

  &__separator
    position: relative
    display: flex
    align-items: center
    align-self: stretch
    color: var(--primary-color)

    &::before
      position: absolute
      content: ''
      display: block
      width: 1px
      height: 100%
      background-color: var(--primary-color)
      margin: 0 18px

    &-icon
      display: flex
      align-items: center
      justify-content: center
      border-radius: 24px
      width: 36px
      aspect-ratio: 1
      background-color: var(--bg-lower-color)
      border: 1px solid var(--primary-color)
      z-index: 2
