.toolbar-menu-button
  @extend %hyperlink-medium
  display: flex
  align-items: center
  justify-content: center
  border: 1px solid transparent
  border-radius: 5px
  background-color: transparent
  color: $text-secondary-color
  padding: 3px 7px
  text-transform: none

  &:hover:not(:disabled):not(.disabled),
  &.completed:not(:disabled):not(.disabled)
    background-color: $theme-lighter-color

  &.completed:not(:disabled):not(.disabled):not(:hover)
    border-color: $border-default-color

  &.completed:active:not(:disabled):not(.disabled):not(:hover),
  &.completed.active:not(:disabled):not(.disabled):not(:hover)
    border-color: $theme-primary-color

  &.completed:hover:not(:disabled):not(.disabled)
    border-color: $theme-primary-color

  &:active:not(:disabled):not(.disabled),
  &.active:not(:disabled):not(.disabled)
    color: $text-secondary-color
    background-color: $theme-lighter-color
    border-color: $theme-primary-color
    outline: none

  &:focus-visible:not(:active):not(:disabled):not(.disabled)
    border-color: $theme-dark-grey
    outline: none

  &.disabled,
  &:disabled
    color: $text-disabled-color
    pointer-events: auto
    cursor: not-allowed

    path
      fill: $text-disabled-color

  &.error
    border-color: $border-critical-color
    background: $error-background-color-light

  &:hover:not(:disabled):not(.disabled),
  &.completed:not(:disabled):not(.disabled),
  &:active:not(:disabled):not(.disabled),
  &.active:not(:disabled):not(.disabled)
    > svg
      path
        fill: $theme-primary-color

  > svg
    path
      fill: $text-primary-color

  &__label
    padding-left: 4px
