$data-union-width: 616px

.data-union
  // TODO: remove together with ImplicitDataUnion
  &__content-dropdown
    min-width: 485px
    border: 1px solid $border-focused-color
    display: flex
    flex-direction: column
    overflow: hidden

    +screen-max(405px)
      min-width: auto

  &__content-dropdown &__actions
    width: 100%
    align-self: unset
    justify-content: flex-end
    padding: 16px

  &__header
    display: flex
    padding: 12px
    flex-direction: column

  &__title
    @extend %heading-xsmall-h5

  &__subtitle
    @extend %paragraph-small
    height: 24px

  &__list
    display: flex
    flex-direction: column
    overflow: hidden
    gap: 8px
    margin-inline: 12px
    width: $data-union-width

  .scroll-box--overflown &__list
    margin-bottom: 8px

  &__list-item
    $parent: &
    display: flex
    align-items: center
    align-self: stretch
    border-radius: 8px
    border: 1px solid $border-default-color

    .checkbox-label.disabled
      #{$parent}-label
        color: $text-disabled-color

    .checkbox-label
      .row
        align-items: center

    &-label
      @extend %subheading-medium
      align-self: center

    &-icon
      display: flex
      width: 32px
      height: 32px
      justify-content: center
      align-items: center
      margin-right: 4px

    &-warning
      margin-left: auto
      color: $warning-default-color

    .checkbox-root
      flex-grow: 1
      padding: 8px 12px

    .checkbox-input
      margin-right: 4px

    &--implicit
      border: 0

      .checkbox-root
        padding: 0

    &--selected
      border-color: $border-blue-default-color
      background-color: $bg-grey-color

  &__container
    display: flex
    flex-direction: column
    align-items: center

  &__actions
    display: flex
    padding: 16px 0
    gap: 8px
    width: $data-union-width
