.users
  h3
    span
      font-size: 24px
      font-weight: normal

  .account-header-info
    font-size: 1rem
    color: #879CA5
    font-weight: normal

  .account-name
    font-family: 'DM Sans', 'Helvetica', 'Arial', sans-serif
    text-transform: none
    letter-spacing: -0.02em

.users-wrapper
  display: flex
  justify-content: space-between

  .card
    flex-grow: 1
    margin-bottom: 0

    &.table-legacy-wrapper
      overflow: hidden

      +screen-max(475px)
        padding: 0

  +screen-max(980px)
    flex-direction: column

  +screen-max(475px)
    margin: 0 -16px
