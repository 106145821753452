.table-control-panel
  display: flex
  align-items: center
  justify-content: space-between
  gap: 8px
  margin-bottom: 24px

  @media screen and (max-width: 768px)
    flex-direction: column
    align-items: initial

  &__search
    background: var(--body-bg-color)
    width: 280px

    .search-box__input
      padding: 9px 8px

  &__filters
    display: flex
    gap: 8px

  &__sort-dropdown
    width: 156px
    border: 1px solid var(--border-primary-color)
    box-shadow: 0px 8px 24px 0px #03516D1F

