.content-dropdown
  display: flex
  flex-direction: column
  margin-top: 4px
  background-color: #fff
  border-radius: 5px
  filter: drop-shadow(0px 4px 12px rgba(5, 117, 158, 0.12))

  &.type-default
    max-height: 50vh
    overflow: auto

  &.type-buttons
    .outlined-button
      border: 0

      &:not(:first-child)
        border-top-left-radius: 0
        border-top-right-radius: 0

      &:not(:last-child)
        border-bottom-left-radius: 0
        border-bottom-right-radius: 0
        border-bottom: 1px solid rgba(5, 117, 158, 0.2)
