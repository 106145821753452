.card
  position: relative
  padding: 15px
  background: #fff
  border-radius: 5px
  box-shadow: 0 2px 8px #e1ebf1

  h3
    word-break: break-word
    text-align: left

  +screen-min(375px)
    padding: 20px

  +screen-min(680px)
    padding: 40px

  &.table-legacy-wrapper
    padding: 28px 24px 12px 24px

    +screen-min(1024px)
      padding: 30px 40px 12px 40px

  .column &
    height: 100%

.card-border
  border: 1px solid rgba(5, 117, 158, 0.2)

.card-blue
  background-color: #f6fafb
  border: 1px solid #e7ebee
  box-shadow: none

.card-header
  display: flex
  align-items: center
  justify-content: space-between
  gap: 10px

  svg
    flex: 0 0 auto

