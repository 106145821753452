:root
  --text-size-xs: 0.875rem
  --text-size-s: 1.125rem
  --text-size-m: 1.25rem
  --text-size-l: 1.5rem
  --text-size-xl: 2.25rem
  --row-popover-width: clamp(400px, 75vw, 800px)

  --app-header-height: 56px
  --wizard-header-height: 56px
  --wizard-max-width: 984px

  +screen-min(476px)
    --app-header-height: 0px

$spaceSmall: 8px
$spaceMedium: 16px
$spaceLarge: 24px

$text-size-base: 16px
$text-size-xxs: 0.75rem
$text-size-xs: var(--text-size-xs)
$text-size-s: var(--text-size-s)
$text-size-m: var(--text-size-m)
$text-size-l: var(--text-size-l)
$text-size-xl: var(--text-size-xl)

$app-header-height: var(--app-header-height)
$dropdown-max-height: 256px

$z-index-header: 10
// header casts shadow on top of the left menu
$z-index-left-menu: $z-index-header - 1
$z-index-dropdown-options: 20
$z-index-wizard-sidebar: $z-index-left-menu
$z-index-wizard-sidebar-hover: $z-index-dropdown-options + 1
$z-index-transformation-modal: $z-index-dropdown-options - 1
$z-index-flash-message: $z-index-header + 1
$z-index-disclosure-panel: $z-index-left-menu + 1
$z-index-navigation-submenu: $z-index-header
$z-index-flash-message-bottom: $z-index-header + 1
$z-index-date-picker: $z-index-header + 1
$z-index-template-filters: $z-index-header - 2
$z-index-dashboard-header: $z-index-header - 2

// UI Library spacings

$space-xs: 4px
$space-s: 8px
$space-m: 16px
$space-l: 24px
$space-xl: 32px
$space-xxl: 48px
$space-xxxl: 80px
