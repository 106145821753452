.row
  display: flex
  flex-direction: row
  flex-wrap: wrap
  flex: 1

  &.odd-columns
    justify-content: center

.row-reverse
  display: flex
  flex-direction: row-reverse
  flex-wrap: wrap
  flex: 1

  &.odd-columns
    justify-content: center


.column
  display: flex
  flex: 1
  flex-direction: column

.flex-grow
  flex-grow: 1

.flex-bottom
  display: flex
  align-items: flex-end

+screen-max(1024px)
  .column
    flex: 0 50%
    margin-bottom: 30px

+screen-max(700px)
  .column
    flex: 0 100%
    padding-left: 0
    padding-right: 0
