.hamburger
  position: relative
  width: 22px
  height: 18px
  padding: 0
  margin: 0 8px
  cursor: pointer
  background-color: var(--bg-default-color)

  &-wrapper
    display: flex
    align-items: center

  .line
    position: absolute
    left: 0
    display: block
    width: 100%
    height: 2px
    background-color: #0a0836
    border-radius: 2px
    opacity: 1
    transition: 0.25s ease-in-out

    &:first-child
      top: 0

    &:nth-child(2)
      top: 50%
      transform: translateY(-50%)

    &:nth-child(3)
      bottom: 0

  &.open .line
    background-color: #84829a

    &:first-child
      transform: translateY(8px) translateX(0) rotate(45deg)

    &:nth-child(2)
      opacity: 0

    &:nth-child(3)
      transform: translateY(-8px) translateX(0) rotate(-45deg)
