.control-panel
  display: flex
  flex-direction: row
  align-items: flex-end
  justify-content: flex-end
  gap: 8px

  @media screen and (max-width: 768px)
    flex-direction: column
    align-items: initial

    .text-field-importers-search
      max-width: initial

  .menu-button-label
    padding: 0 0 0 8px
    border: 1px solid $body-divider-color

  .selection-button-wrapper
    min-width: 50px
    display: flex
    align-items: center
    gap: 8px

    .icon-arrow
      color: $text-primary-color
      height: 10px
      width: 10px
      transform: scale(2.4)

  &-filters
    display: flex
    flex-grow: 1
    flex-wrap: nowrap

  &-buttons
    display: flex
    flex-wrap: nowrap

    @media screen and (max-width: 768px)
      .action-button,
      .tooltip-children
        width: 100%
        max-width: 168px


  &-buttons-selection
    display: flex
    flex-wrap: nowrap

    @media screen and (max-width: 768px)
      display: none

  &__dropdown-actions
    display: flex
    flex-direction: column
    border: 1px solid $border-default-color
    overflow: hidden

    &--link
      text-align: left
      padding: 12px 16px

      &:hover
        background: $bg-grey-light-color

      &:not(:last-child)
        border-bottom: 1px solid $border-default-color

    &--header
      @extend %heading-xsmall-h5

    &--description
      @extend %captions-small
      margin-bottom: 0

  &__add-new-btn.action-button
    padding-left: 24px

    svg
      transform: rotate(180deg)
      transition: transform 0.1s ease-in-out

    &.opened
      svg
        transform: rotate(0deg)
