:root
  --settings-width: 1000px

.wizard-settings
  height: 100%
  overflow: auto
  background-color: var(--bg-lower-color)

  &__title
    @extend %heading-large-h2

  &__header
    width: var(--settings-width)
    margin: 0 auto
    padding: 0 32px

    +screen-max(960px)
      width: initial

    &-container
      background-color: var(--body-bg-color)
      border-bottom: 1px solid var(--border-default-color)
      margin-bottom: 24px
      padding-top: 40px

  &__tab-list
    border-bottom: none
    width: var(--settings-width)
    margin: 0

    +screen-max(960px)
      width: initial

    +screen-max(360px)
      gap: 4px

  &__tab-panels
    max-width: var(--settings-width)
    margin: 0 auto

  &__tab-panel
    padding: 0 32px 64px

  &__overview
    &-title
      .editable-content,
      .editable-placeholder,
      .editable-input
        @extend %heading-medium-h3
        line-height: 1

      .editable-input
        line-height: 1.2

      .editable-input
        border: 1px solid transparent
        background: inherit
        padding: 0
        caret-color: var(--primary-color)

    &-content
      display: flex
      gap: 24px

      +screen-max(960px)
        flex-direction: column

    &-sub-title
      @extend %paragraph-small
      color: var(--text-secondary-color)
      margin: 14px 0 30px

  &__schedule
    background: var(--bg-low-color)
    flex: 1

  &__webhooks
    display: flex
    flex-direction: column
    gap: 4px

    &-title
      @extend %heading-small-h4
      margin-bottom: 8px

    &-text
      @extend %paragraph-medium
      color: var(--text-secondary-color)
      margin-bottom: 8px

    &-divider
      margin: 16px 0
      border-color: var(--border-default-color)

  &__history
    &-runs
      border: 1px solid var(--border-default-color)
      border-radius: 8px
      overflow: hidden

      table
        th
          background-color: var(--bg-lower-color)

        tr:nth-child(odd) td
          background-color: var(--body-bg-color)

        tr:nth-child(even) td
          background-color: var(--bg-low-color)

    &-empty
      display: flex
      flex-direction: column
      align-items: center
      text-align: center
      align-self: center

      svg
        color: $info-default-color
