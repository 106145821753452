.row-sorting
  &__content-dropdown
    width: var(--row-popover-width)
    border: 1px solid $border-focused-color
    display: flex
    flex-direction: column
    overflow: hidden

    .sortable__list
      gap: $space-m
      min-height: auto

      &::after
        display: none

    .scroll-box--overflown .sortable__list
      margin-bottom: $space-m

  &__title
    @extend %heading-xsmall-h5
    padding: 12px

  &__item
    margin-inline: 12px

    .ui-lib-condition-input
      flex: 1

    &__drag-indicator.sortable__item__action
      padding: 10px
      margin: 0 0 0 10px

  &__actions
    padding: 12px

  @media screen and (max-width: 360px)
    &__content-dropdown
      min-width: auto
