.importer-details
  display: flex

  &-main
    width: 70%

  .collapsible
    position: relative
    z-index: 1

  &-aside
    width: 100%
    max-width: 368px
    padding-left: 10px

  .card
    margin-bottom: 10px

+screen-max(768px)
  .importer-details
    flex-direction: column

    &-main
      width: 100%

    &-aside
      max-width: none
      padding-left: 0
