\:root
  --alert-margin-horizontal: 16px

  +screen-min(700px)
    --alert-margin-horizontal: 32px

@keyframes anim-fadeinup
  0%
    opacity: 0
    transform: translateY(25%)
  100%
    opacity: 1
    transform: translateY(0)

@keyframes anim-fadeoutdown
  0%
    opacity: 1
    transform: translateY(0)
  100%
    opacity: 0
    transform: translateY(25%)

.alert
  border-radius: 5px
  word-break: break-word

  &.top
    top: 0
    padding: 16px 40px

  &.bottom
    position: fixed
    bottom: 0
    right: 0
    padding: 16px 20px
    margin: 20px var(--alert-margin-horizontal)
    width: calc(100% - var(--toolbar-width) - var(--alert-margin-horizontal) * 2)

  &-entering.bottom
    opacity: 0
    transform: translateY(25%)

  &-entered.bottom
    transition: all 300ms ease-in-out
    opacity: 1
    transform: translateY(0%)

  &-exit.bottom
    transform: translateY(0)

  &-exiting.bottom
    transition: all 300ms ease-in-out
    opacity: 0
    transform: translateY(25%)

  &-exited.bottom
    opacity: 0
    transform: translate(25%)

  &.with-shadow
    box-shadow: 0 2px 6px -1px rgba(0, 0, 0, 0.16), 0 1px 4px -1px rgba(0, 0, 0, 0.04)

  .close-button
    margin-left: 20px
    line-height: 1

    .close-icon
      width: 18px
      height: 18px
      transform: scale(2.1)

  .main-content
    display: flex
    align-items: center
    justify-content: space-between
    flex-wrap: nowrap

  .content
    flex-grow: 1
    display: flex
    align-items: center
    flex-wrap: nowrap
    justify-content: flex-start

  .details-container
    // margin-right of the icon + width of the icon
    margin-left: 41px

    .details
      @extend %paragraph-medium
      color: var(--text-secondary-color)

    .details p
      margin-bottom: 0

  .icon
    width: 100%
    max-width: 30px
    height: 100%
    max-height: 30px
    margin-right: 11px

  .text
    font-size: $text-size-base
    line-height: 1.25em
    color: $deprecated-text-primary-color

  .multiline
    flex-grow: 1

  .title
    margin: 0 0 4px 0
    font-weight: 500

  .message
    font-weight: 400

    strong
      @extend %heading-xsmall-h5

    p:last-child
      margin-bottom: 0

    &-markdown
      display: inline-block

    &-toggle-details
      color: $warning-pressed-color

  .button-container
    display: flex
    white-space: nowrap
    margin-left: 28px

  .button, .text-button
    padding-left: 28px
    padding-right: 28px

    &:not(:first-child)
      margin-left: 12px

  &-flash
    position: fixed
    z-index: $z-index-flash-message
    border-radius: 0
    text-align: center

  &-flash.top
    // Because of sticky header, alert should be shifted below header to avoid overlapping
    top: $app-header-height
    left: 0

  &-flash.bottom
    border-radius: 5px
    z-index: $z-index-flash-message-bottom
    text-align: left

  &.success
    background: $success-background-color

    .icon
      color: $success-status-color
    hr
      border: 1px solid $success-default-color

  &.success.bottom
    background: $success-status-color

    .text
      color: white

    .icon
      color: #fff

      path
        stroke: $success-status-color !important

    .close-icon
      color: #fff

  &.error
    background: $error-background-color

    .icon
      color: $error-status-color
    hr
      margin-top: 24px
      background-color: var(--border-error-color)

  &.info
    background: $info-background-color

    .icon
      color: $info-status-color
    hr
      border: 1px solid var(--icon-info-color)

  &.warning
    background: $warning-background-color

    .icon
      color: $warning-default-color
    hr
      border: 1px solid $warning-default-color



+screen-min(769px)
 .alert.bottom
   max-width: 480px

+screen-between(600px, 1023px)
  .alert
    padding: 16px 48px

    .content
      align-items: flex-start

    .icon
      margin-right: 9px

    .button-container
      flex-direction: column-reverse

    .button, .text-button
      &:last-child
        margin: 0

      &:not(:last-child)
        margin: 3px 0 0 0

    .dataSizeExceededMessage
      p:nth-child(2)
        font-size: $text-size-xxs
        font-weight: lighter

    &.bottom
      flex-direction: row

      .content
        align-items: center

+screen-max(599px)
  .alert
    flex-direction: column
    align-items: stretch
    padding: 16px 8px

    .content
      align-items: flex-start

    .icon
      margin-right: 9px

    .button-container
      flex-direction: column-reverse
      align-items: center
      margin: 12px 0 0 0

    .button
      width: 100%

    .button, .text-button
      &:last-child
        margin: 0

      &:not(:last-child)
        margin: 3px 0 0 0

    &.bottom
      flex-direction: row

      .content
        align-items: center
