.time-range
  display: flex
  align-items: center
  justify-content: space-between

  .arrow-next
    fill: var(--icon-disabled-color)
    flex-shrink: 0

  &.wide-view
    gap: 4px

.wizard-step
  .time-range
    flex-wrap: wrap

    +screen-min(768px)
      flex-wrap: nowrap
