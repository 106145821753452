.back-link
  position: relative
  display: inline-block
  max-width: 70px
  padding-left: 26px
  text-decoration: none

  &::before
    position: absolute
    top: 50%
    left: 11px
    display: block
    width: 5px
    height: 9px
    content: ''
    background: no-repeat url('~chevron.svg')
    transform: translateY(-50%) rotate(180deg)

  &:hover
    &::before
      filter: brightness(0) saturate(100%) invert(32%) sepia(90%) saturate(1034%) hue-rotate(163deg) brightness(103%) contrast(102%)
