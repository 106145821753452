$header-height: 62px

\:root
  --app-height: 100vh

.marketing-navigation
  padding-left: 32px
  flex: 1
  display: flex
  flex-direction: row
  font-family: 'Inter', sans-serif

  +screen-max(1023px)
    display: none
    position: absolute
    z-index: 4
    top: $header-height
    right: 0
    height: auto
    min-height: calc(var(--app-height) - #{$header-height})
    bottom: 0
    padding-left: 0
    width: 100%
    border-top: 1px solid #e7ebee
    flex-direction: column
    overflow: hidden
    overflow-y: scroll

    &.visible
      display: flex

  &__overlay
    position: fixed
    top: 88px
    left: 0
    right: 0
    bottom: 0
    transition: .275s all linear
    visibility: hidden
    z-index: -1

    +screen-max(1023px)
      display: none

    &.active
      visibility: visible
      background: rgba(3, 3, 39, 0.24)
      z-index: 9998

  .marketing-navigation-list
    display: flex
    gap: 8px
    flex: 1

    +screen-max(1023px)
      flex-grow: 1
      flex-direction: column
      transform: translateX(100%)
      transition: transform 0.3s ease-in
      will-change: transform
      background: #fff
      gap: 0

      &.fade-in
        transform: translateX(0)
        transition: transform 0.3s ease-out

  .marketing-navigation-item
    text-align: center
    display: flex
    align-items: center

    +screen-max(1023px)
      min-height: 56px
      background-color: #fff
      text-align: left
      border: 0
      display: block

      .marketing-navigation-account-label
        padding: 0px

      &.highlighted
        background-color: transparent

    &.active 
      .marketing-navigation-submenu
        border-bottom: 1px solid #C3CDD2
        will-change: opacity,max-height

        +screen-max(1023px)
          border-bottom: none

        &::before
          opacity: 1

          +screen-max(1023px)
            border-top: none

      &.nested > .marketing-navigation-item-button
        background-color: #F2FCFF

        &::after
          transform: rotate(180deg)
          filter: invert(53%) sepia(70%) saturate(6300%) hue-rotate(171deg) brightness(103%) contrast(101%) !important

    &.nested 
      &> .marketing-navigation-item-button::after
        display: block
        content: ''
        background: url('~icons/ic-chevron-down.svg')
        background-repeat: no-repeat
        color: #030327
        margin-left: 4px
        width: 24px
        height: 24px
        filter: invert(61%) sepia(18%) saturate(292%) hue-rotate(153deg) brightness(97%) contrast(90%)
        transition: 0.25s ease-in-out

  .marketing-navigation-item-button
    display: flex
    align-items: center
    padding: 12px 16px
    font-size: 16px
    font-weight: 500
    color: #030327
    cursor: pointer
    text-decoration: none
    background-color: inherit
    border-radius: 12px
    transition: background 0.2s ease, color 0.2s ease

    &:hover
      background-color: #F2FCFF

    +screen-max(1023px)
      width: 100%
      justify-content: space-between
      position: relative
      padding: 12px 64px
      border: 0
      border-radius: 0
      font-size: 16px
      font-weight: 600

    +screen-max(700px)
      padding: 12px 16px

  .marketing-navigation-submenu
    position: absolute
    max-height: 0
    top: 88px
    left: 0
    right: 0
    overflow: hidden
    background: #ffffff
    border-radius: 0px 0px 32px 32px
    box-shadow: 0px 12px 16px 0px rgba(0, 155, 205, 0.12)
    transition: .275s all linear
    will-change: opacity,max-height
    box-sizing: border-box
    z-index: 9999

    +screen-max(1023px)
      position: static
      display: block
      box-shadow: none
      background: #F2FCFF
      border-bottom: none
      border-radius: 0

    &::before
      content: ""
      position: absolute
      top: 0
      left: 0
      right: 0
      opacity: 0
      border-top: 1px solid rgba(195, 205, 210, 0.4)
      z-index: 2

    .container
      display: flex
      align-items: flex-start
      min-height: 230px

      +screen-max(1023px)
        flex-direction: column
        min-height: 0

    &__content
      padding: 32px 32px 32px 0
      flex: 1
      text-align: left
      align-self: stretch
      display: flex
      flex-direction: column
      justify-content: space-between

      +screen-max(1023px)
        padding: 0

    &__title
      margin-bottom: 24px
      color: #879ca5
      font-size: 16px
      font-weight: 600
      line-height: 1.5

      +screen-max(1023px)
        padding-left: 16px
        height: 48px
        display: flex
        align-items: center
        margin-bottom: 0
        line-height: 1.75
        font-weight: 400

    &__links
      max-width: 660px
      display: grid
      grid-template-columns: repeat(3, 1fr)
      gap: 45px 20px

      +screen-max(1023px)
        grid-template-columns: repeat(1, 1fr)
        gap: 0

    &-link
      display: flex
      align-items: flex-start
      flex-direction: column
      gap: 8px

      &__link
        position: relative
        padding-right: 20px
        color: #030327
        font-size: 18px
        font-weight: 600
        line-height: 1.3
        text-decoration: none
        transition: all 0.2s ease-in-out

        +screen-max(1023px)
          padding-right: 0
          padding-left: 32px
          height: 48px
          display: flex
          align-items: center
          width: 100%
          font-size: 16px
          font-weight: 400
          opacity: 0.8

          &::after
            display: none

        &::after
          content: ""
          position: absolute
          width: 24px
          height: 24px
          display: inline-block
          background-image: url(~icons/ic-arrow-next.svg)
          background-size: contain
          background-repeat: no-repeat
          cursor: pointer
          transform: translateX(-2px)
          opacity: 0
          transition: all 0.2s ease-in-out
          filter: invert(46%) sepia(68%) saturate(4911%) hue-rotate(169deg) brightness(100%) contrast(102%)

        &:hover
          color: #009bcd

          &::after
            transform: translateX(4px)
            opacity: 1

      &__desc
        color: #3F3F3F
        font-size: 12px
        line-height: 1.3

        +screen-max(1023px)
          display: none

    &__actions
      display: flex
      align-items: center
      gap: 22px
      margin-top: 16px

      +screen-max(1023px)
        display: none

    &__action
      position: relative
      display: flex
      align-items: center
      gap: 4px
      color: #009bcd
      font-size: 16px
      font-weight: 600
      line-height: 1.5
      text-decoration: none
      transation: all 0.2s ease-in-out

      &:hover
        color: #05759E

      &::before
        content: ""
        width: 24px
        height: 24px
        background-size: contain
        background-repeat: no-repeat
        cursor: pointer

      &--demo
        &::before
          background-image: url(~demo.svg)

        &::after
          content: "|"
          position: absolute
          right: -16px
          top: 50%
          color: rgba(195, 205, 210, 0.4)
          font-family: 'Inter', sans-serif
          font-size: 16px
          font-weight: 400
          line-height: 1.5
          transform: translateY(-50%)

      &--video
        &::before
          background-image: url(~icons/ic-run.svg)
          filter: invert(43%) sepia(92%) saturate(3351%) hue-rotate(168deg) brightness(99%) contrast(102%)

    &-side
      position: relative
      padding: 32px 0 32px 32px
      background: #F2FCFF
      display: flex
      flex-direction: column
      gap: 32px
      text-align: left
      align-self: stretch
      flex: 1
      max-width: 605px

      +screen-max(1023px)
        display: none

      &--dashboards
        max-width: none
        flex: 0

      &--sources
        +screen-max(1023px)
          display: flex
          padding: 0
          max-width: 100%
          gap: 0
          
      &::before
        content: ""
        position: absolute
        display: block
        left: 100%
        top: 0
        bottom: 0
        width: 50vw
        background: #F2FCFF

      &__title
        margin-bottom: 24px
        color: #3F3F3F
        font-size: 16px
        font-weight: 600
        line-height: 1.5

        +screen-max(1023px)
          padding-left: 16px
          height: 48px
          display: flex
          align-items: center
          margin-bottom: 0
          line-height: 1.75
          font-weight: 400
          color: #879ca5

      &__sources-list
        display: grid
        grid-template-columns: repeat(2, 1fr)
        gap: 24px 32px

        +screen-max(1023px)
          grid-template-columns: repeat(1, 1fr)
          gap: 0

      &-source
        display: flex
        align-items: center
        gap: 8px
        text-decoration: none

        +screen-max(1023px)
          padding-left: 32px
          height: 48px
          gap: 4px

        &:hover
          .marketing-navigation-submenu-side-source__title 
            color: #009bcd

        &__icon
          width: 32px
          height: 32px

          +screen-max(1023px)
            width: 24px
            height: 24px

        &__title
          color: #030327
          font-size: 14px
          line-height: 1.7
          transition: all 0.2s ease-in-out

          +screen-max(1023px)
            font-size: 16px
            line-height: 1.75

      &__dashboards-list
        display: flex
        gap: 8px

      &-dashboard
        width: 167px
        padding: 8px
        border-radius: 12px
        border: 1px solid rgba(195, 205, 210, 0.4)
        background: #ffffff
        box-shadow: 0px 1px 4px 0px rgba(0, 155, 205, 0.12)
        text-decoration: none
        transition: all 0.2s ease-in-out

        &:hover
          border-color: #009bcd

        &--cyan
          .marketing-navigation-submenu-side-dashboard__img-box
            background: linear-gradient(135deg, #DFF5FA 0.01%, #58BADE 100%)

        &--green
          .marketing-navigation-submenu-side-dashboard__img-box
            background: linear-gradient(303deg, #93D49D 0%, #D5EFD9 100%)

        &--purple
          .marketing-navigation-submenu-side-dashboard__img-box
            background: linear-gradient(303deg, #B97FDC 0%, #E9D7F3 100%)

        &__img-box
          width: 100%
          position: relative
          overflow: hidden
          margin-bottom: 8px
          border-radius: 8px
          padding-bottom: 64%

        &__img
          position: absolute
          top: 12px
          left: 12px
          width: 135%
          border-radius: 2px 2px 0 0

        &__title
          color: #030327
          font-size: 14px
          font-weight: 600
          line-height: 1.4

      &__links
        display: flex
        flex-direction: column
        align-items: flex-start
        gap: 16px

      &__link
        position: relative
        color: #030327
        font-size: 16px
        line-height: 1.75
        text-decoration: none
        transition: all 0.2s ease-in-out

        &::before
          content: ""
          position: absolute
          right: 0
          top: 50%
          width: 24px
          height: 24px
          background-image: url(~icons/ic-open-new-tab.svg)
          background-size: contain
          background-repeat: no-repeat
          cursor: pointer
          transform: translate(20px, -50%)
          opacity: 0
          transition: all 0.2s ease-in-out
          filter: invert(45%) sepia(46%) saturate(4782%) hue-rotate(167deg) brightness(96%) contrast(102%)

        &:hover
          color: #009bcd

          &::before
            transform: translate(28px, -50%)
            opacity: 1


  .marketing-navigation-item-account
    +screen-max(1023px)
      width: 100%
    .link
      display: inline-flex
      align-items: center
      gap: 4px

      +screen-max(1023px)
        padding-top: 12px
        padding-bottom: 12px
        font-size: 16px
        justify-content: center
        width: 100%

    .marketing-navigation-account-user-icon
      width: 20px
      height: 20px
      filter: invert(53%) sepia(70%) saturate(6300%) hue-rotate(171deg) brightness(103%) contrast(101%)

    &:hover
      .marketing-navigation-account-user-icon
        //https://codepen.io/sosuke/pen/Påjoqqp
        filter: invert(19%) sepia(73%) saturate(5514%) hue-rotate(183deg) brightness(98%) contrast(96%)

  &__box
    margin-left: auto
    display: flex
    align-items: center
    gap: 28px

    +screen-max(1023px)
      margin-top: 32px
      margin-left: 0
      padding: 0 64px
      display: flex
      align-items: center
      flex-direction: column-reverse

      .marketing-navigation-sign-up
        width: 100%
        .button
          width: 100%

    +screen-max(700px)
      padding: 0 16px

    .link
      white-space: nowrap

  .button
    padding: 0 24px
    height: 48px
    font-size: 14px
    line-height: 48px
    color: #fff
    background-color: #009bcd
    border: 0
    position: relative
    font-weight: 600
    border-radius: 12px

    > svg
      margin-right: 4px
      overflow: visible

    &:hover
      color: #fff
      background-color: darken(#009bcd, 5%)

    &.medium
      padding: 0 20px
      height: 42px
      font-size: 14px
      line-height: 42px

    &-ico
      img
        margin-left: 4px
        width: 20px
        height: 20px

      .button-arrow
        filter: invert(94%) sepia(54%) saturate(6639%) hue-rotate(185deg) brightness(119%) contrast(109%)

  .link
    font-weight: 500
    font-size: 16px
    text-decoration: none
    color: #009bcd
    transition: all 0.2s ease-in-out

    &:hover
      color: #05759E
