\:root
  --dashboard-insights-panel-width: 336px
  --dashboard-insights-panel-spacing: 20px
  --dashboard-insights-button-extra-spacing: 12px
  --dashboard-header-height: 140px

.dashboard-insights-panel
  @extend %paragraph-medium
  position: fixed
  height: calc(100vh - 2 * var(--dashboard-insights-panel-spacing) - var(--dashboard-insights-button-extra-spacing) - #{$app-header-height} - var(--dashboard-header-height))
  right: var(--dashboard-insights-panel-spacing)
  margin-top: var(--dashboard-insights-panel-spacing)
  width: var(--dashboard-insights-panel-width)
  border-radius: 8px
  border: 2px solid #A238E1
  background: var(--bg-default-color)
  overflow: hidden
  display: flex
  flex-direction: column

  &__header
    height: 72px
    width: 100%
    background-image: linear-gradient(180deg, #F3E6FA, var(--bg-default-color))
    padding-top: 24px
    padding-bottom: 16px

  &__close
    position: absolute
    right: 8px
    top: 8px
    cursor: pointer

  &__title
    @extend %heading-small-h4
    display: flex
    align-items: center
    justify-content: center
    gap: 4px

  &__content
    overflow: auto
    padding: 12px 24px
    display: flex
    flex-direction: column
    gap: 24px

.dashboard-insights-button
  @extend %subheading-medium
  position: absolute
  right: 20px
  bottom: -18px
  height: 36px
  padding: 6px 12px 6px 6px
  cursor: pointer
  display: flex
  align-items: center
  gap: 4px
  border-radius: 8px
  color: white
  background: linear-gradient(87.34deg, #E14DCB 0%, #A238E1 50%, #5F27A8 100%)
  border: 1px solid #A238E1
  overflow: hidden

  &--primary
    svg
      path
        fill: white

  &--secondary
    color: var(--decorative-eminence-color)
    background: var(--bg-default-color)

.dashboard-insights-section
  display: flex
  flex-direction: column
  gap: 8px

  &__title
    @extend %heading-xsmall-h5
    margin-bottom: 4px

    &--purple
      color: var(--decorative-eminence-color)

    &--blue
      color: var(--text-brand-color)

    &--green
      color: var(--fill-success-color)

  &__items
    display: flex
    flex-direction: column
    gap: inherit

  &--spacious
    gap: 16px

.dashboard-insights-finding
  padding: 8px 0
  width: 100%

  &__heading
    @extend %subheading-medium
    gap: 4px
    display: flex
    flex-direction: row
    align-items: center
    cursor: pointer

  &__details
    background-color: var(--bg-lower-color)
    border-radius: 4px
    padding: 4px 8px
    margin: 8px 0 8px 20px
