.schema-changed-notice

  &__details-container
    display: flex
    width: 100%
    max-height: 368px
    height: 100%
    overflow-y: auto

  &__details-item
    flex: 1 0 auto

    span
      vertical-align: middle

    svg
      width: 20px
      height: 20px
      vertical-align: middle
