.wizard-basic-footer
  border-top: 1px solid var(--border-default-color)
  background-color: var(--body-bg-color)
  flex-shrink: 0
  padding: 12px 16px

  &__content
    width: 100%
    max-width: 776px
    margin-inline: auto

  &__button-group
    display: flex
    gap: 8px

    .action-button
      min-height: 48px
