.empty-section
  text-align: center

  .illustration
    width: 128px

  +screen-max(480px)
    .button
      width: 100%

  +screen-max(355px)
    .illustration
      width: 80px

    .button
      width: 100%
