.field-textarea-container
  position: relative

  .copy-button
    rotate: 90deg
    position: absolute
    right: 8px
    top: 0
    display: none

  &:hover
    .copy-button
      display: block

.field-input,
.field-textarea
  box-sizing: border-box
  width: 100%
  height: 40px
  padding: 5px 11px
  border: 1px solid $input-border-color
  border-radius: 5px
  background-color: var(--bg-input-default-color)
  outline: none

  &:focus
    border-color: $input-border-active-color
  &.disabled, &:disabled
    background-color: $theme-lighter-color
    color: $deprecated-text-disabled-color
    cursor: default

.field-textarea
  display: block
  height: auto
  min-height: 40px
  resize: vertical

  &[readonly]
    color: var(--text-primary-color)
    border-color: var(--border-default-color)

.number-input-without-arrows
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0

  input[type=number]
    -moz-appearance: textfield

@keyframes rotateCounterClockwise
  from
    transform: rotate(0deg)
  to
    transform: rotate(-360deg)


.field-input-actions
  display: flex
  text-align: center
  vertical-align: middle
  user-select: none
  padding-left: 1rem
  gap: 1rem
  justify-content: center
  align-items: center

  &__update--loading
    opacity: 0.9
    pointer-events: none

    .field-input-actions__update--loading-icon
      animation: rotateCounterClockwise 2s linear infinite
