.editable-heading
  word-break: break-word
  height: fit-content
  flex-grow: 1
  overflow: hidden

  .editable-placeholder,
  .editable-content,
  .editable-input
    @extend %heading-medium-h3

  &--dark
    .editable-content,
    .editable-placeholder,
    .editable-input
      @extend %heading-xsmall-h5
      color: $text-invert-color

    .editable-input
      border: 1px solid transparent
      background: inherit
      padding: 0
      caret-color: var(--primary-color)
