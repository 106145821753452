.file-field
  position: relative
  input
    cursor: pointer
  a
    position: absolute
    top: 0
    right: 10px
    height: 40px
    display: flex
    align-items: center

  input[type="file"]
    display: none

  input[disabled] + a
    cursor: default

  &.has-error
    .field-input
      background-color: var(--bg-input-error-color)
      border-color: var(--border-error-color)

  &.has-field-helper
    .field-input
      border-bottom-right-radius: 0
      border-bottom-left-radius: 0

// #dk overriding Google Picker styles
.gsheet-addon-embedded
  .picker-dialog
    height: 600px !important

    .picker-dialog-content
      height: 100% !important
