.preview
  height: 100%
  background: var(--body-bg-color)
  display: flex
  flex-direction: column
  overflow: hidden

  &-header
    display: flex
    flex-grow: 0
    flex-shrink: 0
    justify-content: space-between
    align-items: center
    width: 100%
    padding: 16px 24px 8px
    gap: 8px

    +screen-max(425px)
      flex-direction: column
      align-items: flex-start
      gap: 12px

      .button-refresh
        margin-left: 0

    .header-title
      @extend %heading-large-h2
      flex-grow: 1
      display: flex
      gap: 8px
      align-items: center
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap

      svg
        flex-shrink: 0

    .button-continue
      flex-direction: row-reverse
      min-width: 208px

      svg
        margin-right: 0
        margin-left: 8px

    .button-refresh
      padding: 0

    +screen-max(768px)
      border-radius: 0

      .button-continue
        min-width: auto

  &-content
    height: 100%
    flex-grow: 1
    flex-shrink: 1,
    display: flex
    flex-direction: column
    position: relative
    overflow: hidden

  &-full-height
    height: calc(100% + 1px)
    flex-grow: 1
    justify-content: center
    align-items: center
    border-top: 1px solid $border-default-color
    margin-top: -1px
    overflow: auto

  .no-data-yet
    display: flex
    justify-content: center
    align-items: center
    height: 100%

  .ag-root-wrapper
    border-width: 1px 0 0

  &-last-updated
    @extend %paragraph-small
    display: flex
    align-items: center

  &-description
    display: flex
    align-items: center

    a
      text-decoration: underline

  &-source
    &__empty-state
      width: 100%
      display: flex
      flex-direction: column
      align-items: center
      gap: 16px
      margin-top: 160px

      svg
        width: 36px
        height: 36px
        color: $warning-default-color
        transform: scale(1.33)

      .title
        @extend %heading-xsmall-h5

      .message
        @extend %paragraph-medium
        display: flex
        flex-direction: column
        align-items: center

        .link
          @extend %subheading-medium
          color: $text-link-color

    &__error
      max-width: 768px
      margin: 0 auto
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
      gap: 8px
      padding: 24px
      height: inherit

      &-icon
        width: 48px
        height: 48px
        color: var(--icon-danger-color)

      &-title
        @extend %heading-xsmall-h5
        text-align: center

      &-message
        @extend %paragraph-medium
        text-align: center
        margin-bottom: 24px

      &-actions
        display: flex
        gap: 8px
        align-items: center
        margin-bottom: 24px

      &-details
        width: 100%



  &-outdated
    &__overlay
      position: absolute
      top: -6px // to smoothly blur tabs
      left: 0
      right: 0
      bottom: 0
      background: rgb(255, 255, 255, 0.7)
      backdrop-filter: blur(6px)
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center

    &__title
      @extend %heading-small-h4
      color: $text-primary-color
      margin-bottom: 4px

    &__description
      @extend %paragraph-medium
      margin-bottom: 24px

    &__actions
      display: flex
      flex-direction: row
      gap: 8px

.ag-tooltip
  @extend %captions-medium
  min-width: 150px
  max-width: 220px
  overflow-wrap: break-word
  color: $text-on-primary-color
  background-color: var(--dark-color)
  border-radius: 5px
  box-shadow: 0px 1px 8px rgba(3, 60, 82, 0.12)
  padding: 10px
  transform: translateX(-50%)
  text-align: center

  &::before
    content: ''
    position: absolute
    width: 10px
    height: 10px
    rotate: 45deg
    background-color: var(--dark-color)
    top: -5px
    left: calc(50% - 3px)
