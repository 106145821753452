.book-call
  display: flex
  justify-content: space-between
  align-items: flex-start

  &__title
    font-size: 20px
    letter-spacing: -0.02em
    line-height: 1.5
    margin-bottom: 4px

    +screen-max(768px)
      font-size: 18px

  &__text
    font-size: 16px
    margin-bottom: 7px

    +screen-max(768px)
      font-size: 14px

  &__link
    font-size: 16px
    font-weight: 500

    +screen-max(768px)
      font-size: 14px

  &__img-wrapper
    position: relative
    display: flex
    width: 54px
    min-width: 54px
    height: 54px
    margin-left: 14px

    &::before
      content: ''
      background-color: unset
      background-image: url('~icons/ic-checkmark-fill.svg')
      background-repeat: no-repeat
      background-size: 100% 100%
      border: 0
      position: absolute
      right: 0
      bottom: 0
      box-sizing: border-box
      display: flex
      align-items: center
      justify-content: center
      width: 14px
      height: 15px
      border-radius: 50%
      z-index: 2
      transform: scale(1.33)
      filter: invert(69%) sepia(8%) saturate(2630%) hue-rotate(77deg) brightness(85%) contrast(79%)

  &__img
    display: block
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    width: 100%
    height: 100%
    object-fit: contain
    border-radius: 50%
