.attribute
  &-value
    overflow-wrap: break-word
    white-space: pre-line

  .row &
    width: 50%

    + .attribute
      padding-left: 10px

  > *
    word-break: break-word
