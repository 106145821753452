.app-header
  $parent: &
  position: sticky
  top: var(--billing-alert-height)
  padding: 8px
  z-index: $z-index-header
  display: flex
  align-items: center
  justify-content: space-between
  height: $app-header-height
  background-color: #fff
  box-shadow: 0px 1px 8px rgba(3, 60, 82, 0.12)

  .logo
    display: flex

  .logo-sign
    display: flex
    width: $app-header-height
    height: 100%
    margin: 0
    justify-content: center
    align-items: center
    background-color: $theme-primary-color

  .title-wrapper
    display: flex
    flex-direction: row
    align-items: center
    margin-left: 36px
    margin-right: 16px
    gap: 8px
    flex-grow: 1
    overflow: hidden

    .button-back
      display: flex
      height: fit-content
      rotate: 180deg
      padding-inline: 12px
      border-left: 1px solid $border-default-color
      color: var(--primary-color)
      flex-shrink: 0

  .app-header-billing
    flex-grow: 1
    padding-left: 28px
    height: 100%
    display: flex
    align-items: center
    justify-content: right

    &__select-plan
      @extend %hyperlink-medium
      padding: 0 8px

    +screen-max(820px)
      display: none

  .wizard-actions
    display: flex
    flex-shrink: 0
    margin-right: 48px

    +screen-max(599px)
      margin-right: 16px

    &__button-group
      display: flex
      height: 40px
      gap: 1px
      border-radius: 5px
      overflow: hidden

      .action-button:first-child
        width: 128px

      .action-button:last-child
        width: 40px

    &__content-dropdown
      border-radius: 5px
      overflow: hidden

      .action-button:not(:last-child)
        border-bottom: 1px solid $border-default-color

  &--dark
    background-color: $bg-black-dark-color
    color: $text-invert-color
    padding: 0 16px
    gap: 16px
    box-shadow: none
    height: var(--wizard-header-height)

    .close-wizard
      height: 40px
      width: 40px
      background-color: #F9998C33
      color: $pale-red-color
      border-radius: 5px
      padding: 8px

      +screen-max(340px)
        display: none

  &--hidden
    display: none
    height: var(--app-header-height)

    +screen-max(476px)
      display: flex
