.new-source-modal
  &[data-reach-dialog-content]
    background-color: var(--bg-lower-color)
    border-radius: 16px 16px 0 0
    width: calc(100vw - 16px)
    height: calc(100vh - 48px)
    display: flex
    flex-direction: column
    overflow-x: hidden
    margin: 0
    padding: 0

  &__overlay
    &[data-reach-dialog-overlay]
      position: fixed
      top: 0
      left: 0
      bottom: 0
      right: 0
      background-color: $overlay-grey-dark-color
      display: flex
      justify-content: center
      align-items: flex-end
      z-index: $z-index-transformation-modal

  &__header
    padding: 16px 40px
    background-color: var(--body-bg-color)
    border-bottom: 1px solid var(--border-default-color)
    flex-shrink: 0
    flex-grow: 0

  &__title-wrapper
    display: flex
    justify-content: space-between
    align-items: center

  &__close
    height: 40px

  &__content
    height: 100%
    overflow: auto
    padding: 32px 40px

