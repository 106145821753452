.widget-center-all
  height: 100vh
  display: flex
  justify-content: center
  align-items: center

.widget-full-width
  width: 100%
  height: fit-content

  .integrations-picker-content
    margin-bottom: 32px
