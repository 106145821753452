.key-value-field-select
  .MuiOutlinedInput-root
    border-radius: 0
    height: 32px

  &.size-medium
    .MuiOutlinedInput-root
      height: 40px
    .MuiInputBase-input
      @extend %paragraph-medium

  .MuiInputBase-input
    @extend %paragraph-small
    color: $text-primary-color

    &::placeholder
      color: $text-disabled-color

  .MuiInputBase-root.MuiOutlinedInput-root[class*=MuiOutlinedInput-root]
    input.MuiInputBase-input
      padding: 0 0 0 6px

.MuiPaper-root.MuiAutocomplete-paper
  .key-value-field-select__listbox
    &.MuiAutocomplete-listbox
      li.MuiAutocomplete-option
        @extend %paragraph-small
        color: $text-primary-color
        padding: 6px 12px
    &.size-medium.MuiAutocomplete-listbox
      li.MuiAutocomplete-option
        @extend %paragraph-medium
