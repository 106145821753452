.rails-default-error-page
  color: #2E2F30
  text-align: center
  margin: 0

  .dialog
    width: 95%
    max-width: 33em
    margin: 4em auto 0

    div
      border: 1px solid #CCC
      border-right-color: #999
      border-left-color: #999
      border-bottom-color: #BBB
      border-top: #B00100 solid 4px
      border-top-left-radius: 9px
      border-top-right-radius: 9px
      background-color: white
      padding: 7px 10% 0
      box-shadow: 0 3px 8px rgba(50, 50, 50, 0.17)

    & > p
        margin: 0 0 1em
        padding: 1em
        background-color: #F7F7F7        
        border: 1px solid #CCC
        border-right-color: #999
        border-left-color: #999
        border-bottom-color: #999
        border-bottom-left-radius: 4px
        border-bottom-right-radius: 4px
        border-top-color: #DADADA
        color: #666
        box-shadow: 0 3px 8px rgba(50, 50, 50, 0.17)
  
  h1
    font-size: 20px
    color: #730E15
    line-height: 1.5em
