.account-menu
  position: relative

  &-wrapper.MuiPopover-paper
    box-shadow: 0px 4px 16px 0px #033C5229
    max-height: 430px
    border-radius: 8px
    width: 320px

  &__mobile
    display: flex
    flex-direction: column
    height: calc(100vh - $app-header-height - var(--billing-alert-height) - 56px)

    &-wrapper
      &--hidden
        display: none

    .submenu-item-logout
      margin-top: auto
      border-top: 1px solid var(--border-default-color)
      text-align: center

.navigation-submenu
  position: absolute
  top: 100%
  right: 0
  z-index: $z-index-navigation-submenu
  background-color: var(--body-bg-color)
  box-shadow: 0 15px 20px rgba(0, 82, 111, 0.15)
  border-radius: 0 0 8px 8px
  overflow: auto
  max-height: 540px

  &::before
    content: ""
    position: absolute
    top: -$app-header-height
    left: 0
    right: 0
    width: 100%
    display: block
    height: $app-header-height
    box-shadow: 0px 1px 8px rgba(3, 60, 82, 0.12)
    z-index: 2

.submenu-item
  padding: 4px 8px
  text-align: left

  &:first-child
    position: sticky
    top: 0
    margin-bottom: 4px
    background: var(--body-bg-color)
    border-bottom: 1px solid var(--border-default-color)
    z-index: 1

    &:hover
      .submenu-item-button
        background-color: var(--body-bg-color)

        span
          color: inherit

        .submenu-item-user-link
          color: #0687b7

  &:hover
    .submenu-item-button
      background-color: var(--bg-lower-color)
      border-radius: 4px

      span
        color: $brand-color

  &--current
    background-color: var(--body-bg-color)

    &:hover
      .submenu-item-button
        span
          color: inherit

    .submenu-item-account
      background-color: $bg-grey-light-color
      border-radius: 4px

      &> span::after
        position: absolute
        bottom: -13px
        left: 26px
        width: 13px
        height: 13px
        content: ''
        background-image: url(~icon-selected-company.svg)
        background-repeat: no-repeat
        background-size: 100% 100%

.submenu-item-button
  display: flex
  flex-direction: column
  justify-content: center
  height: 56px
  padding: 0 16px
  font-size: 14px
  font-weight: 500
  line-height: 14px
  text-decoration: none

.submenu-item-user
  flex-direction: row
  align-items: center
  justify-content: flex-start
  color: #0a0836

  &-image
    border-radius: 50%

  &-details
    padding-left: 10px
    line-height: 17px

  &-email,
  &-name,
  &-link
    display: block
    max-width: 195px
    overflow: hidden
    text-overflow: ellipsis

  &-link
    margin-top: 4px
    font-weight: 600
    font-size: 12px
    line-height: 16px
    text-transform: uppercase
    color: $text-link-color

  &-email
    @extend %paragraph-small
    color: var(--text-secondary-color)
    text-transform: lowercase

    &:only-child
      line-height: 14px

  &-name
    text-transform: capitalize

  &:hover
    color: #0a0836

.submenu-item-account
  font-weight: 500
  color: #0a0836
  text-transform: initial
  width: 100%

  &.red
    &> span::before
      background-color: var(--decorative-pale-red-color)

  &.blue
    &> span::before
      background-color: var(--decorative-cerulean-color)

  &.green
    &> span::before
      background-color: var(--decorative-algae-color)

  &.yellow
    &> span::before
      background-color: var(--decorative-sunset-color)

  &.purple
    &> span::before
      background-color: var(--decorative-eminence-color)

  &:hover
    color: #0a0836

  &> span
    position: relative
    padding-left: 46px

    &::before
      position: absolute
      top: 50%
      left: 0
      width: 36px
      height: 36px
      content: ''
      border-radius: 50%
      transform: translateY(-50%)

  &__icon
    position: absolute
    top: 50%
    left: 0
    width: 36px
    height: 36px
    background-image: url(~icons/ic-organization.svg)
    background-repeat: no-repeat
    background-position: center
    background-size: 24px
    transform: translateY(-50%)

.submenu-item-logout
  position: sticky
  bottom: 0
  background: #ffffff
  border-top: none
  text-transform: uppercase
  padding: 4px 12px

  &:hover
    background-color: #fff6f5

  .submenu-item-button
    height: 40px

  span
    position: relative
    color: $error-status-color
    text-transform: uppercase
    display: flex
    align-items: center
    font-size: 12px
    font-weight: 600

.navigation-submenu
  +screen-max(767px)
    top: $app-header-height

  +screen-max(475px)
    .submenu-item
      width: 100vw
