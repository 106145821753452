.condition-input
  label
    margin-bottom: 6px

  .hint p
    color: $deprecated-text-secondary-color
    margin-bottom: 8px
    line-height: 20px

  .rows-container
    background: white
    border-radius: 5px

    .inputs-row
      display: flex
      box-sizing: border-box

      .one-third
        flex: 0 0 calc(100% / 3 - 10px)

      &:not(:first-child)
        margin-top: -1px
      &:not(:last-child)
        border-bottom-color: transparent

      &:first-child
        .key-input
          border-top-left-radius: 5px
        .remove-btn
          border-top-right-radius: 5px

      .bordered-select
        border: 0
        fieldset
          border: 0

        &.disabled
          color: #879CA5

      .bordered-select .MuiOutlinedInput-root
        border-radius: 0
        padding: 4px 7px

      .bordered, .bordered-select
        border-radius: 0
        box-sizing: border-box
        border: 1px solid $input-border-color

        &:not(:first-child)
          margin-left: -1px
        &:not(:last-child)
          border-right-color: transparent

        &:hover:enabled
          border-color: $input-border-hover-color
          z-index: 1

        &:active,
        &:focus
          border-color: $input-border-active-color
          z-index: 2

      .key-input
        width: 25%

      .value-input
        width: 75%

      .remove-btn
        display: flex
        justify-content: center
        align-items: center
        width: 40px
        cursor: pointer
        background: $theme-light-color

        svg
          font-size: 16px
          color: $theme-primary-darker-color

        &:hover svg
          color: $theme-primary-dark-color


    .add-new-row
      height: 40px
      color: $theme-primary-color
      padding: 10px 12px
      font-weight: 500
      line-height: 20px
      cursor: pointer
      border: 1px solid $input-border-color
      border-top: none

    &.empty
      .add-new-row
        border-top: 1px solid $input-border-color

    .add-new-row, .remove-btn
      &.disabled
        color: $deprecated-text-disabled-color
        background-color: $theme-lighter-color
        cursor: default
