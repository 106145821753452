.users-table
  display: table
  width: 100%
  font-size: 16px

  &-header
    .users-table-cell
      position: relative
      font-weight: 500
      cursor: pointer
      padding: 10px

      &::after
        padding: 0 5px
        font-size: 12px
        color: rgba(0, 0, 0, 0.54)
        content: ' '

      &.asc::after
        content: '▲'

      &.desc::after
        content: '▼'

      &.with-link-icon
        display: flex
        align-items: center

        a
          display: inline-flex

  &-row
    display: table-row
    border-bottom: 1px solid rgba(0, 0, 0, 0.12)

    &:last-child
      border-bottom: none

  &-cell
    display: table-cell
    padding: 12px 10px
    vertical-align: middle

    &:first-child
      padding-left: 0

    &:last-child
      padding-right: 0

  &-role
    text-transform: capitalize

  .action-cell
    display: flex

  .users-table-name,
  .users-table-email
    span
      word-break: break-word

  .users-table-header
    .users-table-cell-action
      text-align: right

      &::after
        display: none

  .users-table-delete
    text-align: center

    .delete-action
      display: inline-flex

  .invitation-status
    position: relative
    display: flex
    padding-left: 18px
    text-transform: capitalize

    &::before
      position: absolute
      top: 7.5px
      left: 0
      width: 10px
      height: 10px
      content: ''
      border-radius: 50%

    &.invited::before
      background-color: #05759e

    &.joined::before
      background-color: $success-status-color

+screen-min(768px)
  .users-table-email
    width: 340px

  .users-table-name
    width: 280px

  .users-table-role
    width: 315px
    padding-right: 40px

  .users-table-status
    width: 150px

  .users-table-delete
    width: 100px

+screen-max(768px)
  .users-table
    display: block

    &-row
      display: flex
      flex-wrap: wrap
      padding: 16px

      &.users-table-header
        display: none

      &:last-child
        border-bottom: 0

    &-cell
      padding: 0

    &-email
      width: 100%
      margin-bottom: 8px
      order: 2

    &-name
      width: 100%
      font-weight: 500
      margin-bottom: 8px
      order: 1

    &-role
      width: 37.5%
      margin-right: 24px
      order: 3

    &-status
      margin-right: 24px
      order: 4

    &-delete
      order: 5

      .delete-action
        padding: 8px

    &-role,
    &-status,
    &-delete
      display: flex
      align-items: center

    .invitation-status
      line-height: 14px

      &::before
        top: 50%
        transform: translateY(-50%)

+screen-min(475px)
  .users-table
    &-row
      padding-left: 0
      padding-right: 0

+screen-max(475px)
  .users-table
    padding: 5px 0
