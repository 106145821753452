%dashboard-component-common
  background-color: var(--body-bg-color)
  border: 1px solid var(--border-default-color)
  border-radius: 12px
  overflow: hidden
  height: 100%

.dashboard
  &-container
    position: relative
    flex-grow: 1
    display: flex
    flex-direction: column
    margin: 16px 40px 0  // this is impacted by grid spacing in features/dashboard/grid.js

    +screen-max(1240px)
      margin-right: 0
      margin-left: 0

    &--with-insights-button
      padding-top: var(--dashboard-insights-button-extra-spacing)

    &--with-insights-panel
      margin-right: calc(var(--dashboard-insights-panel-width) + var(--dashboard-insights-panel-spacing))

  &-component
    @extend %dashboard-component-common
    padding: 16px 24px 16px
    font-family: $barlow-font-family

  &-data-labels
    position: absolute
    top: 56px
    bottom: 16px
    right: 36px
    display: flex
    flex-direction: column
    justify-content: space-around
    width: 18%
    font-family: $barlow-font-family

    &__label
      display: flex
      justify-content: space-between
    &__value
      font-weight: 500
    &__dots
      flex-shrink: 1
      overflow: hidden

  &-section-header
    display: flex
    align-items: center
    margin: 0px
    padding: 0
    border: 0px
    background: none
    font-family: var(--inter-font-family)
    color: var(--text-secondary-color)

  &-header
    background-color: var(--bg-low-color)
    border-bottom: 1px solid var(--border-default-color)
    padding: 24px 56px 16px
    position: sticky
    top: $app-header-height
    z-index: $z-index-dashboard-header
    height: fit-content
    transition: box-shadow 0.2s

    +screen-max(1024px)
      padding: 24px 32px 16px

    +screen-max(768px)
      padding: 12px 16px

    body.is-fullscreen &
      top: 0
      background: var(--body-bg-color)

    &__name, &__importer-details
      flex-grow: 1

      textarea
        font-family: var(--font-family-heading)
        font-family: 'DM Sans', 'Helvetica', 'Arial', sans-serif
        font-size: 24px
        font-weight: bold

    &__controls
      display: flex
      align-items: center
      gap: 16px

      +screen-max(768px)
        align-items: flex-start
        flex-direction: column
        gap: 8px

    &__secondary-row
      margin-top: 4px
      display: flex
      gap: 0 16px
      align-items: flex-start

    &__action
      flex: none
      display: flex
      gap: 10px
      align-items: center

    &__actions, &__filters
      display: flex
      flex: none
      gap: 10px
      align-items: center

      .divider
        min-height: 24px
        width: 1px
        border-right: 1px solid var(--border-default-color)

      .menu-button-label
        flex-shrink: 0
        padding: 0

      .date-range-filter
        display: flex
        margin-left: auto
        flex-shrink: 0
        align-items: center

        > span
          font-weight: 500

      .row-filter
        .toolbar-menu-button
          padding-right: 7px
          min-height: 40px

    .dashboard-header__filters
      +screen-max(768px)
        display: none

    &.dashboard-header__normal
      .dashboard-header__controls
        +screen-max(1024px)
          flex-direction: column
          align-items: flex-start
          gap: 8px

      .dashboard-header__actions
        +screen-max(768px)
          display: none

    &.dashboard-header__preview
      .dashboard-header__filters
        margin-top: 8px

        +screen-max(1024px)
          margin-top: 0

      .dashboard-header__secondary-row
        +screen-max(1024px)
          margin-top: 12px
          align-items: center

        +screen-max(768px)
          margin-top: 8px

    &__running-indicator
      cursor: default
      gap: $spaceSmall

      &:hover, &:active
        color: var(--action-button-color)

    &__close-fullscreen-button
      flex-shrink: 0

    .dashboard__integrations
      +screen-max(1024px)
        margin-top: 0
        gap: 4px

        .dashboard__integration-name
          display: none

    &--scrolled
      box-shadow: 0px 1px 8px rgba(3, 60, 82, 0.12)
      padding-top: 16px

      .dashboard__integrations, .breadcrumb-back, .dashboard-header__description
        display: none

    &__description
      @extend %paragraph-small

      +screen-max(1024px)
        display: none

    .react-datepicker-wrapper
      margin-left: 10px
      min-width: 250px

  &-loader
    display: flex
    flex: 1 0 100%
    flex-direction: column
    justify-content: center
    align-items: center
    gap: $spaceMedium

    &__info-text
      max-width: 300px
      font-size: var(--text-size-s)
      font-family: var(--inter-font-family)
      font-weight: 600
      text-align: center

  &__integrations
    display: flex
    flex-wrap: wrap
    gap: 10px
    margin: 4px 0 0

    &-show-more
      color: var(--text-button-brand-default-color)

  &__integration
    display: flex
    align-items: center
    gap: 2px

    &-name
      @extend %paragraph-small
      color: var(--text-secondary-color)

    &--disabled
      filter: grayscale(100%)
