.wizard-not-supported
  height: calc(100vh - #{$app-header-height})
  padding: 32px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

  &__icon
    width: 72px
    height: 72px
    margin-bottom: 8px
    color: var(--primary-light-color)

  &__title
    @extend %heading-xsmall-h5
    text-align: center
    margin-bottom: 16px

  &__description
    @extend %paragraph-medium
    text-align: center
