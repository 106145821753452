.signup
  padding: 24px 0
  height: 100%
  display: flex
  flex-direction: column

  &__wrapper
    min-height: calc(100vh - 88px)
    display: flex
    font-family: 'Inter', sans-serif
    background: #ffffff

    .container
      width: 100%
      height: auto
  
  &__content
    display: flex
    justify-content: space-between
    gap: 32px
    flex: 1
    align-items: flex-start

    +screen-max(1024px)
      flex-direction: column
      justify-content: center
      align-items: center

  &-form
    flex: 1
    width: 100%
    max-width: 416px
    padding: 32px 40px
    border-radius: 16px
    background: #F6FAFB

    +screen-max(1024px)  
      padding: 32px 16px

    a
      color: #009BCD
      text-decoration: none

    &__agreement
      margin-top: 12px
      text-align: center
      font-size: 12px
      line-height: 1.3

    &__text
      margin-top: 24px
      font-size: 16px
      line-height: 1.5
      text-align: center


  &__title
    padding-top: 56px
    margin-bottom: 8px
    font-size: 48px

    +screen-max(768px)
      padding-top: 16px
      font-size: 32px

    .highlighted
      color: #009BCD

  &__text
    margin-bottom: 16px
    font-size: 16px
    line-height: 1.75
    color: #605E5D

  &-list
    padding-top: 24px
    display: flex
    flex-direction: column
    gap: 8px

    &__item
      display: inline-flex
      align-items: center
      font-size: 16px
      font-weight: 500
      
      &::before
        content: ''
        margin-right: 11px
        display: block
        width: 24px
        min-width: 24px
        height: 24px
        background-color: unset
        background-image: url('~icons/ic-checkmark-fill.svg')
        background-repeat: no-repeat
        background-size: 100% 100%
        border: 0
        filter: invert(69%) sepia(8%) saturate(2630%) hue-rotate(77deg) brightness(85%) contrast(79%)
  
  &-divider
    margin: 36px 0
    width: 100%
    text-align: center
    border-bottom: 1px solid rgba(195, 205, 210, 0.40)
    line-height: 0.1rem

    &__text
      background: #F6FAFB
      padding: 0 16px
      font-size: 16px
      color: #605E5D
  
  &__captcha
    margin-top: 45px
    max-width: 480px

    +screen-max(1024px)
      text-align: center
      max-width: 100%
