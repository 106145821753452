.action-button
  box-sizing: border-box
  border: 1px solid transparent
  padding: 7px 8px
  font-size: 12px
  line-height: 24px
  position: relative
  display: inline-flex
  align-items: center
  justify-content: center
  flex-wrap: nowrap
  white-space: nowrap
  width: fit-content
  overflow: hidden
  text-decoration: none
  text-transform: uppercase
  font-weight: 600
  letter-spacing: 0.02em
  gap: 4px

  svg
    flex-shrink: 0

  &--with-border
    border-radius: 5px

  &.action-button--disabled,
  &:disabled
    pointer-events: none
    cursor: not-allowed

  .loader
    margin-bottom: -4px

  &.split-button-trigger
    > svg
      width: 5px
      height: 5px
      color: #ffffff
      transform: scale(4.8)

  &--small
    padding: 6px
    line-height: 20px

  &--shorter
    height: 32px

  &--min-width-medium
    min-width: 76px

  &--fixed-width-small
    width: 128px

  &--fixed-width-medium
    width: 168px

  &--fixed-width-large
    width: 208px

  &--fixed-width-xl
    width: 320px

  &--full-width
    width: 100%

  &--left
    direction: ltr

  &--right
    direction: rtl

  &--primary
    color: $theme-white
    background-color: $theme-primary-color

    &:hover:not(:disabled):not(.action-button--disabled):not(:active)
      color: $theme-white
      background-color: $theme-primary-darker-color

    &:active
      color: $theme-white
      background-color: $theme-primary-dark-color

    &:focus-visible:not(:active)
      outline: 1px solid $deprecated-text-primary-color
      outline-offset: 1px

    &.action-button--disabled,
    &:disabled
      opacity: 0.7
      background-color: $theme-grey-color
      color: $deprecated-text-secondary-color

      .loader .MuiCircularProgress-root
        color: $deprecated-text-secondary-color

    &.action-button--theme-warning
      background-color: var(--text-button-warning-default-color)
      color: $theme-white

      &:hover:not(:disabled):not(.action-button--disabled):not(:active)
        background-color: var(--text-button-warning-hover-color)
        color: $theme-white

      &:hover:not(:disabled):not(.action-button--disabled):active
        background-color: var(--bg-warning-btn-active-color)

    &.action-button--theme-success
      background-color: $success-default-color
      color: $theme-white

      &:hover:not(:disabled):not(.action-button--disabled):not(:active)
        background-color: $success-hover-color
        color: $theme-white

      &:hover:not(:disabled):not(.action-button--disabled):active
        background-color: $success-hover-color

  &--secondary
    background-color: var(--action-button-background-color)
    color: var(--action-button-color)

    &.action-button--theme-primary
      --action-button-color: #{$text-theme-color}
      --action-button-background-color: var(--body-bg-color)
      --action-button-border-color: #{$theme-primary-color}
      --action-button-hover-color: #{$theme-primary-darker-color}
      --action-button-hover-background-color: #{$theme-lighter-color}
      --action-button-hover-border-color: #{$theme-primary-darker-color}
      --action-button-pressed-color: #{$theme-primary-dark-color}
      --action-button-pressed-background-color: #{$theme-lighter-color}
      --action-button-pressed-border-color: #{$theme-primary-dark-color}

    &.action-button--theme-danger
      --action-button-color: #{$critical-default-color}
      --action-button-background-color: var(--body-bg-color)
      --action-button-border-color: #{$border-critical-color}
      --action-button-hover-color: #{$critical-hover-color}
      --action-button-hover-background-color: #{$bg-critical-color}
      --action-button-hover-border-color: #{$border-critical-color}
      --action-button-pressed-color: #{$critical-pressed-color}
      --action-button-pressed-background-color: #{$bg-critical-color}
      --action-button-pressed-border-color: #{$critical-pressed-color}

    &.action-button--theme-success
      --action-button-color: #{$success-default-color}
      --action-button-background-color: var(--body-bg-color)
      --action-button-border-color: #{$border-success-color}
      --action-button-hover-color: #{$success-hover-color}
      --action-button-hover-background-color: #{$bg-success-color}
      --action-button-hover-border-color: #{$border-success-color}
      --action-button-pressed-color: #{$success-pressed-color}
      --action-button-pressed-background-color: #{$bg-success-color}
      --action-button-pressed-border-color: #{$success-pressed-color}

    &.action-button--theme-dark
      --action-button-color: var(--primary-light-color)
      --action-button-background-color: $$bg-transparent-color
      --action-button-border-color: var(--primary-light-color)
      --action-button-hover-color: var(--primary-light-color)
      --action-button-hover-background-color: #{rgba($bg-white-color, 0.08)}
      --action-button-hover-border-color: var(--primary-light-color)
      --action-button-pressed-color: var(--primary-light-color)
      --action-button-pressed-background-color: #{rgba($bg-white-color, 0.16)}
      --action-button-pressed-border-color: var(--primary-light-color)

      &:focus-visible:not(:active):not(.action-button--active)
        border-color: $border-white-color

    &.action-button--with-border
      border: 1px solid var(--action-button-border-color)

      &:hover:not(:disabled):not(.action-button--disabled):not(:active)
        border-color: var(--action-button-hover-border-color)

      &:active
        border-color: var(--action-button-pressed-border-color)

    &:hover:not(:disabled):not(.action-button--disabled):not(:active)
      color: var(--action-button-hover-color)
      background-color: var(--action-button-hover-background-color)

    &:focus-visible:not(:active)
      outline: 1px solid var(--action-button-border-color)
      outline-offset: 1px

    &:active
      color: var(--action-button-pressed-color)
      background-color: var(--action-button-pressed-background-color)

    &.action-button--disabled,
    &:disabled
      background-color: transparent
      color: $text-disabled-color

      .loader .MuiCircularProgress-root
        color: $text-disabled-color

      &.action-button--with-border
        border-color: var(--border-default-color)

  &--flat
    background-color: transparent
    color: var(--action-button-color)

    &:hover:not(:disabled):not(.action-button--disabled):not(:active):not(.action-button--active)
      color: var(--action-button-hover-color)
      background-color: transparent

    &:active
      color: var(--action-button-pressed-color)

    &:focus-visible:not(:active):not(.action-button--active)
      outline: 1px solid $theme-dark-grey
      outline-offset: 1px

    &.action-button--disabled,
    &:disabled
      color: $text-disabled-color

    &.action-button--theme-primary
      --action-button-color: #{$text-theme-color}
      --action-button-border-color: #{$theme-primary-color}
      --action-button-hover-color: #{$theme-primary-darker-color}
      --action-button-hover-background-color: #{$theme-lighter-color}
      --action-button-hover-border-color: #{$theme-primary-darker-color}
      --action-button-pressed-color: #{$theme-primary-dark-color}
      --action-button-pressed-background-color: #{$theme-lighter-color}
      --action-button-pressed-border-color: #{$theme-primary-dark-color}

      &.action-button--active
        background-color: $bg-grey-light-color
        color: var(--primary-active-color)

    &.action-button--theme-danger
      --action-button-color: #{$critical-default-color}
      --action-button-border-color: #{$border-critical-color}
      --action-button-hover-color: #{$critical-hover-color}
      --action-button-hover-background-color: #{$bg-critical-color}
      --action-button-hover-border-color: #{$border-critical-color}
      --action-button-pressed-color: #{$critical-pressed-color}
      --action-button-pressed-background-color: #{$bg-critical-color}
      --action-button-pressed-border-color: #{$critical-pressed-color}
