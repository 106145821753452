.processing-overlay
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  background: inherit
  border-radius: inherit

  .title
    margin: 8px 0 4px 0

  .subtitle
    color: $deprecated-text-secondary-color
