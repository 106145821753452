html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video
  box-sizing: border-box
  padding: 0
  margin: 0
  border: 0

ul
  padding-left: 0
  list-style: none

a
  cursor: pointer

button
  padding: 0
  cursor: pointer
  background: none
  border: 0

button
  &:focus:not(:focus-visible),
  &:hover
    outline: none

input,
textarea
  &:focus
    outline: none

input::-ms-reveal,
input::-ms-clear
  display: none
