.text-field
  width: 100%
  max-width: 250px

  &-settings
    width: 100%
    max-width: 350px

  &-importers-search
    float: right
    max-width: 300px

    .MuiFormLabel-root
      font-size: 14px
      color: #879ca5

  .MuiFormLabel-root
    &.Mui-focused
      color: $theme-primary-color

  .MuiInput-underline
    &::after
      border-bottom: 2px solid $theme-primary-color
