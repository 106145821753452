.billing-alert
  position: sticky
  top: 0
  left: 0
  right: 0
  z-index: 11

  .alert
    border-radius: 0
    padding-top: 4px
    padding-bottom: 4px
    min-height: 40px
    display: flex
    align-items: center

    &.warning
      background: var(--bg-warning-color)
      border-bottom: 1px solid var(--border-warning-color)

      .icon
        color: var(--icon-warning-color)

      .title,
      .message
        color: var(--bg-warning-btn-active-color)

      .billing-alert-link
        color: var(--text-button-warning-default-color)

    &.error
      background: var(--bg-button-secondary-danger-hover-color)
      border-bottom: 1px solid var(--border-error-color)
      
      .icon
        color: var(--text-error-color)

      .title,
      .message
        color: var(--text-button-danger-pressed-color)

      .billing-alert-link
        color: var(--text-button-danger-default-color)

    .main-content
      justify-content: center
      flex: 1

    .content
      justify-content: center
      max-width: 900px

    .icon
      width: 20px
      min-width: 20px
      height: 20px

    .multiline
      flex-grow: 0
      text-align: center

      .title
        margin-bottom: 0
        display: inline
        font-size: 14px

        &::after
          content: ". "

      .message
        display: inline
        font-size: 14px

  .billing-alert-link
    padding-left: 10px
    font-weight: 600
    font-size: 12px
    line-height: 1.33
    text-transform: uppercase
