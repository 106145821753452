.wizard-summary
  padding: 0
  display: flex
  flex-direction: column

  &__wrapper
    @extend .wizard-content__spacing
    background-color: var(--bg-lower-color)

  &__icon
    @extend %decorative-prices
    margin-bottom: 16px

  &__header
    margin-bottom: 32px

  &__title
    @extend %heading-large-h2
    margin-bottom: 4px

  &__description
    @extend %paragraph-medium

  &__sources
    display: flex
    flex-direction: column
    gap: 8px

    &--advanced
      margin-bottom: 64px

  &__actions
    margin-bottom: 32px
    +screen-min(768px)
      flex-direction: row

  &__connect
    display: flex
    flex-direction: column
    gap: 8px
    margin-bottom: 32px

    &-list
      display: flex
      flex-direction: column
      gap: 8px

    &-item
      display: flex
      align-items: center
      gap: 8px
      border: 1px solid $border-default-color
      border-radius: 8px
      background: $bg-grey-color
      padding: 7px 12px

      svg
        flex-shrink: 0

      &-destination
        padding: 19px 12px

    &-name
      @extend %heading-xsmall-h5

    &-description
      @extend %paragraph-small
      margin-bottom: 0
      color: var(--text-secondary-color)

    &-button
      background-color: var(--bg-lower-color)

  &__connect-button,
  &__edit-button
    margin-left: auto
    flex-shrink: 0

  &__stats
    @extend %paragraph-small
    color: var(--text-secondary-color)
    margin-top: 12px
