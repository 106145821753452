$header-z-index: 99999

.marketing-header
  position: sticky
  top: 0
  z-index: $header-z-index
  display: flex
  height: 88px
  background-color: #fff

  .container
    width: 100%
    flex-direction: row
    align-items: center
    max-width: 1600px
    padding: 0 64px
    margin: 0 auto

    +screen-max(700px)
      padding: 0 16px

  .logo
    width: 164px
    height: 40px
    text-indent: -9999px
    background: url('~coupler-logo-v2.svg') no-repeat center
    background-repeat: no-repeat
    background-size: contain

  .hamburger
    &-wrapper
      display: none

    &.open .line
      background-color: $theme-primary-color

  +screen-max(1023px)
    justify-content: space-between
    height: 62px

    .hamburger-wrapper
      display: flex
      margin-left: auto
