.editable
  display: flex

  &-input
    flex-grow: 1
    padding: 3px 12px
    margin: 0
    line-height: 1.2
    resize: none
    background: #f2fcff
    border: 1px solid #05759e
    border-radius: 5px
    caret-color: var(--primary-color)

    &:focus
      outline: none

    &.wide
      height: 40px
      box-sizing: border-box
      padding: 5px 12px
      line-height: 1.75

    &--borderless
      background: none
      border: 1px solid transparent
      padding: 0

  &-content
    display: flex
    align-items: center
    gap: 8px
    border: 1px solid transparent
    max-width: 100%
    word-break: break-word

    &:hover
      cursor: pointer

    &.disabled
      &:hover
        cursor: default

  &-icon
    min-width: 24px
    color: var(--muted-color)

    &.mobile-multiline
      align-self: flex-start

      +screen-max(480px)
        align-self: flex-end

        &.align-middle
          align-self: center
