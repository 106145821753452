.run-list
  th
    background: var(--bg-lower-color)
    text-align: left
    font: 500 var(--text-size-xs)/20px 'Barlow', normal
    border-bottom: 1px solid var(--border-default-color)
    padding: 12px 10px

    &:not(:last-of-type)
      border-right: 1px solid var(--border-default-color)

  tr
    background: var(--body-bg-color)

  tr:nth-child(even)
    background: var(--bg-low-color)
