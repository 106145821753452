.key-value-field-text
  background-color: var(--body-bg-color)
  border: 1px solid $border-default-color
  color: $text-primary-color
  box-sizing: border-box

  &::placeholder
    color: $text-disabled-color

  &.size-small
    @extend %paragraph-small
    padding: 6px 8px

  &.size-medium
    @extend %paragraph-medium
    padding: 6px 8px
    height: 26px

  &:hover:not(:disabled):not(:focus-visible),
  &:hover:not(.disabled):not(:focus-visible)
    border-color: $border-hover-color

  &:focus-visible
    border-color: $border-focused-color

  &.disabled,
  &:disabled
    color: $text-disabled-color
    background-color: $bg-grey-light-color
    cursor: not-allowed
