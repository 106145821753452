.importer-row
  &__content
    display: flex
    gap: 8px
    align-items: center

    &-badges
      display: flex
      gap: 4px

    +screen-max(768px)
      flex-direction: column
      align-items: flex-start

  &.importer-row--with-status-message
    border-bottom: none

  &__status-message
    td
      padding: 0

    .message
      @extend %captions-medium
      padding-top: 12px
      border-top: 1px solid var(--border-default-color)
      display: -webkit-box
      -webkit-box-orient: vertical
      overflow: hidden
      text-overflow: ellipsis
      -webkit-line-clamp: 2
      line-height: 1.5

      &-error
        color: var(--text-error-color)

      &-warning
        color: var(--text-warning-color)

  &__info
    display: flex
    flex-direction: column
    gap: 6px

  &__summary
    display: flex
    gap: 4px
    align-items: center

  &__title
    @extend %subheading-medium
    color: var(--text-primary-color)
    word-break: break-word

    &-wrapper
      display: flex
      gap: 4px
      align-items: center

  &__actions
    display: flex
    gap: 5px
    justify-content: center

  &__menu-button
    border: 1px solid var(--border-default-color)

  &__connections
    @extend %paragraph-small
    color: var(--text-secondary-color)

  &__status
    flex-shrink: 0
    display: flex
    align-items: center
    gap: 8px
    margin-bottom: 6px
    width: fit-content
    height: 20px

    .status-text
      @extend %subheading-small

  &__run-info
    color: var(--text-secondary-color)
    display: flex
    gap: 4px
    align-items: center

    a
      @extend %hyperlink-small
      color: var(--link-default-color)
      text-decoration: underline

  &__warning-status
    .status-icon
      color: var(--icon-warning-color)

    .status-text
      color: var(--text-warning-color)

  &__running-status
    .status-icon
      color: var(--icon-brand-color)
      transform: scale(0.35)

    .status-text
      color: var(--text-info-color)

  &__error-status
    .status-icon
      color: var(--icon-danger-color)

    .status-text
      color: var(--text-danger-color)

  &__success-status
    .status-icon
      color: var(--icon-success-color)

    .status-text
      color: var(--text-success-color)
