:root
  --usage-data-width: 296px

.wizard-usage-data
  border: 1px solid var(--border-default-color)
  border-radius: 8px
  overflow: hidden
  max-width: var(--usage-data-width)
  height: 100%

  +screen-max(960px)
    max-width: initial

  &__header
    padding: 20px 16px
    background-color: var(--body-bg-color)
    border-bottom: 1px solid var(--border-default-color)

  &__title
    @extend %heading-small-h4
    margin-bottom: 0px

  &__content
    display: flex
    padding: 16px 16px 24px
    background-color: var(--bg-low-color)
    min-height: 232px
    min-width: var(--usage-data-width)

    +screen-max(960px)
      min-width: initial

  &__stats
    display: grid
    grid-template-columns: repeat(2, 1fr)
    column-gap: 16px
    row-gap: 20px
    width: 100%

    &-created-by
      grid-column: 1 / -1

  &__empty, &__in-progress
    display: flex
    flex-direction: column
    align-items: center
    text-align: center
    align-self: center
    width: 100%

    svg
      color: $info-default-color
