
.widget-panel
  position: fixed
  top: 50%
  right: 0
  z-index: 9
  display: flex
  box-shadow: 0 4px 45px rgba(5, 117, 158, 0.1)
  transition: transform 0.3s ease-in
  transform: translate(100.3%, -50%)
  will-change: transform

  &.is-expanded
    transform: translate(0, -50%)

  &.completed
    .widget-panel-content
      padding: 16px 5px
      width: auto

  &-trigger
    display: flex
    align-items: center
    justify-content: flex-end
    position: absolute
    top: 50%
    padding: 16px 6px
    width: 32px
    cursor: pointer
    writing-mode: vertical-rl
    transform: rotate(-180deg) translate(calc(100% - 1px), 50%)
    color: #fff
    background: var(--icon-info-color)
    border: 1px solid var(--icon-info-color)
    border-radius: 0 5px 5px 0
    box-shadow: 0px 16px 24px
    z-index: 1

  &-title
    height: 100%
    font-size: 16px
    font-weight: 500
    text-align: center
    margin: 0
    white-space: nowrap

  &-content
    padding: 24px
    width: 350px
    background: #fff
    border: 1px solid var(--icon-info-color)
    border-radius: 5px 0px 0px 5px
    border-right: none
    position: relative
    z-index: 2
