.delete-action
  svg
    width: 16px
    height: 100%
    color: $deprecated-text-disabled-color
    transition: all 0.2s ease-in-out

  &:hover
    svg
      color: $error-status-color
