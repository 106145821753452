.importer-header
  display: flex
  align-items: center
  gap: 20px
  flex-wrap: wrap
  padding-bottom: 30px
  margin-bottom: 30px
  border-bottom: 1px solid rgba(5, 117, 158, 0.2)

  &__wrapper
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: space-between
    flex: 1 1 600px
    gap: 20px

  &-actions
    display: flex

    .button,
    .action-button,
    .outlined-button
      padding-left: 40px
      padding-right: 40px

  &-details
    display: flex
    align-items: flex-start
    margin-top: 35px

  &-name
    margin-bottom: 5px
    textarea
      font-family: 'DM Sans', 'Helvetica', 'Arial', sans-serif
      padding: 0px 12px
      font-size: 24px
      font-weight: bold

  &__info
    width: 100%
    flex: 1 1 300px

  &-description
    font-family: 'DM Sans', 'Helvetica', 'Arial', sans-serif
    font-size: 14px
