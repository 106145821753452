.plan
  @extend .card
  position: relative
  display: flex
  flex-direction: column
  padding: 32px 16px 24px
  font-size: 14px
  border-top: 6px solid $lilac-plan-color
  .plan-name
    @extend %decorative-subheadings
    margin-bottom: 4px
    color: $text-secondary-color
  &::before
    color: $lilac-plan-color

  &-price
    @extend %paragraph-medium
    display: block

    &-value
      @extend %decorative-prices
      display: block

  p
    color: rgba(0, 0, 0, 0.7)
    margin-bottom: 24px
  .pricing
    margin-bottom: 24px
  .button,
  .outlined-button
    margin-top: auto
  .table td
    padding: 12px 0
    vertical-align: top
    ul li:empty
      min-height: 1.5em
    &:last-child
      font-weight: 500
      text-align: right
  .plan-action
    display: flex
    align-items: flex-end
    flex-grow: 1
    text-align: center
    color: $text-disabled-color
    .button,
    .outlined-button
      width: 100%
  &.most-popular
    background-color: #f2fcff
    &::before
      content: 'Most popular'
      position: absolute
      left: 0
      right: 0
      top: -16px
      line-height: 16px
      font-weight: 500
      padding: 6px 12px 4px
      border-radius: 5px 5px 0 0
      color: #fff

  @each $plan-id, $plan-color in $billing-plan-colors
    &.#{$plan-id}
      border-top-color: $plan-color
      @if $plan-id != 'free_2024'
        .plan-name
          color: $plan-color
      &::before
        background-color: $plan-color
      @if $plan-id == 'custom'
        .button
          &, &:hover:not(:disabled):not(.disabled)
            background-color: $plan-color
