.flash
  display: flex
  flex-direction: row
  position: sticky
  top: calc(56px + var(--billing-alert-height))
  align-items: center
  justify-content: center
  flex-wrap: nowrap
  width: 100%
  padding: 16px 40px
  border-radius: 5px
  word-break: break-word
  z-index: 99998
  cursor: pointer

  &-icon
    width: 25px
    height: 25px
    margin: 0 5px

  &-alert
    background-color: #FBCFBD

    .flash-icon
      color: #cc6152

  &-notice
    background-color: #E7F3E9

    .flash-icon
      color: #58a964
