.wizard-step
  margin-bottom: 10px

  &-content
    flex: 1
    max-width: 100%
    // https://css-tricks.com/flexbox-truncated-text/#the-solution-is-min-width-0-on-the-flex-child
    min-width: 0 // for ellipsis

  &-body
    background: var(--body-bg-color)
    border: 1px solid $border-default-color
    border-radius: 8px
    overflow: hidden

  &-header
    padding: 0
    margin: 20px 0 32px 0
    display: flex
    align-items: center
    position: relative

    &-content
      flex-grow: 1
      overflow: hidden

      &__title
        display: flex
        align-items: center

        .icon26
          flex-shrink: 0

      h2
        @extend %heading-large-h2
        margin-right: 16px
        word-break: break-word

        &:has(.editable-input)
          width: 100%

        .editable
          overflow: hidden
          flex-grow: 1

        .editable-input
          @extend %heading-large-h2
          border: 1px solid transparent
          background: inherit
          padding: 0
          caret-color: var(--primary-color)

    &-status
      color: $theme-primary-color
      position: absolute
      display: flex
      align-items: center
      gap: 3px

      svg
        width: 16px
        height: 16px
        color: $theme-primary-color
        transform: scale(1.5)

    &-actions
      flex-shrink: 0
      display: flex
      flex-direction: row
      gap: 8px

  &-active
    .wizard-step-header-description-value
      display: none

  &-disabled
    opacity: 0.4

  .wizard-actions
    margin-top: 32px
    display: flex
    flex-direction: column
    align-items: center
    gap: 8px

    button
      width: 100%

.wizard-sub-step
  padding: 16px
  border-bottom: 1px solid $border-default-color

  &:last-child
    border-bottom: 0
    border-bottom-left-radius: 8px
    border-bottom-right-radius: 8px

  &__header
    @extend %heading-small-h4
    margin-bottom: 16px

  &__pre-button-text
    margin-bottom: 0.5rem

  &__instructions
    .hint
      @extend %paragraph-medium
      color: $text-primary-color

    img
      width: calc(50% - $space-s)
      object-fit: contain

      &:last-child
        .instruction_box
          margin-bottom: 0

    .instruction_box
      width: calc(100% - $space-m)
      margin: $space-s 0 $space-xl

      &--two-side
        display: flex
        gap: $space-m

      .button
        padding-inline: $space-xxl

  &-active
    border-left: 4px solid var(--primary-color)
    padding-left: 12px
    background-color: $bg-grey-lightest-color

  &-disabled
    .property-title
      color: $text-disabled-color

  // prevent it from growing the negative space
  .property-action
    margin-top: -8px
    margin-bottom: -8px
    padding: 8px
