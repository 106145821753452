@design-tokens url('./../design-tokens/core/colors.json') format('style-dictionary3');

.dashboard-theme-default {
  .background-grey-0 {
    background-color: design-token('colors.neutral.100');
    color: var(--text-primary-color);
  }
  .background-grey-1 {
    background-color: design-token('colors.neutral.200');
    color: var(--text-primary-color);
  }
  .background-grey-2 {
    background-color: design-token('colors.neutral.300');
    color: var(--text-primary-color);
  }
  .background-grey-3 {
    background-color: design-token('colors.neutral.400');
    color: var(--text-primary-color);
  }
  .background-grey-4 {
    background-color: design-token('colors.neutral.500');
    color: var(--text-primary-color);
  }
  .background-grey-5 {
    background-color: design-token('colors.neutral.600');
    color: design-token('colors.neutral.white');
  }
  .background-grey-6 {
    background-color: design-token('colors.neutral.700');
    color: design-token('colors.neutral.white');
  }
  .background-grey-7 {
    background-color: design-token('colors.neutral.800');
    color: design-token('colors.neutral.white');
  }

  .background-blue-0 {
    background-color: design-token('colors.blue.100');
    color: var(--text-primary-color);
  }
  .background-blue-1 {
    background-color: design-token('colors.blue.200');
    color: var(--text-primary-color);
  }
  .background-blue-2 {
    background-color: design-token('colors.blue.300');
    color: var(--text-primary-color);
  }
  .background-blue-3 {
    background-color: design-token('colors.blue.400');
    color: var(--text-primary-color);
  }
  .background-blue-4 {
    background-color: design-token('colors.blue.500');
    color: var(--text-primary-color);
  }
  .background-blue-5 {
    background-color: design-token('colors.blue.600');
    color: design-token('colors.neutral.white');
  }
  .background-blue-6 {
    background-color: design-token('colors.blue.700');
    color: design-token('colors.neutral.white');
  }
  .background-blue-7 {
    background-color: design-token('colors.blue.800');
    color: design-token('colors.neutral.white');
  }

  .background-green-0 {
    background-color: design-token('colors.green.100');
    color: var(--text-primary-color);
  }
  .background-green-1 {
    background-color: design-token('colors.green.200');
    color: var(--text-primary-color);
  }
  .background-green-2 {
    background-color: design-token('colors.green.300');
    color: var(--text-primary-color);
  }
  .background-green-3 {
    background-color: design-token('colors.green.400');
    color: var(--text-primary-color);
  }
  .background-green-4 {
    background-color: design-token('colors.green.500');
    color: var(--text-primary-color);
  }
  .background-green-5 {
    background-color: design-token('colors.green.600');
    color: design-token('colors.neutral.white');
  }
  .background-green-6 {
    background-color: design-token('colors.green.700');
    color: design-token('colors.neutral.white');
  }
  .background-green-7 {
    background-color: design-token('colors.green.800');
    color: design-token('colors.neutral.white');
  }

  .background-red-0 {
    background-color: design-token('colors.red.100');
    color: var(--text-primary-color);
  }
  .background-red-1 {
    background-color: design-token('colors.red.200');
    color: var(--text-primary-color);
  }
  .background-red-2 {
    background-color: design-token('colors.red.300');
    color: var(--text-primary-color);
  }
  .background-red-3 {
    background-color: design-token('colors.red.400');
    color: var(--text-primary-color);
  }
  .background-red-4 {
    background-color: design-token('colors.red.500');
    color: var(--text-primary-color);
  }
  .background-red-5 {
    background-color: design-token('colors.red.600');
    color: design-token('colors.neutral.white');
  }
  .background-red-6 {
    background-color: design-token('colors.red.700');
    color: design-token('colors.neutral.white');
  }
  .background-red-7 {
    background-color: design-token('colors.red.800');
    color: design-token('colors.neutral.white');
  }

  .background-orange-0 {
    background-color: design-token('colors.orange.100');
    color: var(--text-primary-color);
  }
  .background-orange-1 {
    background-color: design-token('colors.orange.200');
    color: var(--text-primary-color);
  }
  .background-orange-2 {
    background-color: design-token('colors.orange.300');
    color: var(--text-primary-color);
  }
  .background-orange-3 {
    background-color: design-token('colors.orange.400');
    color: var(--text-primary-color);
  }
  .background-orange-4 {
    background-color: design-token('colors.orange.500');
    color: var(--text-primary-color);
  }
  .background-orange-5 {
    background-color: design-token('colors.orange.600');
    color: design-token('colors.neutral.white');
  }
  .background-orange-6 {
    background-color: design-token('colors.orange.700');
    color: design-token('colors.neutral.white');
  }
  .background-orange-7 {
    background-color: design-token('colors.orange.800');
    color: design-token('colors.neutral.white');
  }

  .background-purple-0 {
    background-color: design-token('colors.purple.100');
    color: var(--text-primary-color);
  }
  .background-purple-1 {
    background-color: design-token('colors.purple.200');
    color: var(--text-primary-color);
  }
  .background-purple-2 {
    background-color: design-token('colors.purple.300');
    color: var(--text-primary-color);
  }
  .background-purple-3 {
    background-color: design-token('colors.purple.400');
    color: var(--text-primary-color);
  }
  .background-purple-4 {
    background-color: design-token('colors.purple.500');
    color: var(--text-primary-color);
  }
  .background-purple-5 {
    background-color: design-token('colors.purple.600');
    color: design-token('colors.neutral.white');
  }
  .background-purple-6 {
    background-color: design-token('colors.purple.700');
    color: design-token('colors.neutral.white');
  }
  .background-purple-7 {
    background-color: design-token('colors.purple.800');
    color: design-token('colors.neutral.white');
  }
}
