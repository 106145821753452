.report-templates
  margin-bottom: 64px

  &.narrow
    max-width: calc(1040px + $space-xl * 2 + $space-l * 2)

  &__header
    text-align: center

  &__title
    margin: 0

  &__sections
    display: flex
    flex-direction: column
    gap: 24px

    &--with-padding
      padding: 0 16px

  &__section
    h3
      margin: 16px 0

    &--top
      h3
        position: relative

        &::after
          @extend %labels-button
          position: absolute
          content: 'Hot'
          margin: 0 8px
          padding: 4px 8px
          color: var(--border-warning-color)
          background-color: var(--bg-warning-color)
          border: 1px solid var(--border-warning-color)
          border-radius: 4px


  &__filters
    display: flex
    justify-content: space-between
    align-items: center
    gap: 8px
    padding: 24px 16px
    position: sticky
    top: $app-header-height
    background-color: $bg-grey-color
    z-index: $z-index-template-filters

    &--sticky
      border-bottom: 1px solid var(--border-default-color)

    &-container
      display: flex
      gap: 8px
      align-items: center
      width: 65%

    +screen-max(768px)
      &-container
        flex-direction: column

    +screen-max(1240px)
      &-container
        width: 100%

  +screen-max(1240px)
    &__filters
      flex-direction: column

  &__filter
    width: 100%

    .muted
      color: var(--text-primary-color)

  &__search
    background: var(--body-bg-color)
    width: 35%

    .search-box__input-container
      height: 40px
      width: 100%

  +screen-max(1240px)
    &__search
      width: 100%

  &__list
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(336px, 1fr))
    gap: 16px

  &__empty-section
    text-align: center

    svg
      color: var(--icon-info-color)

  .report-template
    display: flex
    flex-direction: column
    padding: 12px
    gap: 16px
    border-radius: 16px
    border: 1px solid var(--border-default-color)
    background: var(--body-bg-color)
    cursor: pointer
    position: relative

    &:hover
      border-color: var(--border-hover-color)

      .report-template__buttons
        display: flex

    &__title,
    &__description
      word-break: break-word
      overflow: hidden
      text-overflow: ellipsis
      display: -webkit-box
      -webkit-line-clamp: 3
      line-clamp: 3
      -webkit-box-orient: vertical

    &__title
      @extend %heading-xsmall-h5

    &__description
      @extend %paragraph-small
      margin: 0

    &__img-wrapper
      position: relative
      flex-shrink: 0
      height: 192px
      border-radius: 8px
      overflow: hidden

    &__img
      position: absolute
      width: 130%
      top: 20px
      left: 20px
      border-radius: 5px
      box-shadow: -4px 0px 16px 0px rgba(0, 155, 205, 0.12)

    &__info
      display: flex
      gap: 8px
      flex-direction: column
      justify-content: space-between
      height: 100%

    &__buttons
      display: none
      width: 100%
      align-items: center
      justify-content: center
      gap: 8px
      position: absolute
      top: 0
      left: 0
      height: 212px
      border-radius: 15px 15px 0px 0px
      background: rgba(3, 3, 39, 0.64)
      border-bottom: 1px solid var(--border-hover-color)
      backdrop-filter: blur(2px)

    &__integrations
      display: flex
      align-items: center

    &__arrow
      fill: var(--muted-color)

  &__footer
    @extend %paragraph-medium
    width: 100%
    position: fixed
    left: 0
    bottom: 0
    display: inline-flex
    height: 64px
    justify-content: center
    align-items: center
    gap: 16px
    border-top: 1px solid var(--border-default-color)
    background: $theme-light-color

    &--text
      margin: 0

    +screen-max(768px)
      &--text
        display: none


