.select-group-label-wrapper
  display: flex
  padding: 8px 12px
  align-items: center
  position: sticky
  top: 0
  background-color: var(--body-bg-color)
  z-index: 2

  &:hover, &.selected
    background-color: $bg-grey-color

  .checkbox-label, .group-label
    @extend %heading-xsmall-h5
