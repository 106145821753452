.input-clear-button
  background-color: $bg-grey-color
  border: 1px solid $border-default-color
  border-radius: 0 5px 5px 0

  &.size-small
    width: 32px
    height: 32px
    font-size: 18px
    line-height: 18px
    padding: 6px

  &.size-medium
    width: 40px
    height: 40px
    font-size: 24px
    line-height: 24px
    padding: 7px

  > svg
    vertical-align: baseline
    color: var(--primary-color)
    width: 100%
    height: auto

  &.disabled,
  &:disabled
    background-color: $bg-grey-light-color
    cursor: not-allowed

    > svg
      color: var(--muted-color)

  &:active,
  &:focus-visible
    border-color: $border-focused-color
    outline: none
