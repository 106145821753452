.checkbox
  &-root
    display: flex
    align-items: center
    cursor: pointer
    gap: 4px

    input
      appearance: none
      margin: 0

    &.disabled
      cursor: not-allowed

  &-input
    display: flex
    flex-shrink: 0
    justify-content: center
    width: 24px
    height: 24px

    .checkbox-icon
      width: 24px
      height: 24px
      color: var(--primary-color)

      &:hover
        color: var(--checkbox-hover-color)

      &:active
        color: var(--primary-active-color)

      &.disabled
        color: var(--muted-color)

      &.error
        color: $critical-default-color

  &-label
    @extend %paragraph-medium
    width: 100%

    &.disabled
      color: $text-disabled-color

.checkbox-group
  display: flex
  gap: 4px

  &-root
    margin-right: 1px

    &:last-child
      margin-right: 0

    input
      position: absolute
      width: 1px
      height: 1px
      padding: 0
      margin: -1px
      overflow: hidden
      clip: rect(0 0 0 0)
      border: 0

  &-input
    display: flex
    align-items: center
    justify-content: center
    width: 36px
    height: 36px
    font-size: 12px
    font-weight: 500
    text-transform: uppercase
    border-radius: 4px

    &[data-state='checked']
      color: #fff
      background-color: #05759e
      border: 1px solid transparent

    &[data-state='unchecked']
      background-color: #fff
      border: 1px solid rgba(135, 156, 165, 0.3)


