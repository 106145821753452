.eye-checkbox
  display: flex
  align-items: center
  overflow: hidden

  &:active
    .eye-checkbox__icon
      *
        fill: $theme-primary-dark-color

  &:hover:not(:active)
    .eye-checkbox__icon
      *
        fill: $theme-primary-color

  &__input
    display: none

  &__icon
    margin-left: 7px
    margin-right: 7px

    *
      fill: $theme-grey-color

  &__label
    flex-grow: 1
    color: $deprecated-text-disabled-color
    font-size: 14px
    line-height: 20px
    overflow: hidden
    flex-basis: 0%

    &.checked
      color: $deprecated-text-secondary-color
