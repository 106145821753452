.not-found
  min-height: calc(100vh - 136px)
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  padding: 0 16px

.not-found__ico
  width: 128px
  margin-bottom: 32px

  +screen-max(475px)
    width: 80px
    margin-bottom: 16px

.not-found__title
  margin-bottom: 12px
  text-align: center
  font-size: 20px

.not-found__text
  margin-bottom: 32px
  text-align: center

  +screen-max(475px)
    font-size: 14px

.not-found__button
  margin-bottom: 88px

  +screen-max(475px)
    width: 100%
