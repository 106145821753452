.transformation-modal
  &[data-reach-dialog-content]
    background-color: $bg-grey-lightest-color
    border-top-left-radius: 16px
    border-top-right-radius: 16px
    width: calc(100vw - 16px)
    height: calc(100vh - 48px)
    overflow: hidden
    display: flex
    flex-direction: column
    // reset of other styles
    margin: 0
    padding: 0

  &__overlay
    &[data-reach-dialog-overlay]
      position: fixed
      top: 0
      left: 0
      bottom: 0
      right: 0
      background-color: $overlay-grey-dark-color
      display: flex
      justify-content: center
      align-items: flex-end
      z-index: $z-index-transformation-modal

  &__header
    padding: 16px 40px
    background-color: var(--body-bg-color)
    border-bottom: 1px solid $border-default-color
    flex-shrink: 0
    flex-grow: 0

  &__title-wrapper
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center

  &__title
    @extend %heading-large-h2

  &__description
    margin: 0

  &__close
    height: 40px

  &__content
    height: 100%
    overflow: auto
    padding: 32px 0

  &__footer
    border-top: 1px solid $border-default-color
    background-color: var(--body-bg-color)
    display: flex
    justify-content: center
