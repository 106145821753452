.field-helper
  width: 100%
  padding: 8px 16px 8px 12px
  background-color: $theme-lighter-color
  border: 1px solid $input-border-color
  border-top: 0
  border-bottom-right-radius: 5px
  border-bottom-left-radius: 5px
  font-size: $text-size-xs

  .field-helper-title
    display: flex
    align-items: center
    line-height: 20px

  .field-helper-title,
  .field-helper-description
    a
      font-weight: 500
      text-decoration: none

    ul
      ul
        list-style-type: disc

    ul,
    ol
      list-style-position: inside
      list-style: inside

    p
      margin-bottom: 0

    img
      display: block
      max-width: 100%
      margin: 16px auto

  .field-helper-description
    padding-left: 20px

  .field-helper-icon
    flex-shrink: 0

  &.is-collapsible
    .field-helper-title
      font-weight: 500
      color: $theme-primary-color
      cursor: pointer

    .arrow
      margin-left: auto

  &.has-error
    border-color: var(--border-error-color)
