$green-plan-color: #5baa68;
$orange-plan-color: #f5a623;
$red-plan-color: #de5946;
$blue-plan-color: #00749c;
$blue-vivid-plan-color: #17B3E5;
$lilac-plan-color: #68328a;
$grey-plan-color: #879CA5;

$billing-plan-colors: (
  "free": $green-plan-color,
  "lite_2023": $orange-plan-color,
  "squad": $blue-plan-color,
  "professional": $red-plan-color,
  "business": $lilac-plan-color,
  "free_2021": $green-plan-color,
  "professional_2021": $red-plan-color,
  "squad_2021": $blue-plan-color,
  "business_2021": $lilac-plan-color,
  "free_2022": $blue-plan-color,
  "personal_2022": $red-plan-color,
  "squad_2022": $green-plan-color,
  "business_2022": $blue-vivid-plan-color,
  "starter_2022": $red-plan-color,
  "personal_v2_2022": $red-plan-color,
  "squad_v2_2022": $green-plan-color,
  "business_v2_2022": $blue-vivid-plan-color,
  "free_2023": $blue-plan-color,
  "free_2024": $grey-plan-color, // plan name is overridden separately
  "free_2024_08": $grey-plan-color, // plan name is overridden separately
  "free_trial_2024_08": $grey-plan-color, // plan name is overridden separately
  "personal_2024_08": $orange-plan-color,
  "professional_2024_08": $red-plan-color,
  "team_2024_08": $green-plan-color,
  "business_2024_08": $blue-vivid-plan-color,
  "custom": $lilac-plan-color,
  "professional_reviewed_2023": $red-plan-color,
  "squad_reviewed_2023": $green-plan-color,
  "business_reviewed_2023": $blue-vivid-plan-color,
  "professional_2021_reviewed_2023": $red-plan-color,
  "squad_2021_reviewed_2023":$green-plan-color,
  "business_2021_reviewed_2023": $blue-vivid-plan-color
)
