.badge
  @extend %labels-badge
  color: var(--text-primary-color)
  background-color: var(--fill-disabled-color)
  padding: 4px 8px
  border-radius: 4px
  white-space: nowrap

  &-purple
    background-color: var(--decorative-eminence-color)
    color: var(--text-inverse-color)
