.table
  border-radius: 5px
  border: 1px solid var(--border-default-color)
  outline: 1px solid var(--border-default-color)
  border-collapse: collapse
  overflow: hidden
  width: 100%

  &-striped
    tbody tr:nth-child(even)
      background-color: var(--bg-low-color)

  &-wrapper
    border-radius: 5px
    border: 1px solid var(--border-default-color)
    overflow-x: auto
    width: 100%

  thead
    background-color: var(--bg-lower-color)
    border-bottom: 1px solid var(--border-default-color)

    th
      @extend %subheading-small
      color: var( --text-primary-color)

    th:not(:last-child):not(:first-child)
      text-align: left

    th:first-child
      text-align: center

    th:last-child
      text-align: center
      padding-right: 20px

  tbody
    background-color: var(--body-bg-color)

    tr
      border-bottom: 1px solid var(--border-default-color)

      td:last-child
        padding-right: 20px
        width: 70px

      td:first-child
        padding: 16px

  td
    padding: 16px 20px 16px 0

    .muted
      color: var(--text-disabled-color)

  th
    padding: 10px 0

  .checkbox-root
    justify-content: center

  tfoot
    background-color: var(--body-bg-color)

    td
      padding: 16px 20px
