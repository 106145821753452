.login
  padding: 16px 0
  height: 100%
  display: flex
  flex-direction: column

  &__wrapper
    min-height: calc(100vh - 88px)
    display: flex
    font-family: 'Inter', sans-serif
    background: #ffffff

    .container
      width: 100%
      height: auto

  &__title
    margin-bottom: 0
    font-size: 48px
    text-align: center

    +screen-max(768px)
      font-size: 32px

    .highlighted
      color: #009BCD

  &__subtitle
    margin-bottom: 44px
    color: #605E5D
    font-size: 16px
    text-align: center

  &__box
    flex: 1

  &-content
    margin: 0 auto
    padding: 32px 40px
    width: 100%
    max-width: 864px
    display: flex
    justify-content: space-between
    border-radius: 16px
    background: #F6FAFB

    +screen-max(1024px)  
      flex-direction: column
      align-items: center
      max-width: 416px
      padding: 32px 16px

    &__box
      flex: 1
      width: 100%

    &__title
      margin-top: 12px
      margin-bottom: 24px
      font-size: 18px
      font-weight: 600
      line-height: 1.3
      text-align: center

  &-divider
    position: relative
    margin: 0 55px
    height: auto
    width: 1px
    background: rgba(195, 205, 210, 0.40)

    +screen-max(1024px)  
      width: 100%
      height: 1px
      margin: 55px 0

    &__text
      position: absolute
      left: 50%
      top: 50%
      padding: 16px 0
      background: #F6FAFB
      font-size: 16px
      line-height: 1.5
      color: #605E5D
      transform: translate(-50%, -50%)

      +screen-max(1024px)  
        padding: 0 16px

  &-form-container
    width: 100%
    max-width: 338px

    +screen-max(1024px)
      max-width: 100%

  &-form
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    width: 100%
    max-width: 338px

    +screen-max(1024px)
      max-width: 100%

  &-field
    width: 100%
    margin-bottom: 20px

  &-remember
    width: 100%
    margin-bottom: 16px

    &-checkbox
      display: flex
      position: relative

      &__input
        position: absolute
        top: 0
        left: 0
        width: 0
        height: 0
        clip: rect(0,0,0,0)

        &:checked
          + .login-remember-checkbox__label
            &::before
              background: #009BCD

            &::after
              transform: rotate(0)
              opacity: 1

      &__label
        position: relative
        padding-left: 30px
        display: flex
        flex-direction: column
        font-size: 16px
        cursor: pointer

        &::before
          content: ""
          position: absolute
          top: 0
          left: 0
          width: 22px
          min-width: 22px
          height: 22px
          border: 1px solid #009BCD
          border-radius: 4px
          cursor: pointer
          box-sizing: border-box
          transition: all .2s ease-in

        &::after
          content: ""
          position: absolute
          top: 1px
          left: 1px
          width: 20px
          height: 20px
          mask: url('~icons/ic-checkmark.svg') no-repeat 50% 50%
          mask-size: contain
          background-color: #ffffff
          transform: rotate(-145deg)
          transition: all .2s ease-in
          opacity: 0

  .login-forgot-link
    margin-top: 8px
    display: block
    color: #009BCD
    text-align: right
    font-size: 16px
    line-height: 1.5
    text-decoration: none

  .login-signup
    text-align: center
    margin-top: 16px
    font-size: 16px
    
    a
      text-decoration: none
      color: #009BCD

  .login__footer
    margin-top: 16px
    text-align: center

  &__agreement
    font-size: 12px
    margin-bottom: 8px

    a
      text-decoration: none
      color: #009BCD

  &-form-input
    width: 100%
    padding: 11px 16px
    line-height: 150%
    border-radius: 12px
    box-sizing: border-box
    border: 1px solid rgba(195, 205, 210, 0.40)

    &:focus
      border-color: #009BCD
      outline: 0

    &::placeholder
      color: #879CA5

  &-actions
    width: 100%
    margin-top: 8px

  &-signin-btn
    width: 100%
    padding: 0 24px
    line-height: 1.2
    min-height: 48px
    font-size: 14px
    font-weight: 600
    border-radius: 12px
    box-sizing: border-box
    border: 1px solid #009BCD
    color: #fff
    background-color: #009BCD
    cursor: pointer

    &:hover
      background-color: #009BCD
      opacity: .95

  &-plans-link
    display: block
    padding: 20px 0 10px

  &-agreement
    padding-bottom: 150px
    margin: 0 auto
    font-size: 12px

  &-recaptcha-agreement
    font-size: 12px

    a
      color: #009BCD
      text-decoration: none

  &-forgot
    .login__title
      margin-bottom: 24px

    &__subtitle
      margin-bottom: 8px
      text-align: center
      font-size: 22px
      line-height: 1.5

      +screen-max(768px)
        font-size: 16px

    &__form
      margin-top: 60px
      display: flex
      flex-direction: column
      align-items: center

    .login-agreement
      padding-bottom: 0
      margin-bottom: 8px

      a
        color: #009BCD
        text-decoration: none

    .login-recaptcha-agreement
      text-align: center


  +screen-max(768px)
    &-content
      align-items: center

    &-two-content
      flex-direction: column-reverse

    &-title
      margin-bottom: 10px

    &-subtitle span
      display: block

    &-subtitle
      margin-bottom: 16px
      font-size: 18px

    &-info
      width: 100%
      max-width: 338px

    .button-google
      font-size: 14px

    .onboarding-list
      font-size: 16px

    &-plans-link
      text-align: center

    &-agreement
      max-width: 215px
      padding-bottom: 45px

  +screen-max(500px)
    &-title
      font-size: 24px

.field_with_errors
  input
    border: 1px solid #CC6152
    background-color: #FBCFBD

.form-field-error
  color: #CC6152
  font-size: 14px

.form-hidden-field-error
  color: #CC6152
  font-size: 16px
  margin-bottom: 10px

