.linear-progress
  &.root
    height: 20px

    &.success
      background-color: rgba(37, 167, 58, 0.5)

    &.danger
      background-color: rgba(255, 108, 92, 0.5)

  .bar
    &.success
      background-color: rgba(37, 167, 58, 1)

    &.danger
      background-color: rgba(255, 108, 92, 1)

  &.toolbar-stats-progress
    &.root
      height: 5px
      background-color: rgba(216, 216, 216, 0.5)
      border-radius: 5px

    .bar
      background-color: #0a0836

.circular-progress
  display: flex
  align-items: center

  &-percentage
    font-size: 24px

  svg 
    path
      transition-duration: 1.2s

    .CircularProgressbar-path
      stroke: $success-status-color

    .CircularProgressbar-trail
      stroke: #dbe1e4


