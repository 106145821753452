.custom-solutions
  position: relative
  margin-bottom: 24px

  &__title-wrap
    display: flex
    justify-content: center

  &__title
    font-size: 20px
    margin-bottom: 16px
    font-weight: bold
    letter-spacing: -0.02em
    position: relative
    
    &::before
      content: ""
      margin: 0 auto
      position: absolute
      top: 50%
      left: 0
      right: 0
      width: 100%
      border-bottom: 1px solid $theme-primary-color
      opacity: .2
      transform: translateY(-50%)
      z-index: -1

      +screen-max(640px)
        display: none

  &__title-text
    background: $theme-light-color
    padding: 0 60px

    +screen-max(1180px)
      padding: 0 24px

    +screen-max(640px)
      padding: 0

  &-title__line
    +screen-max(640px)
      display: block

  &__text
    a
      text-decoration: underline

  &__list
    display: grid
    grid-template-columns: repeat(2, 1fr)
    gap: 20px

    +screen-max(1280px)
      gap: 12px

    +screen-max(768px)
      grid-template-columns: repeat(2, 1fr)

    +screen-max(640px)
      grid-template-columns: repeat(1, 1fr)
      gap: 16px
      margin-bottom: 0

    .column
      margin-bottom: 0

  .custom-solution
    @extend .card
    position: relative
    display: flex
    flex-direction: column
    padding: 16px
    font-size: 14px
    border-top: 6px solid $theme-primary-color

    &__name
      color: $theme-primary-color
      margin-bottom: 2px
      font-size: 18px
      font-weight: 500

    &__price
      margin-bottom: 16px
      font-size: 32px
      line-height: 44px
      font-weight: 600

    &__list-title
      margin-bottom: 2px

    &__list
      flex: 1

    &__item
      display: flex
      align-items: center
      margin-bottom: 4px
      position: relative
      padding-left: 24px

      &:last-child
        margin-bottom: 16px

      &:before
        content: ""
        position: absolute
        left: 0
        top: 0
        bottom: 0
        width: 16px
        background: url('~ic-drop-down-checkmark.svg') no-repeat center
        background-size: contain
