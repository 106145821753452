.breadcrumbs
  display: flex
  align-items: center
  list-style: none
  overflow: hidden

  &.breadcrumb-back
    @extend %subheading-medium
    align-items: center
    color: var(--primary-color)
    display: inline-flex


  .breadcrumbs-item
    .loader
      padding: 9px
      display: inline
    a
      text-decoration: none

    &:first-child
      flex-shrink: 0

  .breadcrumbs-separator
    margin: 0 9px
    fill: $theme-primary-color
